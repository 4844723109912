import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RestAPIService from '../../services/rest-api.service';
import MapComponent from './map.component';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import useContent from "../../hooks/useContent.hook";
import axios from 'axios';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet.heat';
import L from 'leaflet';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);

const AnalyticsComponent = ({ userGroup }) => {
  const { t } = useTranslation();
  const { fetchUsersByBrand } = useContent();
  const [stores, setStores] = useState([]);
  const [bikeData, setBikeData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [importedUsers, setImportedUsers] = useState([]);
  const [userLocations, setUserLocations] = useState([]);
  const [trips, setTrips] = useState([]);
  const [bikeLocations, setBikeLocations] = useState([]);
  const [appInstalls, setAppInstalls] = useState([]);

  useEffect(() => {
    RestAPIService.fetchStoresByBrand(userGroup)
      .then(data => {
        console.log(t("Fetched stores:"), data);
        setStores(data);
      })
      .catch(error => {
        console.error(t("Error fetching stores:"), error);
      });

    RestAPIService.fetchBikesByBrand(userGroup)
      .then(data => {
        console.log(t("Fetched bikes:"), data);
        setBikeData(data);
      })
      .catch(error => {
        console.error(t("Error fetching bikes:"), error);
      });

    fetchUsersByBrand(userGroup)
      .then(data => {
        console.log(t("Fetched users:"), data);
        setUserData(data);
      })
      .catch(error => {
        console.error(t("Error fetching users:"), error);
      });

    RestAPIService.fetchBikeErrorsByBrand(userGroup)
      .then(data => {
        console.log(t("Fetched bike errors:"), data);
        setErrorData(data);
      })
      .catch(error => {
        console.error(t("Error fetching bike errors:"), error);
      });

    RestAPIService.fetchImportedUsers()
      .then(data => {
        console.log(t("Fetched imported users:"), data);
        setImportedUsers(data);
        fetchUserLocations(data.filter(user => user.postalCode));
      })
      .catch(error => {
        console.error(t("Error fetching imported users:"), error);
      });

    RestAPIService.fetchTrips()
      .then(data => {
        console.log(t("Fetched trips:"), data);
        setTrips(data);
      })
      .catch(error => {
        console.error(t("Error fetching trips:"), error);
      });

    RestAPIService.fetchBikeLocations()
      .then(data => {
        console.log(t("Fetched bike locations:"), data);
        setBikeLocations(data);
      })
      .catch(error => {
        console.error(t("Error fetching bike locations:"), error);
      });

    RestAPIService.fetchInstallations()
      .then(data => {
        console.log(t("Fetched app installations:"), data);
        setAppInstalls(data);
      })
      .catch(error => {
        console.error(t("Error fetching app installations:"), error);
      });
  }, [userGroup, t]);

  const fetchUserLocations = async (users) => {
    try {
      const locations = await Promise.all(
        users.map(async (user) => {
          try {
            const response = await RestAPIService.fetchLatLng(user.postalCode);
            const { lat, lng } = response;
            if (lat !== undefined && lng !== undefined) {
              return { ...user, lat, lng };
            }
            return null;
          } catch (error) {
            console.error(t(`Error fetching coordinates for postal code: ${user.postalCode}`), error);
            return null;
          }
        })
      );
      setUserLocations(locations.filter(location => location !== null));
    } catch (error) {
      console.error(t("Error fetching user locations:"), error);
    }
  };

  const processBikeData = () => {
    const today = new Date();
    const past30Days = Array.from({ length: 30 }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      return date.toISOString().split('T')[0];
    }).reverse();

    const counts = bikeData.reduce((acc, bike) => {
      const date = new Date(bike.date).toISOString().split('T')[0];
      if (past30Days.includes(date)) {
        acc[date] = (acc[date] || 0) + 1;
      }
      return acc;
    }, {});

    const bikeCounts = past30Days.map(date => counts[date] || 0);

    console.log(t("Processed bike counts for the last 30 days:"), bikeCounts);

    return {
      labels: past30Days,
      datasets: [
        {
          label: t('Bikes Connected per Day (Last 30 Days)'),
          data: bikeCounts,
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    };
  };

  const processTotalBikeData = () => {
    const counts = bikeData.reduce((acc, bike) => {
      const date = new Date(bike.date).toISOString().split('T')[0];
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const dates = Object.keys(counts).sort((a, b) => new Date(a) - new Date(b));
    const cumulativeCounts = dates.map((date, index) => {
      return dates.slice(0, index + 1).reduce((sum, currentDate) => sum + counts[currentDate], 0);
    });

    console.log(t("Processed total bike data:"), cumulativeCounts);

    return {
      labels: dates,
      datasets: [
        {
          label: t('Total Bikes Added Over Time'),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    };
  };

  const processTotalUserData = () => {
    const counts = userData.reduce((acc, user) => {
      const date = new Date(user.date_registered.replace(' ', 'T')).toISOString().split('T')[0];
      if (!isNaN(new Date(date))) {
        acc[date] = (acc[date] || 0) + 1;
      }
      return acc;
    }, {});

    const dates = Object.keys(counts).sort((a, b) => new Date(a) - new Date(b));
    const cumulativeCounts = dates.map((date, index) => {
      return dates.slice(0, index + 1).reduce((sum, currentDate) => sum + counts[currentDate], 0);
    });

    console.log(t("Processed total user data:"), cumulativeCounts);

    return {
      labels: dates,
      datasets: [
        {
          label: t('Total Users Added Over Time'),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: 'rgba(153,102,255,0.4)',
          borderColor: 'rgba(153,102,255,1)',
        },
      ],
    };
  };

  const processTotalImportedUserData = () => {
    const counts = importedUsers.reduce((acc, user) => {
      if (user._created_at) {
        const date = new Date(user._created_at).toISOString().split('T')[0];
        if (!isNaN(new Date(date))) {
          acc[date] = (acc[date] || 0) + 1;
        }
      }
      return acc;
    }, {});

    const dates = Object.keys(counts).sort((a, b) => new Date(a) - new Date(b));
    const cumulativeCounts = dates.map((date, index) => {
      return dates.slice(0, index + 1).reduce((sum, currentDate) => sum + counts[currentDate], 0);
    });

    console.log(t("Processed total imported user data:"), cumulativeCounts);

    return {
      labels: dates,
      datasets: [
        {
          label: t('Total App Users'),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: 'rgba(54,162,235,0.4)',
          borderColor: 'rgba(54,162,235,1)',
        },
      ],
    };
  };

  const processTotalStoreData = () => {
    const counts = stores.reduce((acc, store) => {
      const date = new Date(store.date).toISOString().split('T')[0];
      if (!isNaN(new Date(date))) {
        acc[date] = (acc[date] || 0) + 1;
      }
      return acc;
    }, {});

    const dates = Object.keys(counts).sort((a, b) => new Date(a) - new Date(b));
    const cumulativeCounts = dates.map((date, index) => {
      return dates.slice(0, index + 1).reduce((sum, currentDate) => sum + counts[currentDate], 0);
    });

    console.log(t("Processed total store data:"), cumulativeCounts);

    return {
      labels: dates,
      datasets: [
        {
          label: t('Total Stores Added Over Time'),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: 'rgba(255,206,86,0.4)',
          borderColor: 'rgba(255,206,86,1)',
        },
      ],
    };
  };

  const processErrorData = () => {
    const errorCounts = errorData.reduce((acc, error) => {
      const errorCode = error.params?.error || (typeof error.problem === 'string' && error.problem.match(/\d+/)?.[0]);
      if (errorCode) {
        acc[errorCode] = (acc[errorCode] || 0) + 1;
      }
      return acc;
    }, {});

    const labels = Object.keys(errorCounts);
    const data = Object.values(errorCounts);

    console.log(t("Processed error data:"), { labels, data });

    return {
      labels,
      datasets: [
        {
          label: t('Error Occurrences'),
          data,
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
        },
      ],
    };
  };

  const processFixData = () => {
    const errorFixes = {};

    errorData.forEach(record => {
      if (record.params?.type === 'errorFixed') {
        const errorCode = record.params.error;
        if (errorCode) {
          if (!errorFixes[errorCode]) {
            errorFixes[errorCode] = {};
          }
          const solution = record.params.solution;
          const step = record.params.step;
          if (!errorFixes[errorCode][solution]) {
            errorFixes[errorCode][solution] = {};
          }
          errorFixes[errorCode][solution][step] = (errorFixes[errorCode][solution][step] || 0) + 1;
        }
      }
    });

    console.log(t("Processed error fix data:"), errorFixes);

    return errorFixes;
  };

  const processTotalTripDistance = () => {
    const counts = trips.reduce((acc, trip) => {
      const date = new Date(trip.start).toISOString().split('T')[0];
      if (!isNaN(new Date(date))) {
        acc[date] = (acc[date] || 0) + trip.distance;
      }
      return acc;
    }, {});

    const dates = Object.keys(counts).sort((a, b) => new Date(a) - new Date(b));
    const cumulativeCounts = dates.map((date, index) => {
      return dates.slice(0, index + 1).reduce((sum, currentDate) => sum + counts[currentDate], 0);
    });

    console.log(t("Processed total trip distance data:"), cumulativeCounts);

    return {
      labels: dates,
      datasets: [
        {
          label: t('Total Trip Distance Over Time (km)'),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: 'rgba(255,159,64,0.4)',
          borderColor: 'rgba(255,159,64,1)',
        },
      ],
    };
  };

  const processTripDistancePerDay = () => {
    const counts = trips.reduce((acc, trip) => {
      const date = new Date(trip.start).toISOString().split('T')[0];
      if (!isNaN(new Date(date))) {
        acc[date] = (acc[date] || 0) + trip.distance;
      }
      return acc;
    }, {});

    const today = new Date();
    const past30Days = Array.from({ length: 30 }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      return date.toISOString().split('T')[0];
    }).reverse();

    const tripDistances = past30Days.map(date => counts[date] || 0);

    console.log(t("Processed trip distance per day for the last 30 days:"), tripDistances);

    return {
      labels: past30Days,
      datasets: [
        {
          label: t('Trip Distance per Day (Last 30 Days) (km)'),
          data: tripDistances,
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    };
  };

  const processTotalTrips = () => {
    const counts = trips.reduce((acc, trip) => {
      const date = new Date(trip.start).toISOString().split('T')[0];
      if (!isNaN(new Date(date))) {
        acc[date] = (acc[date] || 0) + 1;
      }
      return acc;
    }, {});

    const dates = Object.keys(counts).sort((a, b) => new Date(a) - new Date(b));
    const cumulativeCounts = dates.map((date, index) => {
      return dates.slice(0, index + 1).reduce((sum, currentDate) => sum + counts[currentDate], 0);
    });

    console.log(t("Processed total trips data:"), cumulativeCounts);

    return {
      labels: dates,
      datasets: [
        {
          label: t('Total Trips Over Time'),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: 'rgba(153,102,255,0.4)',
          borderColor: 'rgba(153,102,255,1)',
        },
      ],
    };
  };

  const processTripsPerDay = () => {
    const counts = trips.reduce((acc, trip) => {
      const date = new Date(trip.start).toISOString().split('T')[0];
      if (!isNaN(new Date(date))) {
        acc[date] = (acc[date] || 0) + 1;
      }
      return acc;
    }, {});

    const today = new Date();
    const past30Days = Array.from({ length: 30 }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      return date.toISOString().split('T')[0];
    }).reverse();

    const tripCounts = past30Days.map(date => counts[date] || 0);

    console.log(t("Processed trips per day for the last 30 days:"), tripCounts);

    return {
      labels: past30Days,
      datasets: [
        {
          label: t('Trips per Day (Last 30 Days)'),
          data: tripCounts,
          fill: false,
          backgroundColor: 'rgba(153,102,255,0.4)',
          borderColor: 'rgba(153,102,255,1)',
        },
      ],
    };
  };

  const processTotalAppInstalls = () => {
    const counts = appInstalls.reduce((acc, install) => {
      if (install._created_at) {
        const date = new Date(install._created_at).toISOString().split('T')[0];
        acc[date] = (acc[date] || 0) + 1;
      }
      return acc;
    }, {});

    const dates = Object.keys(counts).sort((a, b) => new Date(a) - new Date(b));
    const cumulativeCounts = dates.map((date, index) => {
      return dates.slice(0, index + 1).reduce((sum, currentDate) => sum + counts[currentDate], 0);
    });

    console.log(t("Processed total app installs data:"), cumulativeCounts);

    return {
      labels: dates,
      datasets: [
        {
          label: t('Total App Installs Over Time'),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: 'rgba(54,162,235,0.4)',
          borderColor: 'rgba(54,162,235,1)',
        },
      ],
    };
  };

  const processAppVersionDistribution = () => {
    const counts = appInstalls.reduce((acc, install) => {
      const version = install.appVersion || t("Unknown");
      acc[version] = (acc[version] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(counts);
    const data = Object.values(counts);

    return {
      labels,
      datasets: [
        {
          label: t('App Version Distribution'),
          data,
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#FF6384', '#36A2EB', '#FFCE56'
          ],
        },
      ],
    };
  };

  const processDeviceTypeDistribution = () => {
    const counts = appInstalls.reduce((acc, install) => {
      const deviceType = install.deviceType || t("Unknown");
      acc[deviceType] = (acc[deviceType] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(counts);
    const data = Object.values(counts);

    return {
      labels,
      datasets: [
        {
          label: t('Device Type Distribution'),
          data,
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#FF6384', '#36A2EB', '#FFCE56'
          ],
        },
      ],
    };
  };

  const processLocaleIdentifierDistribution = () => {
    const counts = appInstalls.reduce((acc, install) => {
      const locale = install.localeIdentifier ? install.localeIdentifier.substring(0, 2) : t("Unknown");
      acc[locale] = (acc[locale] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(counts);
    const data = Object.values(counts);

    return {
      labels,
      datasets: [
        {
          label: t('Locale Identifier Distribution'),
          data,
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#FF6384', '#36A2EB', '#FFCE56'
          ],
        },
      ],
    };
  };

  const tripHeatmapData = trips.map(trip => [trip.geolocationStart[0], trip.geolocationStart[1], 1]);
  const bikeHeatmapData = bikeLocations
    .filter(bike => !(bike.geolocation[0] === 0 && bike.geolocation[1] === 0))
    .map(bike => [bike.geolocation[1], bike.geolocation[0], 1]);

  return (
    <div className="container-fluid text-center">
      <div className="row">
        <div className="col-md-6">
          <h4>{t("Store Locations")}</h4>
          <MapComponent stores={stores} />
          <p>{t("Total Stores")}: {stores.length}</p>
          <h4>{t("User Locations")}</h4>
          <MapComponent stores={userLocations} />
          <p>{t("Total Users")}: {userLocations.length}</p>
          <hr />
          <h4>{t("Trips")}</h4>
          <MapContainer style={{ height: "600px", width: "100%" }} center={[0, 0]} zoom={2}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <HeatmapLayer points={tripHeatmapData} />
            <MapBounds points={tripHeatmapData} />
          </MapContainer>
          <hr />
          <h4>{t("Bike Locations")}</h4>
          <MapContainer style={{ height: "600px", width: "100%" }} center={[0, 0]} zoom={2}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <HeatmapLayer points={bikeHeatmapData} />
            <MapBounds points={bikeHeatmapData} />
          </MapContainer>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Total App Installs Over Time")}</h4>
            {appInstalls.length > 0 ? (
              <Line data={processTotalAppInstalls()} />
            ) : (
              <p>{t("No app install data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("App Version Distribution")}</h4>
            {appInstalls.length > 0 ? (
              <Doughnut data={processAppVersionDistribution()} />
            ) : (
              <p>{t("No app version data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Device Type Distribution")}</h4>
            {appInstalls.length > 0 ? (
              <Doughnut data={processDeviceTypeDistribution()} />
            ) : (
              <p>{t("No device type data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Locale Identifier Distribution")}</h4>
            {appInstalls.length > 0 ? (
              <Doughnut data={processLocaleIdentifierDistribution()} />
            ) : (
              <p>{t("No locale identifier data available.")}</p>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="chart-section">
            <h4>{t("Bikes Connected per Day (Last 30 Days)")}</h4>
            {bikeData.length > 0 ? (
              <Line data={processBikeData()} />
            ) : (
              <p>{t("No bike data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Total Bikes Added Over Time")}</h4>
            {bikeData.length > 0 ? (
              <Line data={processTotalBikeData()} />
            ) : (
              <p>{t("No bike data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Total Users Added Over Time")}</h4>
            {userData.length > 0 ? (
              <Line data={processTotalUserData()} />
            ) : (
              <p>{t("No user data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Total App Users")}</h4>
            {importedUsers.length > 0 ? (
              <Line data={processTotalImportedUserData()} />
            ) : (
              <p>{t("No imported user data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Total Stores Added Over Time")}</h4>
            {stores.length > 0 ? (
              <Line data={processTotalStoreData()} />
            ) : (
              <p>{t("No store data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Error Occurrences")}</h4>
            {errorData.length > 0 ? (
              <Bar data={processErrorData()} />
            ) : (
              <p>{t("No error data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Error Fixes")}</h4>
            {errorData.length > 0 ? (
              <div>
                {Object.entries(processFixData()).map(([errorCode, solutions]) => (
                  <div key={errorCode}>
                    <h5>{t("Error Code")}: {errorCode}</h5>
                    {Object.entries(solutions).map(([solution, steps]) => (
                      <div key={solution}>
                        <h6>{t("Solution")}: {solution}</h6>
                        <ul>
                          {Object.entries(steps).map(([step, count]) => (
                            <li key={step}>{t("Step")} {step}: {count} {t("times")}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <p>{t("No fix data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Total Trip Distance Over Time (km)")}</h4>
            {trips.length > 0 ? (
              <Line data={processTotalTripDistance()} />
            ) : (
              <p>{t("No trip data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Trip Distance per Day (Last 30 Days) (km)")}</h4>
            {trips.length > 0 ? (
              <Line data={processTripDistancePerDay()} />
            ) : (
              <p>{t("No trip data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Total Trips Over Time")}</h4>
            {trips.length > 0 ? (
              <Line data={processTotalTrips()} />
            ) : (
              <p>{t("No trip data available.")}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t("Trips per Day (Last 30 Days)")}</h4>
            {trips.length > 0 ? (
              <Line data={processTripsPerDay()} />
            ) : (
              <p>{t("No trip data available.")}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const HeatmapLayer = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const heatLayer = L.heatLayer(points, {
      radius: 25,
      blur: 15,
      maxZoom: 17,
      minOpacity: 0.5,
      max: 1.0,
    }).addTo(map);

    return () => {
      map.removeLayer(heatLayer);
    };
  }, [map, points]);

  return null;
};

const MapBounds = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    if (points.length > 0) {
      const bounds = L.latLngBounds(points.map(point => [point[0], point[1]]));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [points, map]);

  return null;
};

export default AnalyticsComponent;
