// FleetComponent.js
import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RestAPIService from '../../services/rest-api.service';
import SideMenu from './sidemenu.component';
import ViewBikes from './bikes.component';
import BikeDetail from './bikeDetail.component';
import TripsComponent from './trips.component';
import HomeComponent from './home.component';

const FleetComponent = ({ userGroup }) => {
  const { t } = useTranslation();
  const [bikes, setBikes] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    listBikesByBrand();
  }, [userGroup]);

  const listBikesByBrand = () => {
    RestAPIService.fetchBikesByBrand(userGroup).then(bikes => {
      setBikes(bikes);
      setMessage("");
    }).catch(error => {
      console.error(t("There was an error listing bikes by brand!"), error);
      setMessage(t("There was an error listing bikes by brand!"));
    });
  };

  return (
    <div className="container-fluid container-fleet-module">
      <div className="row">
        <div className="col-md-2">
          <SideMenu userGroup={userGroup} listBikesByBrand={listBikesByBrand} />
        </div>
        <div className="col-md-10 main-content">
          <Routes>
            <Route path="/" element={<HomeComponent bikes={bikes} userGroup={userGroup}/>} />
            <Route path="all" element={<ViewBikes bikes={bikes} listBikesByBrand={listBikesByBrand} userGroup={userGroup} />} />
            <Route path="trips" element={<TripsComponent userGroup={userGroup} />} />
            <Route path="bike/:id" element={<BikeDetail userGroup={userGroup} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
      {message && <div className="alert alert-danger">{message}</div>}
    </div>
  );
};

export default FleetComponent;
