import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import RestAPIService from '../../services/rest-api.service';
import useContent from "../../hooks/useContent.hook";
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MapComponent from '../admin/map.component'; // Import the MapComponent

const StoreDetailComponent = ({ userGroup }) => {
  const { id: storeId } = useParams();
  const navigate = useNavigate();
  const { fetchUsersByStore, deleteUser, resetUserPassword } = useContent();
  const { t } = useTranslation();
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    phone: '',
    latitude: null,
    longitude: null,
  });
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [showStoreModal, setShowStoreModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [key, setKey] = useState('store');
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    fetchStoreDetails();
    fetchStoreUsers();
  }, [storeId]);

  useEffect(() => {
    if (key === 'map') {
      setShowMap(true);
    }
  }, [key]);

  const fetchStoreDetails = () => {
    RestAPIService.getStore(storeId, userGroup).then(store => {
      setStore(store);
      setStores([store]);
    }).catch(error => {
      console.error(t("There was an error fetching the store details!"), error);
      setMessage(t("There was an error fetching the store details!"));
    });
  };

  const fetchStoreUsers = () => {
    fetchUsersByStore(storeId).then(users => {
      setUsers(users);
    }).catch(error => {
      console.error(t("There was an error fetching the store users!"), error);
      setMessage(t("There was an error fetching the store users!"));
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStore({ ...store, [name]: value });
  };

  const handleDeleteUser = () => {
    if (selectedUserId) {
      deleteUser(selectedUserId).then(() => {
        setMessage(t("User deleted successfully"));
        fetchStoreUsers();
        setShowUserModal(false);
        setSelectedUserId(null);
      }).catch(error => {
        console.error(t("There was an error deleting the user!"), error);
        setMessage(error.response ? error.response.data : t("There was an error deleting the user!"));
        setShowUserModal(false);
        setSelectedUserId(null);
      });
    }
  };

  const resetPassword = (userId) => {
    resetUserPassword(userId).then(() => {
      setMessage(t("Password reset email sent successfully"));
    }).catch(error => {
      console.error(t("There was an error sending the password reset email!"), error);
      setMessage(error.response ? error.response.data : t("There was an error sending the password reset email!"));
    });
  };

  const handleUpdateStore = (e) => {
    e.preventDefault();
    RestAPIService.updateStore(storeId, store).then(() => {
      setMessage(t("Store updated successfully"));
    }).catch(error => {
      console.error(t("There was an error updating the store!"), error);
      setMessage(error.response ? error.response.data : t("There was an error updating the store!"));
    });
  };

  const handleShowStoreModal = () => setShowStoreModal(true);
  const handleCloseStoreModal = () => setShowStoreModal(false);

  const handleShowUserModal = (userId) => {
    setSelectedUserId(userId);
    setShowUserModal(true);
  };
  const handleCloseUserModal = () => {
    setShowUserModal(false);
    setSelectedUserId(null);
  };

  return (
    <>
      <div className="container-fluid users-module-users-wrapper">
        <div className="row">
          <div className="col-12">
            <h3>{t("Edit Store")}</h3>
            <h4>{t("Details")}</h4>
          </div>
        </div>
      </div>

      <div className="container-fluid users-module-users-wrapper">
        <div className="row">
          <div className="col-12">
            <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mt-1">
              <Tab eventKey="store" title={t("Store")}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                      <form className="details" onSubmit={handleUpdateStore}>
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="name">{t("Store Name")}:</label>
                                <input type="text" name="name" value={store.name} onChange={handleInputChange} className="form-control" required />
                              </div>
                              <div className="form-group">
                                <label htmlFor="address1">{t("Address Line 1")}:</label>
                                <input type="text" name="address1" value={store.address1} onChange={handleInputChange} className="form-control" required />
                              </div>
                              <div className="form-group">
                                <label htmlFor="address2">{t("Address Line 2")}:</label>
                                <input type="text" name="address2" value={store.address2} onChange={handleInputChange} className="form-control" />
                              </div>
                              <div className="form-group">
                                <label htmlFor="city">{t("City")}:</label>
                                <input type="text" name="city" value={store.city} onChange={handleInputChange} className="form-control" required />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="state">{t("State/Province")}:</label>
                                <input type="text" name="state" value={store.state} onChange={handleInputChange} className="form-control" required />
                              </div>
                              <div className="form-group">
                                <label htmlFor="country">{t("Country")}:</label>
                                <select name="country" value={store.country || ''} onChange={handleInputChange} className="form-control" required>
                                  <option value="">{t("Select Country")}</option>
                                  <option value="United States" selected={store.country === 'US'}>{t("United States")}</option>
                                  <option value="Canada" selected={store.country === 'CA'}>{t("Canada")}</option>
                                  <option value="Taiwan" selected={store.country === 'TW'}>{t("Taiwan")}</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="postalCode">{t("Postal Code")}:</label>
                                <input type="text" name="postalCode" value={store.postalCode} onChange={handleInputChange} className="form-control" required />
                              </div>
                              <div className="form-group">
                                <label htmlFor="phone">{t("Phone Number")}:</label>
                                <input type="text" name="phone" value={store.phone} onChange={handleInputChange} className="form-control" required />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              {message && (
                                <div className="alert alert-info" role="alert">
                                  {message}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <button type="button" className="btn btn-danger" onClick={handleShowStoreModal}>{t("Delete Store")}</button>
                              <button type="submit" className="btn btn-success">{t("Update Store")}</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="users" title={t("Users")}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      {users.length > 0 ? (
                        <table className="table table-no-borders">
                          <thead>
                            <tr>
                              <th>{t("Username")}</th>
                              <th>{t("First Name")}</th>
                              <th>{t("Last Name")}</th>
                              <th>{t("Email")}</th>
                              <th>{t("Store")}</th>
                              <th>{t("Language")}</th>
                              <th>{t("Edit")}</th>
                              <th>{t("Reset Password")}</th>
                              <th>{t("Delete")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.map(user => (
                              <tr key={user.id}>
                                <td>{user.username}</td>
                                <td>{user.first_name}</td>
                                <td>{user.last_name}</td>
                                <td>{user.email}</td>
                                <td>{store.name} - {store.address1}</td>
                                <td>{user.language === 'fr' ? t("French") : t("English")}</td>
                                <td>
                                  <button onClick={() => navigate(`/users/all/${user.email}`)} className="btn btn-secondary-reverse btn-small">{t("Edit")}</button>
                                </td>
                                <td>
                                  <button onClick={() => resetPassword(user.id)} className="btn btn-secondary-reverse btn-small">{t("Reset")}</button>
                                </td>
                                <td>
                                  <button onClick={() => handleShowUserModal(user.id)} className="btn btn-small btn-danger">{t("Delete")}</button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p>{t("No users associated with this store.")}</p>
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="map" title={t("Map")}>
                <div className="container-fluid">
                  <div className="row mb-4">
                    <div className="col-12 mt-4">
                      {showMap && (
                        <MapComponent stores={stores} style={{ height: '300px' }} />
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>

      {/* Confirmation Modal for Store */}
      <Modal show={showStoreModal} onHide={handleCloseStoreModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Confirm Deletion")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Are you sure you want to delete this store?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseStoreModal}>
            {t("Cancel")}
          </Button>
          <Button variant="danger" onClick={() => {
            RestAPIService.deleteStore(storeId, userGroup)
              .then(() => {
                setMessage(t("Store deleted successfully"));
                setShowStoreModal(false);
                navigate('/users');
              })
              .catch(error => {
                console.error(t("There was an error deleting the store!"), error);
                setMessage(error.response ? error.response.data : t("There was an error deleting the store!"));
                setShowStoreModal(false);
              });
          }}>
            {t("Delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal for Users */}
      <Modal show={showUserModal} onHide={handleCloseUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Confirm Deletion")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Are you sure you want to delete this user?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUserModal}>
            {t("Cancel")}
          </Button>
          <Button variant="danger" onClick={handleDeleteUser}>
            {t("Delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StoreDetailComponent;
