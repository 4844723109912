import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Debug from "../../../services/debug.js";
import RestAPIService from "../../../services/rest-api.service";
import AnalyticsService from "../../../services/analytics.service";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const BikeDetailComponent = () => {
  const { bikeSerial } = useParams(); // Extract bike serial from URL parameters
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [batterySerial, setBatterySerial] = useState("");
  const [newBatterySerial, setNewBatterySerial] = useState("");
  const [newFtexSerial, setNewFtexSerial] = useState("");
  const [ftexSerial, setFtexSerial] = useState("");
  const [bikeBrand, setBikeBrand] = useState("");
  const [bikeModel, setBikeModel] = useState("");
  const [bikeYear, setBikeYear] = useState("");
  const [bikeRecords, setBikeRecords] = useState([]);
  const [source, setSource] = useState("");
  const [problem, setProblem] = useState("");
  const [comment, setComment] = useState("");
  const [showModal, setShowModal] = useState(false);

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          {t("This field is required!")}
        </div>
      );
    }
  };

  const fetchBikeInfo = async () => {
    try {
      let bikeInfo = await RestAPIService.getBike(bikeSerial);
      Debug.logger(2, 'bikeInfo', bikeInfo);
      setBatterySerial(bikeInfo.battery_serial);
      setFtexSerial(bikeInfo.ftex_serial);
      setBikeBrand(bikeInfo.brand);
      setBikeModel(bikeInfo.model);
      setBikeYear(bikeInfo.year);
    } catch (error) {
      Debug.logger(2, 'Error fetching bike info from Rest API Server', error);
    }
  };

  const fetchBikeRecords = async () => {
    try {
      let results = await RestAPIService.getBikeRecords(bikeSerial);
      Debug.logger(1, 'bike getBikeRecords', results);
      setBikeRecords(results);
    } catch (error) {
      console.error('Error fetching bike records:', error);
    }
  };

  useEffect(() => {
    fetchBikeInfo();
    fetchBikeRecords();
  }, [bikeSerial]);

  const handleBatterySerialChange = (e) => {
    e.preventDefault();

    setMessage("");
    if (!bikeSerial || !newBatterySerial) {
      return setMessage(t("Error updating battery serial number"));
    }

    RestAPIService.updateBikeBattery(bikeSerial, newBatterySerial)
      .then((response) => {
        if (response instanceof Error || response?.status >= 400) {
          console.log('RESPONSE battery update', response);
          const resMessage =
            response.response?.data?.message ||
            response.message ||
            response.toString();

          if (response.response?.status === 409) {
            setShowModal(true);
          } else {
            setMessage(resMessage);
          }
          return;
        }
        
        let error = t("Battery Serial Change");
        let comment = t("Battery Serial Number has been updated to ") + newBatterySerial;
        let params = {
          type: "serial"
        };
        AnalyticsService.addRecord(bikeSerial, error, comment, params);
        setNewBatterySerial("");
        fetchBikeInfo();
      })
      .catch((error) => {
        console.log('battery change error', error);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 409) {
          setShowModal(true);
        } else {
          setMessage(resMessage);
        }
      });
  };

  const handleForceRemoveBattery = async () => {
    try {
      await RestAPIService.removeBatteryFromBike(newBatterySerial);
      await RestAPIService.updateBikeBattery(bikeSerial, newBatterySerial);
      let error = t("Battery Serial Change");
      let comment = t("Battery Serial Number has been updated to ") + newBatterySerial;
      let params = {
        type: "serial"
      };
      AnalyticsService.addRecord(bikeSerial, error, comment, params);
      setNewBatterySerial("");
      fetchBikeInfo();
      setShowModal(false);
    } catch (error) {
      setMessage(t("Error forcing battery update: ") + error.toString());
    }
  };

  const onChangeBatterySerial = (e) => {
    setNewBatterySerial(e.target.value);
  };

  const handleControllerSerialChange = (e) => {
    e.preventDefault();

    setMessage2("");
    if (!bikeSerial || !newFtexSerial) {
      return setMessage(t("Error updating controller serial number"));
    }
    RestAPIService.updateBikeController(bikeSerial, newFtexSerial).then(
      () => {
        let error = t("Controller Serial Change");
        let comment = t("Controller Serial Number has been updated to ") + newFtexSerial;
        let params = {
          type: "serial"
        };
        AnalyticsService.addRecord(bikeSerial, error, comment, params);
        setNewFtexSerial("");
        fetchBikeInfo();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage2(resMessage);
      }
    );
  };

  const onChangeControllerSerial = (e) => {
    setNewFtexSerial(e.target.value);
  };

  const onChangeSource = (e) => {
    setSource(e.target.value);
  };

  const onChangeProblem = (e) => {
    setProblem(e.target.value);
  };

  const onChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();

    setMessage("");

    let recordObject = {
      type: "Manual",
      source: source,
      problem: problem,
      comment: comment
    };

    RestAPIService.addBikeRecord(bikeSerial, recordObject).then(
      () => {
        Debug.logger(1, 'success adding new record', recordObject);
        setSource("");
        setProblem("");
        setComment("");
        fetchBikeRecords();
      },
      (error) => {
        console.error('error adding new record', error);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  };

  return (
    <div className="container-fluid history-wrapper">
      <div className="row justify-content-center">
        {bikeBrand && bikeModel && bikeYear && (
          <div className="col-md-11">
            <header>
              <h2>
                {bikeBrand} {bikeModel} ({bikeYear})- &nbsp;
              </h2>
              <h3>
                 {bikeSerial}
              </h3>
              <span>{t("Battery")}: <span>{batterySerial}</span></span>
              <span>{t("Controller")}: <span>{ftexSerial}</span></span>
              <hr/>
            </header>
          </div>
        )}
        <div className="col-md-11">
          <div className="row">
            <div className="col-md-6">
              <h4>{t("Change Battery")}</h4>
              <hr/>
              <Form onSubmit={handleBatterySerialChange}>
                <div className="form-group">
                  <label htmlFor="batterySerial">{t('New Battery Serial')}</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="batterySerial"
                    placeholder={t('Battery Serial Number')}
                    value={newBatterySerial}
                    onChange={onChangeBatterySerial}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-primary btn-big"
                  >
                    <span>{t('Change Battery Serial')}</span>
                  </button>
                </div>

                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
              </Form>
            </div>
            <div className="col-md-6">
              <h4>{t("Change Controller")}</h4>
              <hr/>
              <Form onSubmit={handleControllerSerialChange}>
                <div className="form-group">
                  <label htmlFor="controllerSerial">{t('New Controller Serial')}</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="controllerSerial"
                    placeholder={t('Controller Serial Number')}
                    value={newFtexSerial}
                    onChange={onChangeControllerSerial}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-primary btn-big"
                  >
                    <span>{t('Change Controller Serial')}</span>
                  </button>
                </div>

                {message2 && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message2}
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
        <div className="col-md-11">
          <h4>{t("Add a Comment")}</h4>
          <hr/>
          <Form onSubmit={handleCommentSubmit}>
            <div className="form-group">
              <label htmlFor="source">{t('Retailer')}</label>
              <Input
                type="text"
                className="form-control"
                name="source"
                placeholder={t('Name of Retailer')}
                value={source}
                onChange={onChangeSource}
                validations={[required]}
              />
            </div>
            <div className="form-group">
              <label htmlFor="problem">{t('Description of Problem')}</label>
              <Input
                type="text"
                className="form-control"
                name="problem"
                placeholder={t('Ex. Error code 01')}
                value={problem}
                onChange={onChangeProblem}
                validations={[required]}
              />
            </div>
            <div className="form-group">
              <label htmlFor="comment">{t('Description of Solution')}</label>
              <textarea
                className="form-control"
                name="comment"
                placeholder={t('Ex. How the problem was solved..')}
                value={comment}
                onChange={onChangeComment}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-big"
              >
                <span>{t('Submit')}</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </div>
        <div className="col-md-11">
          <h4>{t("Bike Comments and History")}</h4>
          <hr/>
          {bikeRecords && bikeRecords.length && bikeRecords.map((record, index) => (
            <div
              key={index}
              className={`records ${record.type}`}
            >
              <span>{t("Date")}:<span>{record.date}</span></span>
              <span>{t("Retailer/Source")}:<span>{record.source}</span></span>
              <span>{t("Problem/Error")}:<span>{record.problem}</span></span>
              <span>{t("Comment")}:<span>{record.comment}</span></span>
            </div>
          ))}
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Battery Serial Conflict')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('The battery serial is already assigned to another bike. Do you want to force remove the battery from the other bike and assign it to this one?')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleForceRemoveBattery}>
            {t('Force Update')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BikeDetailComponent;
