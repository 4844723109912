import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const ResourcesComponent = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
  }, [currentLanguage]);

  return (
    <div className="container-fluid resources">
      <div className="row">
        <div className="col-12">
          {currentLanguage === 'fr' ? (
            <>
              <h3>{t('Ressources')}</h3>
              <h4>{t('Supplémentaires')}</h4>
            </>
          ) : (
            <>
              <h3>{t('Additional')}</h3>
              <h4>{t('Resources')}</h4>
            </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ul className={`additional-resources mt-4 row ${currentLanguage}`}>
            <li className="col-sm-6 col-md-4 col-lg-3" data-language="fr">
              <Link to="http://diagnostics-wp.ftex.ca/wp-content/uploads/2024/06/autorq-bb-fr.pdf" target="_blank" rel="noopener noreferrer">
                <span>Pedalier Autorq</span>
                <p>Comment installer un jeu de pedalier Autorq</p>
                <img src="/imgs/icons/icon-download.svg" alt="Download" />
              </Link>
            </li>
            <li className="col-sm-6 col-md-4 col-lg-3" data-language="en">
              <Link to="http://diagnostics-wp.ftex.ca/wp-content/uploads/2024/06/autorq-bb-en.pdf" target="_blank" rel="noopener noreferrer">
                <span>Autorq Sensor</span>
                <p>How to Install an Autorq PAS sensor</p>
                <img src="/imgs/icons/icon-download.svg" alt="Download" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ResourcesComponent;
