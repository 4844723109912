import React, { useEffect, useState } from 'react';
import MapComponent from './../admin/map.component';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import dayjs from 'dayjs';
import RestAPIService from '../../services/rest-api.service';
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DefaultContent = ({ stores, users, userGroup }) => {
  const { t } = useTranslation();
  const [connectionData, setConnectionData] = useState([]);
  const [topConnectionStores, setTopConnectionStores] = useState([]);
  const [loginData, setLoginData] = useState([]);
  const [topLoginStores, setTopLoginStores] = useState([]);

  useEffect(() => {
    const fetchConnections = async () => {
      try {
        const result = await RestAPIService.getConnections(userGroup);
        setConnectionData(result);
        processTopConnectionStores(result);
      } catch (error) {
        console.error(t('Error fetching connections:'), error);
      }
    };

    const fetchLogins = async () => {
      try {
        const result = await RestAPIService.getLogins(userGroup);
        setLoginData(result);
        processTopLoginStores(result);
      } catch (error) {
        console.error(t('Error fetching logins:'), error);
      }
    };

    fetchConnections();
    fetchLogins();
  }, [userGroup, t]);

  const calculateTotalUsers = () => {
    return users.length;
  };

  const calculateTotalConnections = (data) => {
    return data.length;
  };

const processTotalData = (data, label) => {
  if (!data) return { labels: [], datasets: [] };

  const weeks = [];
  const counts = Array(8).fill(0);
  const startDate = dayjs().subtract(7, 'weeks').startOf('week');

  data.forEach(record => {
    const week = dayjs(record.date).startOf('week');
    const index = week.diff(startDate, 'week');
    if (index >= 0 && index < 8) {
      counts[index] += 1;
    }
  });

  for (let i = 0; i < 8; i++) {
    weeks.push(startDate.add(i, 'week').format('DD MMM YY'));
  }

  // Remove leading weeks where data is zero
  while (counts.length > 0 && counts[0] === 0) {
    counts.shift();
    weeks.shift();
  }

  // Ensure at least 4 weeks are displayed
  while (counts.length < 4) {
    counts.unshift(0);
    weeks.unshift(startDate.subtract(counts.length + 1, 'week').format('DD MMM YY'));
  }

  return {
    labels: weeks,
    datasets: [
      {
        label: t(label),
        data: counts,
        fill: false,
        backgroundColor: label === t('Connections') ? 'rgba(75,192,192,0.4)' : 'rgba(192,75,192,0.4)',
        borderColor: label === t('Connections') ? 'rgba(75,192,192,1)' : 'rgba(192,75,192,1)',
        tension: 0.4, // Smoothing the line
      },
    ],
  };
};


  const processTopConnectionStores = (data) => {
    if (!data) return setTopConnectionStores([]);

    const storeCounts = {};

    data.forEach(record => {
      const store = record.params?.user?.store;
      if (store) {
        storeCounts[store] = (storeCounts[store] || 0) + 1;
      }
    });

    const sortedStores = Object.entries(storeCounts).sort((a, b) => b[1] - a[1]).slice(0, 10);
    const storeIds = sortedStores.map(([store]) => store);

    if (storeIds.length > 0) {
      RestAPIService.getStoreDetailsByIds(storeIds)
        .then(storeDetails => {
          const enrichedStores = sortedStores.map(([store, count]) => {
            const details = storeDetails.find(detail => detail._id.toString() === store);
            return {
              storeId: store,
              count: count,
              name: details ? details.name : t('Unknown'),
              address: details ? details.address : t('Unknown'),
            };
          });
          setTopConnectionStores(enrichedStores);
        })
        .catch(error => {
          console.error(t('Error fetching store details:'), error);
        });
    } else {
      setTopConnectionStores([]);
    }
  };

  const processTopLoginStores = (data) => {
    if (!data) return setTopLoginStores([]);

    const storeCounts = {};

    data.forEach(record => {
      const store = record.params?.store;
      if (store) {
        storeCounts[store] = (storeCounts[store] || 0) + 1;
      }
    });

    const sortedStores = Object.entries(storeCounts).sort((a, b) => b[1] - a[1]).slice(0, 10);
    const storeIds = sortedStores.map(([store]) => store);

    if (storeIds.length > 0) {
      RestAPIService.getStoreDetailsByIds(storeIds)
        .then(storeDetails => {
          const enrichedStores = sortedStores.map(([store, count]) => {
            const details = storeDetails.find(detail => detail._id.toString() === store);
            return {
              storeId: store,
              count: count,
              name: details ? details.name : t('Unknown'),
              address: details ? details.address : t('Unknown'),
            };
          });
          setTopLoginStores(enrichedStores);
        })
        .catch(error => {
          console.error(t('Error fetching store details:'), error);
        });
    } else {
      setTopLoginStores([]);
    }
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0, // Remove decimals
        },
        grid: {
          display: false // Remove grid lines
        }
      },
      x: {
        ticks: {
          callback: function(value) {
            return dayjs(this.getLabelForValue(value)).format('DD MMM YY');
          }
        },
        grid: {
          display: false // Remove grid lines
        }
      }
    },
    plugins: {
      legend: {
        display: false, // Remove legend
      },
    },
    elements: {
      line: {
        tension: 0.4, // Smoothing the line
      }
    }
  };

  return (
    <div className="container-fluid users-module-users-wrapper">
      <div className="row">
        <div className="col-12">
          <h3>{t("Stores and Users")}</h3>
          <h4>{t("Overview")}</h4>
        </div>
      </div>
      <div className="row stats-row">
        <div className="col-md-3">
          <div className="stat-icon white">
            <div className="img-container">
              <img src="../imgs/icons/icon-flag.svg" alt={t("Stores")} />
            </div>
            <span>
              {stores.length} {t("stores")}<br/>
              <span>
                {t("In my brand")}
              </span>
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="stat-icon white">
            <div className="img-container">
              <img src="../imgs/icons/icon-user.svg" alt={t("Users")} />
            </div>
            <span>
              {calculateTotalUsers()}<br/>
              <span>
                {t("Users")}
              </span>
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="stat-icon white">
            <div className="img-container">
              <img src="../imgs/icons/icon-bike.svg" alt={t("Bikes")} />
            </div>
            <span>
              {calculateTotalConnections(connectionData)}<br/>
              <span>
                {t("Connections")}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 margin-top">
          <h3 className="mb-4">{t('Store Locations')}</h3>
        </div>
        <div className="col-12">
          <MapComponent stores={stores} style={{ height: '300px' }} />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="chart-section">
            <h3 className="mb-4">
              {t('Total Connections per Week')}
            </h3>
            {connectionData.length > 0 ? (
              <Line data={processTotalData(connectionData, 'Connections')} options={chartOptions} />
            ) : (
              <p>{t('No connection data available.')}</p>
            )}
          </div>
          <div className="chart-section table">
            <h3 className="mb-4">
              {t('Top 10 Stores with Most Connections Last Month')}
            </h3>
            {topConnectionStores.length > 0 ? (
              <table className="table table-no-borders">
                <thead>
                  <tr>
                    <th>{t('Store')}</th>
                    <th>{t('Connections')}</th>
                  </tr>
                </thead>
                <tbody>
                  {topConnectionStores.map((store, index) => (
                    <tr key={index}>
                      <td>{store.name}</td>
                      <td>{store.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>{t('No store connection data available.')}</p>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="chart-section">
            <h3 className="mb-4">
              {t('Total Logins per Week')}
            </h3>
            {loginData.length > 0 ? (
              <Line data={processTotalData(loginData, 'Logins')} options={chartOptions} />
            ) : (
              <p>{t('No login data available.')}</p>
            )}
          </div>
          <div className="chart-section table">
            <h3 className="mb-4">
              {t('Top 10 Stores with Most Logins Last Month')}
            </h3>
            {topLoginStores.length > 0 ? (
              <table className="table table-no-borders">
                <thead>
                  <tr>
                    <th>{t('Store')}</th>
                    <th>{t('Logins')}</th>
                  </tr>
                </thead>
                <tbody>
                  {topLoginStores.map((store, index) => (
                    <tr key={index}>
                      <td>{store.name}</td>
                      <td>{store.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>{t('No store login data available.')}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultContent;
