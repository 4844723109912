import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import AuthService from "../services/auth.service";
import AnalyticsService from "../services/analytics.service";
import { useTranslation } from 'react-i18next';
import Debug from "../services/debug.js";

const BLEConnect = ({ isConnected, userGroup, setUserGroup, currentUser, setCurrentUser, connectToDevice, setLoadingScreen }) => {
  const { t, i18n } = useTranslation();
  const [userReady, setUserReady] = useState(false);
  const [bleConnectionError, setBleConnectionError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const authedUser = AuthService.getCurrentUser();
    const group = AuthService.getCurrentGroup();
    setUserGroup(group);

    if (!authedUser) {
      navigate("/login");
    }

    setCurrentUser(authedUser);
    Debug.logger(1, 'authedUser', authedUser);
    setUserReady(true);
  }, [navigate]);

  const handleFindBikes = async () => {
    setLoadingScreen(true);
    setBleConnectionError(false); // Reset error state before attempting to connect
    await connectToDevice(setLoadingScreen, setBleConnectionError, 1000)
      .then(serial => {
        if (serial) {
          const authedUser = AuthService.getCurrentUser();
          console.log('serial analytics', serial, authedUser, userGroup);
          const params = {
            user: authedUser,
            bikeSerial: serial,
          }
          AnalyticsService.track('bike-connection', userGroup, params);
        }
      })
  };

  useEffect(() => {
    if (isConnected) {
      Debug.logger(2, 'BLE connected to bike');
      setLoadingScreen(false);
      navigate("/bluetoothPreflight");
    } else if (bleConnectionError) {
      Debug.logger(2, 'BLE connection failed');
      setLoadingScreen(false);
    }
  }, [isConnected, bleConnectionError, navigate]);

  useEffect(() => {
    Debug.logger(1, 'userroup', userGroup)
  }, [userGroup]);

  return (
    <div className="container-fluid ble-wrapper text-center">
      <div className="col-md-12">
        {userReady && (
          <div>
            <header className="text-center">
              {userGroup === "Velec" ? (
                <img src="../imgs/clients/velec/logo.png" alt="Velec E-Bikes" />
              ) : userGroup === "Bike" ? (
                <img src="../imgs/clients/bike/logo.avif" alt="Bike" />
              ) : (
                <img src="../imgs/ftex-logo-color.png" alt="FTEX" />
              )}
              
              <img src="../imgs/bluetooth.png" alt="Bluetooth Icon" />
            </header>
            <button id="find-bikes" className="btn btn-primary btn-large" onClick={handleFindBikes}>
              {t('Connect to a Bike')}
            </button>
            {bleConnectionError && (
              <div>{t("Connection failed, please try again")}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BLEConnect;
