import React from 'react';
import useDiagnostics from './../../hooks/useDiagnostics.hook';
import MaintenanceComponent from './maintenance.component.js';

const RunMaintenance = (props) => {
  const maintenanceProps = useDiagnostics(props);
  return <MaintenanceComponent {...maintenanceProps} />;
};

export default RunMaintenance;
