import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SolutionViewComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { solution } = location.state || {};

  if (!solution) {
    return <div>{t('No solution details available.')}</div>;
  }

  const handleBackClick = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    if (pathParts.length > 1) {
      const parentPath = '/' + pathParts.slice(0, -1).join('/');
      navigate(parentPath);
    } else {
      navigate('/'); // Or navigate to a default route
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <h3>{t('Solution Details')}</h3>
          <h4>{solution.solution_description}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12 code-guides">
          <ul>
            {solution.steps.map((step, index) => (
              <li key={index}>
                <h5>{t('Step')} {index + 1}:</h5>
                <p dangerouslySetInnerHTML={{ __html: step.description }} />
              </li>
            ))}
          </ul>
          <button className="btn btn-big" onClick={handleBackClick}>
            {t('Back')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SolutionViewComponent;
