import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import RestAPIService from '../../services/rest-api.service';

const BikesComponent = ({ userGroup }) => {
  const { t } = useTranslation();
  const [serial, setSerial] = useState("");
  const [bikes, setBikes] = useState([]);
  const [message, setMessage] = useState("");
  const [generalMessage, setGeneralMessage] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    listAllBikes();
  }, [userGroup]);

  const searchBikeBySerial = (e) => {
    e.preventDefault();
    RestAPIService.getBike(serial).then(bike => {
      if (bike) {
        setBikes([bike]);
        setGeneralMessage("");
      } else {
        setBikes([]);
        setGeneralMessage(t("No bike found with that serial number!"));
      }
      setSearchPerformed(true);
    }).catch(error => {
      console.error("There was an error searching for the bike!", error);
      setGeneralMessage(t("There was an error searching for the bike!"));
      setSearchPerformed(true);
    });
  };

  const listAllBikes = () => {
    RestAPIService.listAllBikes(userGroup).then(bikes => {
      setBikes(bikes);
      setMessage("");
    }).catch(error => {
      console.error("There was an error listing all bikes!", error);
      setMessage(t("There was an error listing all bikes!"));
    });
  };

  const clearSearch = () => {
    listAllBikes();
    setSerial('');
    setGeneralMessage('');
    setSearchPerformed(false);
  };

  const viewBikeHistory = (bikeSerial) => {
    navigate(`/fleet/bike/${bikeSerial}`);
  };

  return (
    <div className="container-fluid fleet-module">
      <div className="row">
        <div className="col-6">
          <h3>{t("Search Bikes")}</h3>
          <h4>{t("by Serial Number:")}</h4>
        </div>
        <div className="col-6">
          <div className="search-form-container">
            <Form onSubmit={searchBikeBySerial}>
              <Form.Group controlId="formSearchBike">
                <Form.Control
                  type="text"
                  placeholder={t("Enter serial number")}
                  value={serial}
                  onChange={(e) => setSerial(e.target.value)}
                  required
                />
              </Form.Group>
              <Button className="btn btn-block btn-huge btn-primary" type="submit">
                {t("Search")}
              </Button>
            </Form>
          </div>
        </div>
      </div>
      {searchPerformed && (
        <div className="row">
          <div className="col-12 text-right">
            <Button variant="secondary" onClick={clearSearch}>
              {t("Clear Search")}
            </Button>
          </div>
        </div>
      )}
      {bikes.length === 0 && generalMessage && (
        <div className="row">
          <div className="col-12">
            <Alert variant="info">{generalMessage}</Alert>
          </div>
        </div>
      )}
      {bikes.length > 0 && (
        <div className="row">
          <div className="col-12">
            <table className="table table-no-borders">
              <thead>
                <tr>
                  <th>{t("Brand")}</th>
                  <th>{t("Model")}</th>
                  <th>{t("Year")}</th>
                  <th>{t("Bike Serial")}</th>
                  <th>{t("Battery Serial")}</th>
                  <th>{t("Controller Serial")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {bikes.map(bike => (
                  <tr key={bike.bike_serial}>
                    <td>{bike.brand}</td>
                    <td>{bike.model}</td>
                    <td>{bike.year}</td>
                    <td>{bike.bike_serial}</td>
                    <td>{bike.battery_serial}</td>
                    <td>{bike.ftex_serial}</td>
                    <td>
                      <button onClick={() => viewBikeHistory(bike.bike_serial)} className="btn btn-secondary-reverse btn-small">
                        {t("View Details")}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default BikesComponent;
