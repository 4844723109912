import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import useContent from "../hooks/useContent.hook";
import RestAPIService from "../services/rest-api.service";
import AnalyticsService from "../services/analytics.service";
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import Debug from "../services/debug.js";
import { useTranslation } from 'react-i18next';

function CustomToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className={`btn btn-small btn-secondary ${isCurrentEventKey ? "open" : ""}`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const BikeComponent = ({
  isConnected,
  isPreflightChecked,
  errorCode,
  bikeSerial,
  getBikeBrand,
  getBikeModel,
  setCurrentBikeError,
  getErrorCodeByBLE,
  setCurrentSolution,
  setBikeError,
}) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [currentError, setCurrentError] = useState(null);
  const [hasError, setHasError] = useState(true);
  const [batterySerial, setBatterySerial] = useState("");
  const [ftexSerial, setFtexSerial] = useState("");
  const [brandSerial, setBrandSerial] = useState("");
  const [bikeBrand, setBikeBrand] = useState("");
  const [bikeModel, setBikeModel] = useState("");
  const [bikeYear, setBikeYear] = useState("");

  useEffect(() => {
    if (!isConnected && !isPreflightChecked) {
      navigate('/bleConnect');
    }

    if (isConnected && !isPreflightChecked) {
      navigate('/bluetoothPreflight');
    }

    if (currentError && currentError > 0) {
      let error = "Error Code: " + currentError;
      let comment = "Error detect via bluetooth in diagnostics tool";
      let params = {
        type: "error",
        error: currentError,
      }
      AnalyticsService.addRecord(bikeSerial, error, comment, params);
      setHasError(true);
    }
  }, [isConnected, isPreflightChecked, errorCode]);

  function formatIndex(index) {
    return index < 10 ? `0${index}` : index.toString();
  }

  return (
    <div className="container-fluid bike-wrapper">
      <div className="row justify-content-center">
        <div className="col-md-11">
          <header>
            <span>{t("Controller")}: <span>{bikeSerial}</span></span>
            <hr/>
          </header>
        </div>
          {hasError && currentError ? (
            <>
              <div className="col-md-11">
                {t("Error Code")} : <span>{formatIndex(currentError)}</span>
              </div>
            </>
          ) : (
            <div className="col-md-11">
              <header>
                <h2>{t("No Current Errors Detected on this Bike!")}</h2>
              </header>
            </div>
          )}
      </div>
    </div>
  );
};

export default BikeComponent;