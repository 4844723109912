import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useContent from '../hooks/useContent.hook';

const HelpPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { brandGroup } = location.state || { brandGroup: 'FTEX' };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formValues, setFormValues] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    message: '',
    responsePreference: ''
  });

  const { sendEmail } = useContent();
  const { t, i18n } = useTranslation();

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailContent = `
      ${t('First Name')}: ${formValues.fname}
      ${t('Last Name')}: ${formValues.lname}
      ${t('Email')}: ${formValues.email}
      ${t('Phone')}: ${formValues.phone}
      ${t('Message')}: ${formValues.message}
      ${t('Preferred Response')}: ${formValues.responsePreference}
    `;
    try {
      await sendEmail({
        to: 'ramee.mossa@gmail.com', // Replace with the appropriate support email address
        subject: `${t('Support Request from')} ${formValues.fname} ${formValues.lname}`,
        message: emailContent,
        brand: brandGroup
      });
      setShowConfirmation(true);
    } catch (error) {
      console.error(t('Error sending email:'), error);
      alert(t('Failed to send email. Please try again.'));
    }
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div className="container-fluid help-page-component">
      <div className="row">
        <div className="col-3 offset-md-1 d-flex flex-column justify-content-between">
          <div>
            <h1>
              {t('How can we')} <br />
              <span>{t('help you')}?</span>
            </h1>
            <p className="mt-2 bold">{t('Welcome to our contact & technical support page')}</p>
            <p className="mt-4">{t('"You deserve fast and effective solutions! We\'re committed to providing the best possible customer service. Thank you for contacting us."')}</p>
            <p className="mt-4 uppercase">{brandGroup} {t('TEAM')}</p>
          </div>
          <button onClick={handleBackClick} className="btn btn-secondary mt-4">{t('Back')}</button>
        </div>
        <div className="col-6 offset-md-1 d-flex justify-content-center align-items-center">
          <form onSubmit={handleSubmit} className="w-100">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="fname">{t('First Name')}:</label>
                    <input type="text" id="fname" name="fname" className="form-control" required value={formValues.fname} onChange={handleChange} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">{t('Email')}:</label>
                    <input type="email" id="email" name="email" className="form-control" required value={formValues.email} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="lname">{t('Last Name')}:</label>
                    <input type="text" id="lname" name="lname" className="form-control" required value={formValues.lname} onChange={handleChange} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">{t('Phone Number')}:</label>
                    <input type="text" id="phone" name="phone" className="form-control" required value={formValues.phone} onChange={handleChange} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="message">{t('Message')}:</label>
                    <textarea id="message" name="message" className="form-control" rows="5" required value={formValues.message} onChange={handleChange}></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-9">
                  <div className="form-group">
                    <Form.Group controlId="formStoreState">
                      <Form.Label>{t('What kind of response would you prefer?')}</Form.Label>
                      <Form.Control
                        as="select"
                        name="responsePreference"
                        value={formValues.responsePreference}
                        onChange={handleChange}
                        required
                      >
                        <option value="">{t('Select...')}</option>
                        <option value="Call">{t('Call')}</option>
                        <option value="Email">{t('Email')}</option>
                        <option value="No Preference">{t('No Preference')}</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-3">
                  <input type="hidden" name="brandGroup" value={brandGroup} />
                  <button type="submit" className="btn btn-primary">{t('Submit')}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Modal show={showConfirmation} onHide={handleConfirmationClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 email-success">
                <img src="../imgs/sidebar/icon-help.svg" alt={t('Successfully sent email')} />
                <h1>{t('Your request has been submitted!')}</h1>
                <h4>{t('We will provide an update soon.')}</h4>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-block btn-primary" onClick={handleConfirmationClose}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HelpPage;
