import React, { useEffect, useState } from 'react';
import RestAPIService from '../../services/rest-api.service';
import { Bar, Line } from 'react-chartjs-2';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet.heat';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);

const TripsComponent = ({ userGroup }) => {
  const { t } = useTranslation();
  const [trips, setTrips] = useState([]);
  const [totalTripDistance, setTotalTripDistance] = useState(0);
  const [totalTrips, setTotalTrips] = useState(0);

  useEffect(() => {
    RestAPIService.fetchSessions()
      .then(data => {
        setTrips(data);
        setTotalTrips(data.length);
        const totalDistance = Math.round(data.reduce((acc, trip) => acc + (trip.distance || 0), 0));
        setTotalTripDistance(totalDistance);
      })
      .catch(error => console.error('Error fetching trips:', error));
  }, []);

  // Prepare heatmap data
  const tripHeatmapData = trips
    .flatMap(trip => trip.gpsLocations)
    .filter(location => location && location.longitude !== undefined && location.latitude !== undefined)
    .map(location => [location.latitude, location.longitude, 1]);

  // Calculate the average range per PAS level
  const calculateAverageRangePerPASLevel = () => {
    const pasRanges = {};

    trips.forEach(trip => {
      if (trip.data) {
        const { avgPASLevel, totalDistance, maxBatteryLevel, minBatteryLevel } = trip.data;
        const batteryUsed = maxBatteryLevel - minBatteryLevel;

        if (batteryUsed > 0) {
          const rangeForFullBattery = (totalDistance / batteryUsed) * 100;

          const pasLevel = Math.round(avgPASLevel);
          if (!pasRanges[pasLevel]) {
            pasRanges[pasLevel] = { totalRange: 0, count: 0 };
          }
          pasRanges[pasLevel].totalRange += rangeForFullBattery;
          pasRanges[pasLevel].count += 1;
        }
      }
    });

    return Object.keys(pasRanges).map(level => ({
      pasLevel: parseInt(level, 10),
      averageRange: pasRanges[level].totalRange / pasRanges[level].count
    }));
  };

  const pasData = calculateAverageRangePerPASLevel();

  const pasChartData = {
    labels: pasData.map(data => `PAS ${data.pasLevel}`),
    datasets: [
      {
        label: t('Estimated Range (km) per 100% Battery'),
        data: pasData.map(data => data.averageRange),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }
    ]
  };

  const getLastSixMonths = () => {
    const months = [];
    const date = new Date();
    for (let i = 5; i >= 0; i--) {
      const month = new Date(date.getFullYear(), date.getMonth() - i, 1);
      months.push(month.toLocaleString('en-US', { month: 'short' }));
    }
    return months;
  };

  const processCumulativeTripDistance = () => {
    const counts = trips.reduce((acc, trip) => {
      const date = new Date(trip.startTime);
      const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}`;
      if (!isNaN(date)) {
        acc[formattedDate] = (acc[formattedDate] || 0) + trip.distance;
      }
      return acc;
    }, {});

    const dates = Object.keys(counts).sort((a, b) => new Date(a.split('/').reverse().join('/')) - new Date(b.split('/').reverse().join('/')));
    const cumulativeCounts = dates.map((date, index) => dates.slice(0, index + 1).reduce((sum, currentDate) => sum + counts[currentDate], 0));

    return {
      labels: dates,
      datasets: [
        {
          label: t('Total Trip Distance Over Time (km)'),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: 'rgba(255,159,64,0.4)',
          borderColor: 'rgba(255,159,64,1)',
          tension: 0.6, // Smoothing the line
          borderWidth: 2
        }
      ]
    };
  };

  const cumulativeTripData = processCumulativeTripDistance();

  return (
    <div className="container-fluid fleet-module">
      <div className="row">
        <div className="col-12">
          <h3>{t("General Overview")}</h3>
          <h4>{t("Location and Activity")}</h4>
        </div>
      </div>
      <div className="row stats-row">
        <div className="col-md-3">
          <div className="stat-icon white">
            <div className="img-container">
              <img src="../imgs/icons/icon-route.svg" alt={t("Total Trip Distance")} />
            </div>
            <span>
              {totalTripDistance} {t("km")}<br/>
              <span>{t("Total trip distance")}</span>
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="stat-icon white">
            <div className="img-container">
              <img src="../imgs/icons/icon-map.svg" alt={t("Total Trips")} />
            </div>
            <span>
              {totalTrips} {t("trips")}<br/>
              <span>{t("Total trips")}</span>
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h3 className="mb-3">{t("Location of Trips")}</h3>
          <MapContainer style={{ height: "500px", width: "100%" }} center={[0, 0]} zoom={2}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <HeatmapLayer points={tripHeatmapData} />
            <MapBounds points={tripHeatmapData} />
          </MapContainer>
        </div>
      </div>
{/*      <div className="row">
        <div className="col-12">
          <h3 className="mt-5">{t("Estimated Range per PAS Level (km per 100% Battery)")}</h3>
          <Bar data={pasChartData} options={{ responsive: true, plugins: { legend: { display: false } } }} />
        </div>
      </div>*/}
      <div className="row">
        <div className="col-12">
          <h3 className="mt-5">{t("Cumulative Trip Distance Over Time (Last 6 Months)")}</h3>
          <Line 
            data={cumulativeTripData} 
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  display: true,
                  title: {
                    display: true,
                  },
                  grid: {
                    display: false,
                  },
                },
                y: {
                  display: true,
                  title: {
                    display: true,
                  },
                  grid: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
            style={{ maxHeight: '300px' }}
          />
        </div>
      </div>
    </div>
  );
};

const HeatmapLayer = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const heatLayer = L.heatLayer(points, {
      radius: 25,
      blur: 15,
      maxZoom: 17,
      minOpacity: 0.5,
      max: 1.0,
    }).addTo(map);

    return () => {
      map.removeLayer(heatLayer);
    };
  }, [map, points]);

  return null;
};

const MapBounds = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    // Filter out invalid points
    const validPoints = points.filter(
      point => point.latitude !== undefined && point.longitude !== undefined
    );

    if (validPoints.length > 0) {
      // Create bounds only with valid points
      const bounds = L.latLngBounds(validPoints.map(point => [point.latitude, point.longitude]));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [points, map]);

  return null;
};


export default TripsComponent;
