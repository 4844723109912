import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link, Routes, Route } from 'react-router-dom';
import UsersComponent from './admin/users.component.js';
import BikesComponent from './admin/bikes.component.js';
import StoreDetailComponent from './admin/stores/storeDetail.component.js';
import UserDetailComponent from './admin/users/userDetail.component.js';
import BikeDetailComponent from './admin/bikes/bikeDetail.component.js';
import AnalyticsComponent from './admin/analytics.component.js';

const AdminComponent = ({
  isConnected,
  isPreflightChecked,
  errorCode,
  bikeSerial,
  getBikeBrand,
  getBikeModel,
  setCurrentBikeError,
  getErrorCodeByBLE,
  setCurrentSolution,
  setBikeError,
  userGroup
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPageTitle, setCurrentPageTitle] = useState('');

  useEffect(() => {
    if (location.pathname === '/admin') {
      navigate('/admin/users');
    }
  }, [location, navigate]);

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    let title = "";
    switch (path) {
      case "users":
        title = "Users";
        break;
      case "bikes":
        title = "Bikes";
        break;
      case "analytics":
        title = "Analytics";
        break;
      default:
        title = "";
    }
    setCurrentPageTitle(title);
  }, [location]);

  return (
    <>
      <nav className="navbar navbar-expand navbar-dark bg-dark sidenav">
        <div className="navbar-nav">
          <ul>
            <li className="nav-item">
              <Link to="/admin/users" className={`nav-item ${location.pathname.startsWith("/admin/user") ? "active" : ""}`}>
                Users
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admin/bikes" className={`nav-item ${location.pathname.startsWith("/admin/bike") ? "active" : ""}`}>
                Bikes
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admin/analytics" className={`nav-item ${location.pathname.startsWith("/admin/analytics") ? "active" : ""}`}>
                Analytics
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-item">
                Go Back
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container-fluid admin-wrapper">
        <div className="row justify-content-center">
          <div className="col-md-11">
            <img src="../imgs/ftex-logo-color.png" alt="FTEX Evionics Operating System" />
            <h1>Diagnostics Tool Admin</h1>
            <h2 id="current-page-title">{currentPageTitle}</h2>        
            <div className="display">
              <Routes>
                // <Route path="users" element={<UsersComponent userGroup={userGroup} />} />
                // <Route path="user/:email" element={<UserDetailComponent userGroup={userGroup} />} />
                // <Route path="stores/:store" element={<StoreDetailComponent userGroup={userGroup} />} />
                // <Route path="bikes/:bikeSerial" element={<BikeDetailComponent userGroup={userGroup} />} />
                // <Route path="bikes" element={<BikesComponent userGroup={userGroup} />} />
                // <Route path="analytics" element={<AnalyticsComponent userGroup={userGroup} />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminComponent;
