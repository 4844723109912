const lessVariablesConfig = {
  ftex: {
    primary: "#FF6700",
    secondary: "#234354",
  },
  velec: {
    primary: "#da1d1d",
    secondary: "#234354",
  },
  milebox: {
    primary: "rgba(51, 127, 96, 1)",
    secondary: "rgba(201, 137, 86, 1)",
  },
  super73: {
    primary: "#000000",
    secondary: "#b58845",
  },
  handleworks: {
    primary: "#EB820D",
    secondary: "#000000",
  },
  fabled: {
    primary: "#EDB4BE",
    secondary: "#D2CBC1",
  }

};

export default lessVariablesConfig;
