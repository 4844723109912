import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Debug from "../../services/debug.js";
import RestAPIService from "../../services/rest-api.service";
import AnalyticsService from "../../services/analytics.service";
import { format } from 'date-fns';
import BatterySerialModal from './BatterySerialModal';
import ControllerSerialModal from './ControllerSerialModal';
import AddCommentModal from './AddCommentModal';

const BikeDetailComponent = () => {
  const { id } = useParams(); // Extract bike ID from URL parameters
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [batterySerial, setBatterySerial] = useState("");
  const [ftexSerial, setFtexSerial] = useState("");
  const [bikeBrand, setBikeBrand] = useState("");
  const [bikeModel, setBikeModel] = useState("");
  const [bikeYear, setBikeYear] = useState("");
  const [bikeRecords, setBikeRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedErrorCode, setSelectedErrorCode] = useState('');
  const [selectedSource, setSelectedSource] = useState('');
  const [analyticsData, setAnalyticsData] = useState([]); // State for storing analytics data

  const [showBatteryModal, setShowBatteryModal] = useState(false);
  const [showControllerModal, setShowControllerModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);

  const fetchBikeInfo = async () => {
    try {
      let bikeInfo = await RestAPIService.getBike(id); // Use the extracted bike ID
      Debug.logger(2, 'bikeInfo', bikeInfo);
      setBatterySerial(bikeInfo.battery_serial);
      setFtexSerial(bikeInfo.ftex_serial);
      setBikeBrand(bikeInfo.brand);
      setBikeModel(bikeInfo.model);
      setBikeYear(bikeInfo.year);
    } catch (error) {
      Debug.logger(2, 'Error fetching bike info from Rest API Server', error);
    }
  };

  const fetchBikeRecords = async () => {
    try {
      let results = await RestAPIService.getBikeRecords(id); // Use the extracted bike ID
      Debug.logger(1, 'bike getBikeRecords', results);
      setBikeRecords(results);
      setFilteredRecords(results);
    } catch (error) {
      console.error('Error fetching bike records:', error);
    }
  };

  const fetchAnalyticsData = async () => {
    try {
      const brand = "velec"; // Replace with the actual brand if dynamic
      let response = await RestAPIService.getAnalyticsData(brand, id);
      setAnalyticsData(response.data);
    } catch (error) {
      Debug.logger(2, 'Error fetching analytics data from Rest API Server', error);
    }
  };

  useEffect(() => {
    fetchBikeInfo();
    fetchBikeRecords();
    fetchAnalyticsData(); // Fetch analytics data
  }, [id]);

  const determineRecordType = (record) => {
    if (record.comment.includes(t("Battery Serial Number"))) {
      return t("Battery Serial Change");
    } else if (record.comment.includes(t("Controller Serial Number"))) {
      return t("Controller Serial Change");
    } else if (record.problem && record.problem.toLowerCase().includes(t("error"))) {
      return t("Error");
    } else if (record.source && record.source.toLowerCase().includes(t("retailer"))) {
      return t("Retailer Comment");
    } else {
      return t("Brand Comment");
    }
  };

  const getAvailableTypes = () => {
    const types = bikeRecords.map(record => determineRecordType(record));
    return [...new Set(types)];
  };

  const getAvailableErrorCodes = () => {
    const errorCodes = bikeRecords.map(record => {
      if (record.problem && record.problem.toLowerCase().includes(t("error"))) {
        return record.problem;
      }
      return '';
    }).filter(Boolean);
    return [...new Set(errorCodes)];
  };

  const getAvailableSources = () => {
    const sources = bikeRecords.map(record => record.source).filter(Boolean);
    return [...new Set(sources)];
  };

  const applyFilters = () => {
    let filtered = bikeRecords;

    if (startDate) {
      filtered = filtered.filter(record => new Date(record.date) >= new Date(startDate));
    }

    if (endDate) {
      filtered = filtered.filter(record => new Date(record.date) <= new Date(endDate));
    }

    if (selectedType) {
      filtered = filtered.filter(record => determineRecordType(record) === selectedType);
    }

    if (selectedErrorCode) {
      filtered = filtered.filter(record => record.problem && record.problem.includes(selectedErrorCode));
    }

    if (selectedSource) {
      filtered = filtered.filter(record => record.source === selectedSource);
    }

    setFilteredRecords(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [startDate, endDate, selectedType, selectedErrorCode, selectedSource, bikeRecords]);

  const bikeConnectionsCount = analyticsData.filter(data => data.action === 'bike-connection').length;
  const bikeErrorsCount = bikeRecords.filter(data => data.params && data.params.type === 'error').length;
  const bikeCommentsCount = bikeRecords.length;

  return (
    <>
      <div className="container-fluid bike-detail">
        <div className="row">
          <div className="col-6">
            <h3>{t("Search Bikes")}</h3>
            <h4>{t("by Serial Number:")}</h4>
          </div>
          <div className="col-md-9">
            <div className="bike-detail-summary">
              {bikeBrand && bikeModel && bikeYear && (
                <>
                  <h2>
                    <span>{bikeBrand}</span> {bikeModel} ({bikeYear})&nbsp;-&nbsp;
                  </h2>
                  <h3>{id}</h3>
                  <div className="row info-box-container">
                    <div className="col info-box">
                      <h5>{t("Controller Serial:")}</h5>
                      <span>{ftexSerial}</span>
                      <button className="btn btn-danger btn-block" onClick={() => setShowControllerModal(true)}>{t("Edit")}</button>
                    </div>
                    <div className="col info-box">
                      <h5>{t("Battery Serial:")}</h5>
                      <span>{batterySerial}</span>
                      <button className="btn btn-danger btn-block" onClick={() => setShowBatteryModal(true)}>{t("Edit")}</button>
                    </div>
                    <div className="col info-box">
                      <h5>{t("Model:")}</h5>
                      <span>{bikeModel}</span>
                    </div>
                    <div className="col info-box">
                      <h5>{t("Year:")}</h5>
                      <span>{bikeYear}</span>
                    </div>
                    <div className="col info-box">
                      <h5>{t("Bike Serial:")}</h5>
                      <span>{id}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row fleet-module">
          <div className="col-12">
            <h4>{t("Activities on This Bike")}</h4>
          </div>
          <div className="col-3">
            <div className="stat-icon white">
              <div className="img-container">
                <img src="../../imgs/icons/icon-flag.svg" alt={t("Connections")} />
              </div>
              <span>
                {bikeConnectionsCount}<br/>
                <span>{t("Connections")}</span>
              </span>
            </div>
          </div>
          <div className="col-3">
            <div className="stat-icon white">
              <div className="img-container">
                <img src="../../imgs/icons/icon-flag.svg" alt={t("Errors Detected")} />
              </div>
              <span>
                {bikeErrorsCount}<br/>
                <span>{t("Errors Detected")}</span>
              </span>
            </div>
          </div>
          <div className="col-3">
            <div className="stat-icon white">
              <div className="img-container">
                <img src="../../imgs/icons/icon-flag.svg" alt={t("Comments")} />
              </div>
              <span>
                {bikeCommentsCount}<br/>
                <span>{t("Comments")}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="row fleet-module">
          <div className="col-12">
            <h4 className="mt-4">{t("Bike History")}</h4>
          </div>
        </div>
      </div>
      <div className="container-fluid bike-detail">
        <div className="row">
          <div className="col-md-12">
            <button className="btn btn-secondary add-comment mb-3" onClick={() => setShowCommentModal(true)}>
              {t("Add Comment")}
              <img src="/imgs/icons/icon-plus.svg" alt={t("Add Comment")} />
            </button>
          </div>
          </div>
        <div className="row">
          <div className="col-12">
            <div className="filters">
              <div className="clear-filters col">
                {startDate && (
                  <button className="btn btn-filter btn-small btn-inline-block" onClick={() => setStartDate('')}>
                    {t("After Date")}
                    <img src="/imgs/icons/icon-x.svg" alt={t("Remove filter")} />
                  </button>
                )}
                {endDate && (
                  <button className="btn btn-filter btn-small btn-inline-block" onClick={() => setEndDate('')}>
                    {t("Before Date")}
                    <img src="/imgs/icons/icon-x.svg" alt={t("Remove filter")} />
                  </button>
                )}
                {selectedType && (
                  <button className="btn btn-filter btn-small btn-inline-block" onClick={() => setSelectedType('')}>
                    {t("Type")}
                    <img src="/imgs/icons/icon-x.svg" alt={t("Remove filter")} />
                  </button>
                )}
                {selectedErrorCode && (
                  <button className="btn btn-filter btn-small btn-inline-block" onClick={() => setSelectedErrorCode('')}>
                    {t("Error Code")}
                    <img src="/imgs/icons/icon-x.svg" alt={t("Remove filter")} />
                  </button>
                )}
                {selectedSource && (
                  <button className="btn btn-filter btn-small btn-inline-block" onClick={() => setSelectedSource('')}>
                    {t("Source")}
                    <img src="/imgs/icons/icon-x.svg" alt={t("Remove filter")} />
                  </button>
                )}
              </div>
              <div className="filter-input col">
                <div className="filter">
                  <label htmlFor="startDate">{t("After:")}</label>
                  <input
                    type="date"
                    id="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className="filter">
                  <label htmlFor="endDate">{t("Before:")}</label>
                  <input
                    type="date"
                    id="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                {getAvailableTypes().length > 0 && (
                  <div className="filter">
                    <select
                      className="form-select"
                      id="typeFilter"
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      <option value="">{t("Sort by Comment Type")}</option>
                      {getAvailableTypes().map((type, index) => (
                        <option key={index} value={type}>{type}</option>
                      ))}
                    </select>
                  </div>
                )}
                {getAvailableErrorCodes().length > 0 && (
                  <div className="filter">
                    <select
                      className="form-select"
                      id="errorCodeFilter"
                      value={selectedErrorCode}
                      onChange={(e) => setSelectedErrorCode(e.target.value)}
                    >
                      <option value="">{t("Sort by Error Code")}</option>
                      {getAvailableErrorCodes().map((code, index) => (
                        <option key={index} value={code}>{code}</option>
                      ))}
                    </select>
                  </div>
                )}
                {getAvailableSources().length > 0 && (
                  <div className="filter">
                    <select
                      className="form-select"
                      id="sourceFilter"
                      value={selectedSource}
                      onChange={(e) => setSelectedSource(e.target.value)}
                    >
                      <option value="">{t("Sort by Source")}</option>
                      {getAvailableSources().map((source, index) => (
                        <option key={index} value={source}>{source}</option>
                      ))}
                    </select>
                  </div>
                )}
                {bikeRecords.some(record => record.source === t('brand')) && (
                  <div className="filter">
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedSource === 'brand'}
                        onChange={(e) => setSelectedSource(e.target.checked ? 'brand' : '')}
                      />
                      {t("Brand Comments Only")}
                    </label>
                  </div>
                )}
              </div>
            </div>
            <table className="table table-no-borders">
              <thead>
                <tr>
                  <th>{t("Date")}</th>
                  <th>{t("Retailer/Source")}</th>
                  <th>{t("Problem/Error")}</th>
                  <th>{t("Comment")}</th>
                  <th>{t("Error Code")}</th>
                  <th>{t("Type")}</th>
                </tr>
              </thead>
              <tbody>
                {filteredRecords.length > 0 ? (
                  filteredRecords.map((record, index) => (
                    <tr key={index}>
                      <td>{format(new Date(record.date), 'dd MM yyyy - HH:mm')}</td>
                      <td>{record.source}</td>
                      <td>{record.problem}</td>
                      <td>{record.comment}</td>
                      <td>{record.problem && record.problem.toLowerCase().includes(t('error')) ? record.problem : ''}</td>
                      <td>{determineRecordType(record)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">{t("No records found")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Modals */}
      <BatterySerialModal
        show={showBatteryModal}
        handleClose={() => setShowBatteryModal(false)}
        bikeId={id}
        fetchBikeInfo={fetchBikeInfo}
        AnalyticsService={AnalyticsService}
        RestAPIService={RestAPIService}
      />
      <ControllerSerialModal
        show={showControllerModal}
        handleClose={() => setShowControllerModal(false)}
        bikeId={id}
        fetchBikeInfo={fetchBikeInfo}
        AnalyticsService={AnalyticsService}
        RestAPIService={RestAPIService}
      />
      <AddCommentModal
        show={showCommentModal}
        handleClose={() => setShowCommentModal(false)}
        bikeId={id}
        fetchBikeRecords={fetchBikeRecords}
        RestAPIService={RestAPIService}
      />
    </>
  );
};

export default BikeDetailComponent;
