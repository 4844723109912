import React, { useEffect, useState } from 'react';
import RestAPIService from '../../services/rest-api.service';
import MapComponent from './../admin/map.component';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import useContent from "../../hooks/useContent.hook";
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet.heat';
import L from 'leaflet';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const StoresComponent = ({ userGroup }) => {
  const { fetchUsersByBrand } = useContent();
  const { t } = useTranslation();
  const [stores, setStores] = useState([]);
  const [bikeData, setBikeData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [storeLocations, setStoreLocations] = useState([]);

  useEffect(() => {
    RestAPIService.fetchStoresByBrand(userGroup)
      .then(data => {
        console.log("Fetched stores:", data);
        setStores(data);
        const locations = data.map(store => ({
          lat: store.latitude,
          lng: store.longitude,
        }));
        setStoreLocations(locations);
      })
      .catch(error => {
        console.error("Error fetching stores:", error);
      });

    RestAPIService.fetchBikesByBrand(userGroup)
      .then(data => {
        console.log("Fetched bikes:", data);
        setBikeData(data);
      })
      .catch(error => {
        console.error("Error fetching bikes:", error);
      });

    fetchUsersByBrand(userGroup)
      .then(data => {
        console.log("Fetched users:", data);
        setUserData(data);
      })
      .catch(error => {
        console.error("Error fetching users:", error);
      });
  }, [userGroup]);

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  };

  const processBikeData = () => {
    const today = new Date();
    const past30Days = Array.from({ length: 30 }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      return date.toISOString().split('T')[0];
    }).reverse();

    const counts = bikeData.reduce((acc, bike) => {
      const date = new Date(bike.date).toISOString().split('T')[0];
      if (past30Days.includes(date)) {
        acc[date] = (acc[date] || 0) + 1;
      }
      return acc;
    }, {});

    const bikeCounts = past30Days.map(date => counts[date] || 0);

    console.log("Processed bike counts for the last 30 days:", bikeCounts);

    return {
      labels: past30Days,
      datasets: [
        {
          label: t('Bikes Connected per Day (Last 30 Days)'),
          data: bikeCounts,
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    };
  };

  const processTotalUserData = () => {
    const counts = userData.reduce((acc, user) => {
      if (isValidDate(user.date_registered)) {
        const date = new Date(user.date_registered.replace(' ', 'T')).toISOString().split('T')[0];
        acc[date] = (acc[date] || 0) + 1;
      }
      return acc;
    }, {});

    const dates = Object.keys(counts).sort((a, b) => new Date(a) - new Date(b));
    const cumulativeCounts = dates.map((date, index) => {
      return dates.slice(0, index + 1).reduce((sum, currentDate) => sum + counts[currentDate], 0);
    });

    console.log("Processed total user data:", cumulativeCounts);

    return {
      labels: dates,
      datasets: [
        {
          label: t('Total Users Added Over Time'),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: 'rgba(153,102,255,0.4)',
          borderColor: 'rgba(153,102,255,1)',
        },
      ],
    };
  };

  const processTotalStoreData = () => {
    const counts = stores.reduce((acc, store) => {
      if (isValidDate(store.date)) {
        const date = new Date(store.date).toISOString().split('T')[0];
        acc[date] = (acc[date] || 0) + 1;
      }
      return acc;
    }, {});

    const dates = Object.keys(counts).sort((a, b) => new Date(a) - new Date(b));
    const cumulativeCounts = dates.map((date, index) => {
      return dates.slice(0, index + 1).reduce((sum, currentDate) => sum + counts[currentDate], 0);
    });

    console.log("Processed total store data:", cumulativeCounts);

    return {
      labels: dates,
      datasets: [
        {
          label: t('Total Stores Added Over Time'),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: 'rgba(255,206,86,0.4)',
          borderColor: 'rgba(255,206,86,1)',
        },
      ],
    };
  };

  return (
    <div className="container-fluid fleet-module">
      <div className="row">
        <div className="col-12">
          <h3>{t('General Overview')}</h3>
          <h4>{t('Location and Activity')}</h4>
        </div>
      </div>
      <div className="row stats-row">
        <div className="col-md-3">
          <div className="stat-icon">
            <div className="img-container">
              <img src="../imgs/icons/icon-bike.svg" alt={t('Bike Trips')} />
            </div>
            <span>
              {stores.length} {t('stores')}<br/>
              <span>
                {t('In my brand')}
              </span>
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="stat-icon">
            <div className="img-container">
              <img src="../imgs/icons/icon-flag.svg" alt={t('Stores')} />
            </div>
            <span>
              {t('XX stores')}<br/>
              <span>
                {t('In my brand')}
              </span>
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="stat-icon">
            <div className="img-container">
              <img src="../imgs/icons/icon-route.svg" alt={t('Total Trip Distance')} />
            </div>
            <span>
              {t('XX km')}<br/>
              <span>
                {t('Total trip distance')}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2 className="text-secondary-color mb-4">{t('Fleet Maps')}</h2>
        </div>
        <div className="col-md-6">
          <h3 className="mb-3">{t('Location of Trips')}</h3>
          <MapComponent stores={storeLocations} />
        </div>
        <div className="col-md-6">
          <h3 className="mb-3">{t('Location of Bikes')}</h3>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t('Bikes Connected per Day (Last 30 Days)')}</h4>
            {bikeData.length > 0 ? (
              <Line data={processBikeData()} />
            ) : (
              <p>{t('No bike data available.')}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t('Total Users Added Over Time')}</h4>
            {userData.length > 0 ? (
              <Line data={processTotalUserData()} />
            ) : (
              <p>{t('No user data available.')}</p>
            )}
          </div>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t('Total Stores Added Over Time')}</h4>
            {stores.length > 0 ? (
              <Line data={processTotalStoreData()} />
            ) : (
              <p>{t('No store data available.')}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const HeatmapLayer = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const heatLayer = L.heatLayer(points, {
      radius: 25,
      blur: 15,
      maxZoom: 17,
      minOpacity: 0.5,
      max: 1.0,
    }).addTo(map);

    return () => {
      map.removeLayer(heatLayer);
    };
  }, [map, points]);

  return null;
};

const MapBounds = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    if (points.length > 0) {
      const bounds = L.latLngBounds(points.map(point => [point.lat, point.lng]));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [points, map]);

  return null;
};

export default StoresComponent;
