import React, { useState, useEffect } from 'react';
import RestAPIService from '../../services/rest-api.service';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BikesComponent = ({ userGroup }) => {
  const { t } = useTranslation();
  const [serial, setSerial] = useState("");
  const [bikes, setBikes] = useState([]);
  const [newBike, setNewBike] = useState({
    brand: '',
    model: '',
    year: '',
    bike_serial: '',
    battery_serial: '',
    ftex_serial: ''
  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    listAllBikes();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBike({ ...newBike, [name]: value });
  };

  const handleCreateBike = (e) => {
    e.preventDefault();
    RestAPIService.createBike(newBike).then(() => {
      setMessage(t("Bike created successfully"));
      listAllBikes();
    }).catch(error => {
      console.error(t("There was an error creating the bike!"), error);
      setMessage(t("There was an error creating the bike!"));
    });
  };

  const searchBikeBySerial = () => {
    RestAPIService.getBike(serial).then(bike => {
      if (bike) {
        setBikes([bike]);
        setMessage("");
      } else {
        setBikes([]);
        setMessage(t("No bike found with that serial number!"));
      }
    }).catch(error => {
      console.error(t("There was an error searching for the bike!"), error);
      setMessage(t("No bike found with that serial number!"));
    });
  };

  const listAllBikes = () => {
    RestAPIService.listAllBikes(userGroup).then(bikes => {
      setBikes(bikes);
      setMessage("");
    }).catch(error => {
      console.error(t("There was an error listing all bikes!"), error);
      setMessage(t("There was an error listing all bikes!"));
    });
  };

  const viewBikeHistory = (bikeSerial) => {
    navigate(`/admin/bikes/${bikeSerial}`);
  };

  return (
    <div className="container-fluid bikes-wrapper text-center">
      <div className="row">
        <div className="col-md-6">
          <h3>{t("Create Bike")}</h3>
          <form onSubmit={handleCreateBike} className="form-container">
            <div className="form-group">
              <label htmlFor="brand">{t("Brand")}:</label>
              <input type="text" name="brand" value={newBike.brand} onChange={handleInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="model">{t("Model")}:</label>
              <input type="text" name="model" value={newBike.model} onChange={handleInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="year">{t("Year")}:</label>
              <input type="text" name="year" value={newBike.year} onChange={handleInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="bike_serial">{t("Bike Serial")}:</label>
              <input type="text" name="bike_serial" value={newBike.bike_serial} onChange={handleInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="battery_serial">{t("Battery Serial")}:</label>
              <input type="text" name="battery_serial" value={newBike.battery_serial} onChange={handleInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="ftex_serial">{t("Controller Serial")}:</label>
              <input type="text" name="ftex_serial" value={newBike.ftex_serial} onChange={handleInputChange} className="form-control" required />
            </div>
            <button type="submit" className="btn btn-success">{t("Create Bike")}</button>
          </form>
        </div>
        <div className="col-md-6">
          <h3>{t("Search Bike")}</h3>
          <div className="form-container">
            <div className="form-group">
              <input
                type="text"
                placeholder={t("Search by serial")}
                value={serial}
                onChange={(e) => setSerial(e.target.value)}
                className="form-control"
              />
              <button onClick={searchBikeBySerial} className="btn btn-primary">{t("Search Bike")}</button>
            </div>
            <div className="form-container">
              <button onClick={listAllBikes} className="btn btn-info">{t("List All Bikes")}</button>
            </div>
          </div>
        </div>
      </div>
      {message && (
        <div className="alert alert-info" role="alert">
          {message}
        </div>
      )}
      {bikes.length > 0 && (
        <div className="row">
          <div className="col-md-12">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>{t("Brand")}</th>
                  <th>{t("Model")}</th>
                  <th>{t("Year")}</th>
                  <th>{t("Bike Serial")}</th>
                  <th>{t("Battery Serial")}</th>
                  <th>{t("Controller Serial")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {bikes.map(bike => (
                  <tr key={bike.bike_serial}>
                    <td>{bike.brand}</td>
                    <td>{bike.model}</td>
                    <td>{bike.year}</td>
                    <td>{bike.bike_serial}</td>
                    <td>{bike.battery_serial}</td>
                    <td>{bike.ftex_serial}</td>
                    <td>
                      <button onClick={() => viewBikeHistory(bike.bike_serial)} className="btn btn-info">{t("View History")}</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default BikesComponent;
