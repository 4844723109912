import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useContent from "../../../hooks/useContent.hook";
import RestAPIService from '../../../services/rest-api.service';

const UserDetailComponent = ({ userGroup }) => {
  const { t } = useTranslation();
  const { email } = useParams();
  const navigate = useNavigate();
  const { fetchUserByEmail, updateUser } = useContent();
  const [user, setUser] = useState(null);
  const [stores, setStores] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await fetchUserByEmail(email, userGroup);
        console.log(t("Fetched user:"), userData);
        setUser(userData);
      } catch (error) {
        console.error(t("There was an error fetching the user!"), error);
        setMessage(t("There was an error fetching the user!"));
      }

      try {
        const storeData = await RestAPIService.fetchStoresByBrand(userGroup);
        console.log(t("Fetched stores:"), storeData);
        setStores(storeData);
      } catch (error) {
        console.error(t("There was an error fetching the stores!"), error);
        setMessage(t("There was an error fetching the stores!"));
      }
    };

    fetchData();
  }, [email, userGroup, t]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleUpdateUser = (e) => {
    e.preventDefault();
    const updatedUser = {
      username: user.username,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      language: user.language,
      store: user.store_id
    };
    updateUser(user.id, updatedUser).then(() => {
      setMessage(t("User updated successfully"));
    }).catch(error => {
      console.error(t("There was an error updating the user!"), error);
      setMessage(t("There was an error updating the user!"));
    });
  };

  if (!user || !stores.length) {
    return <div>{t("Loading...")}</div>;
  }

  return (
    <div className="container">
      <h3>{t("Edit User")}</h3>
      <form onSubmit={handleUpdateUser}>
        <div className="form-group">
          <label htmlFor="username">{t("Username")}:</label>
          <input type="text" name="username" value={user.username || ''} onChange={handleInputChange} className="form-control" readOnly />
        </div>
        <div className="form-group">
          <label htmlFor="first_name">{t("First Name")}:</label>
          <input type="text" name="first_name" value={user.first_name || ''} onChange={handleInputChange} className="form-control" required />
        </div>
        <div className="form-group">
          <label htmlFor="last_name">{t("Last Name")}:</label>
          <input type="text" name="last_name" value={user.last_name || ''} onChange={handleInputChange} className="form-control" required />
        </div>
        <div className="form-group">
          <label htmlFor="email">{t("Email")}:</label>
          <input type="email" name="email" value={user.email || ''} onChange={handleInputChange} className="form-control" readOnly />
        </div>
        <div className="form-group">
          <label htmlFor="language">{t("Language")}:</label>
          <select name="language" value={user.language || ''} onChange={handleInputChange} className="form-control" required>
            <option value="">{t("Select Language")}</option>
            <option value="en_US">{t("English")}</option>
            <option value="fr">{t("French")}</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="store">{t("Store")}:</label>
          <select name="store" value={user.store_id || ''} onChange={handleInputChange} className="form-control" required>
            <option value="">{t("Select Store")}</option>
            {stores.map(store => (
              <option key={store._id} value={store._id}>{store.name} - {store.address1}</option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn btn-success">{t("Update User")}</button>
        <button type="button" className="btn btn-secondary" onClick={() => navigate('/admin/users')}>{t("Cancel")}</button>
      </form>
      {message && (
        <div className="alert alert-info" role="alert">
          {message}
        </div>
      )}
    </div>
  );
};

export default UserDetailComponent;
