import React, { useEffect, useState } from 'react';
import RestAPIService from '../../services/rest-api.service';
import MapComponent from './../admin/map.component';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import useContent from "../../hooks/useContent.hook";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet.heat';
import L from 'leaflet';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const UsersComponent = ({ userGroup }) => {
  const { fetchUsersByBrand } = useContent();
  const { t } = useTranslation();
  const [userData, setUserData] = useState([]);
  const [userLocations, setUserLocations] = useState([]);
  const [trips, setTrips] = useState([]);
  const [bikeLocations, setBikeLocations] = useState([]);

  useEffect(() => {
    fetchUsersByBrand(userGroup)
      .then(data => {
        console.log("Fetched users:", data);
        setUserData(data);
        fetchUserLocations(data.filter(user => user.postalCode));
      })
      .catch(error => {
        console.error("Error fetching users:", error);
      });

    RestAPIService.fetchTrips()
      .then(data => {
        console.log("Fetched trips:", data);
        setTrips(data);
      })
      .catch(error => {
        console.error("Error fetching trips:", error);
      });

    RestAPIService.fetchBikeLocations(userGroup)
      .then(data => {
        console.log("Fetched bike locations:", data);
        setBikeLocations(data);
      })
      .catch(error => {
        console.error("Error fetching bike locations:", error);
      });
  }, [userGroup]);

  const fetchUserLocations = async (users) => {
    try {
      const locations = await Promise.all(
        users.map(async (user) => {
          try {
            const response = await RestAPIService.fetchLatLng(user.postalCode);
            if (response) {
              const { lat, lng } = response;
              return { ...user, lat, lng };
            }
            return null;
          } catch (error) {
            console.error(`Error fetching coordinates for postal code: ${user.postalCode}`, error);
            return null;
          }
        })
      );
      setUserLocations(locations.filter(location => location !== null));
    } catch (error) {
      console.error("Error fetching user locations:", error);
    }
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  };

  const processTotalUserData = () => {
    const counts = userData.reduce((acc, user) => {
      if (isValidDate(user.date_registered)) {
        const date = new Date(user.date_registered.replace(' ', 'T')).toISOString().split('T')[0];
        acc[date] = (acc[date] || 0) + 1;
      }
      return acc;
    }, {});

    const dates = Object.keys(counts).sort((a, b) => new Date(a) - new Date(b));
    const cumulativeCounts = dates.map((date, index) => {
      return dates.slice(0, index + 1).reduce((sum, currentDate) => sum + counts[currentDate], 0);
    });

    console.log("Processed total user data:", cumulativeCounts);

    return {
      labels: dates,
      datasets: [
        {
          label: t('Total Users Added Over Time'),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: 'rgba(153,102,255,0.4)',
          borderColor: 'rgba(153,102,255,1)',
        },
      ],
    };
  };

  const tripHeatmapData = trips.map(trip => [trip.geolocationStart[0], trip.geolocationStart[1], 1]);
  const bikeHeatmapData = bikeLocations
    .filter(bike => !(bike.geolocation[0] === 0 && bike.geolocation[1] === 0))
    .map(bike => [bike.geolocation[1], bike.geolocation[0], 1]);

  return (
    <div className="container-fluid fleet-module">
      <div className="row">
        <div className="col-md-6">
          <h2>{t('Users Analytics')}</h2>
          <div className="chart-section" style={{ marginTop: '2rem' }}>
            <h4>{t('Total Users Added Over Time')}</h4>
            {userData.length > 0 ? (
              <Line data={processTotalUserData()} />
            ) : (
              <p>{t('No user data available.')}</p>
            )}
          </div>
          <h4>{t('User Locations')}</h4>
          <MapComponent stores={userLocations} />
          <p>{t('Total Users')}: {userLocations.length}</p>
        </div>
        <div className="col-md-6">
          <h4>{t('TRIPS')}</h4>
          <MapContainer style={{ height: "600px", width: "100%" }} center={[0, 0]} zoom={2}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <HeatmapLayer points={tripHeatmapData} />
            <MapBounds points={tripHeatmapData} />
          </MapContainer>
          <hr></hr>
          <h4>{t('BIKE LOCATIONS')}</h4>
          <MapContainer style={{ height: "600px", width: "100%" }} center={[0, 0]} zoom={2}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <HeatmapLayer points={bikeHeatmapData} />
            <MapBounds points={bikeHeatmapData} />
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

const HeatmapLayer = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const heatLayer = L.heatLayer(points, {
      radius: 25,
      blur: 15,
      maxZoom: 17,
      minOpacity: 0.5,
      max: 1.0,
    }).addTo(map);

    return () => {
      map.removeLayer(heatLayer);
    };
  }, [map, points]);

  return null;
};

const MapBounds = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    if (points.length > 0) {
      const bounds = L.latLngBounds(points.map(point => [point[0], point[1]]));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [points, map]);

  return null;
};

export default UsersComponent;
