import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Alert, Form } from 'react-bootstrap';
import useContent from '../../hooks/useContent.hook';
import RestAPIService from '../../services/rest-api.service';
import { useTranslation } from 'react-i18next';

const ViewUsers = ({ users, stores, listUsersByBrand, userGroup }) => {
  const { t } = useTranslation();
  const { resetUserPassword, deleteUser, fetchUserByEmail } = useContent();
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [generalMessage, setGeneralMessage] = useState('');
  const [email, setEmail] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);
  const navigate = useNavigate();

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const handleShowUserModal = (userId) => {
    setSelectedUserId(userId);
    setDeleteMessage('');
    setShowUserModal(true);
  };

  const handleCloseUserModal = () => {
    setShowUserModal(false);
    setSelectedUserId(null);
    setDeleteMessage('');
  };

  const handleDeleteUser = () => {
    if (selectedUserId) {
      deleteUser(selectedUserId)
        .then((response) => {
          console.log('response delete user', response.status, response);
          if (response.status === 200) {
            setGeneralMessage(t("User deleted successfully"));
            listUsersByBrand();
            handleCloseUserModal();
          } else {
            setDeleteMessage(t("There was an error deleting the user. Please try again."));
          }
        })
        .catch(error => {
          console.error(t("There was an error deleting the user!"), error);
          setDeleteMessage(t("There was an error deleting the user. Please try again."));
        });
    }
  };

  const resetPassword = (userId) => {
    resetUserPassword(userId)
      .then(() => {
        setGeneralMessage(t("Password reset email sent successfully"));
      })
      .catch(error => {
        console.error(t("There was an error sending the password reset email!"), error);
        setGeneralMessage(t("There was an error sending the password reset email!"));
      });
  };

  const searchUserByEmail = (e) => {
    e.preventDefault();
    fetchUserByEmail(email, userGroup)
      .then(user => {
        if (user) {
          setFilteredUsers([user]);
          setGeneralMessage('');
        } else {
          setFilteredUsers([]);
          setGeneralMessage(t('No user found with that email.'));
        }
      })
      .catch(error => {
        console.error(t("There was an error searching for the user!"), error);
        setGeneralMessage(t("There was an error searching for the user!"));
      });
  };

  const clearSearch = () => {
    setFilteredUsers(users);
    setEmail('');
    setGeneralMessage('');
  };

  return (
    <div className="container-fluid users-module-users-wrapper">
      <div className="row">
        <div className="col-6">
          <h3>{t('Search Users')}</h3>
          <h4>{t('by Email')}:</h4>
        </div>
        <div className="col-6">
          <div className="search-form-container">
            <Form onSubmit={searchUserByEmail}>
              <Form.Group controlId="formSearchEmail">
                <Form.Control 
                  type="email" 
                  placeholder={t("Enter email")} 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  required 
                />
              </Form.Group>
              <Button className="btn btn-block btn-huge btn-primary" type="submit">
                {t('Search')}
              </Button>
            </Form>
          </div>
        </div>
      </div>
      {filteredUsers.length !== users.length && (
        <div className="row">
          <div className="col-12 text-right">
            <Button variant="secondary" onClick={clearSearch}>
              {t('Clear Search')}
            </Button>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12">
          {generalMessage && <Alert variant="info">{generalMessage}</Alert>}
          <table className="table table-no-borders">
            <thead>
              <tr>
                <th>{t('Username')}</th>
                <th>{t('First Name')}</th>
                <th>{t('Last Name')}</th>
                <th>{t('Email')}</th>
                <th>{t('Store')}</th>
                <th>{t('Language')}</th>
                <th>{t('Edit')}</th>
                <th>{t('Reset Password')}</th>
                <th>{t('Delete')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(user => (
                <tr key={user.id}>
                  <td>{user.username}</td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.store_id ? (
                      <div className="nowrap-td">
                        {stores.find(store => store._id === user.store_id)?.name}
                        <br />
                        {stores.find(store => store._id === user.store_id)?.address1}
                      </div>
                    ) : (
                      t('N/A')
                    )}
                  </td>
                  <td>{user.language === 'fr' ? t('French') : t('English')}</td>
                  <td>
                    <button onClick={() => navigate(`/users/all/${user.email}`)} className="btn btn-secondary-reverse btn-small ">{t('Edit')}</button>
                  </td>
                  <td>
                    <button onClick={() => resetPassword(user.id)} className="btn btn-secondary-reverse btn-small ">{t('Reset')}</button>
                  </td>
                  <td>
                    <button onClick={() => handleShowUserModal(user.id)} className="btn btn-small btn-danger">{t('Delete')}</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Confirmation Modal for Users */}
      <Modal show={showUserModal} onHide={handleCloseUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Confirm Deletion')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('Are you sure you want to delete this user?')}
          {deleteMessage && <Alert variant="danger" className="mt-2">{deleteMessage}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUserModal}>
            {t('Cancel')}
          </Button>
          <Button variant="danger" onClick={handleDeleteUser}>
            {t('Delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewUsers;
