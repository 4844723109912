import React, { useContext, useEffect, useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { useNavigate, Link } from 'react-router-dom';
import useContent from "../hooks/useContent.hook";
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import RestAPIService from "../services/rest-api.service";
import Debug from "../services/debug.js";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

function CustomToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className={`btn btn-small btn-secondary ${isCurrentEventKey ? "open" : ""}`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const HistoryComponent = ({
  isConnected,
  isPreflightChecked,
  bikeSerial,
  getBikeBrand,
  getBikeModel,
  setCurrentSolution,
}) => {
  const { t, i18n } = useTranslation();

  const [source, setSource] = useState("");
  const [problem, setProblem] = useState("");
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState("");
  const [batterySerial, setBatterySerial] = useState("");
  const [ftexSerial, setFtexSerial] = useState("");
  const [bikeBrand, setBikeBrand] = useState("");
  const [bikeModel, setBikeModel] = useState("");
  const [bikeYear, setBikeYear] = useState("");
  const [bikeRecords, setBikeRecords] = useState("");

  const navigate = useNavigate();  

  useEffect(() => {
    if (!isConnected && !isPreflightChecked) {
      navigate('/bleConnect');
    }

    if (isConnected && !isPreflightChecked) {
      navigate('/bluetoothPreflight');
    }

  }, [isConnected, isPreflightChecked, navigate, getBikeModel]);

  useEffect(() => {
    const fetchBikeInfo = async () => {
      try {
        let bikeInfo = await RestAPIService.getBike(bikeSerial);
        Debug.logger(1, 'bike info', bikeInfo);
        setBatterySerial(bikeInfo.battery_serial);
        setFtexSerial(bikeInfo.ftex_serial);
        setBikeBrand(bikeInfo.brand);
        setBikeModel(bikeInfo.model);
        setBikeYear(bikeInfo.year);
      } catch (error) {
        Debug.logger(1, 'Error fetching bike info:', error);
        // Handle the error, maybe redirect the user or show an error message
      }
    };

    fetchBikeInfo();
  }, [bikeSerial]);

  useEffect(() => {
  }, [bikeRecords]);

  const fetchBikeRecords = async () => {
    try {
      let results = await RestAPIService.getBikeRecords(bikeSerial);
      Debug.logger(1, 'bike getBikeRecords', results);
      setBikeRecords(results);
    } catch (error) {
      console.error('Error fetching bike info:', error);
      // Handle the error, maybe redirect the user or show an error message
    }
  };

  useEffect(() => {
    fetchBikeRecords();
  }, [bikeSerial]); // You only need bikeSerial here, not other states

  const onChangeSource = (e) => {
    setSource(e.target.value);
  };

    const onChangeProblem = (e) => {
    setProblem(e.target.value);
  };

    const onChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();

    setMessage("");

    let recordObject = {
      type: "Manual",
      source: source,
      problem: problem,
      comment: comment
    }

    RestAPIService.addBikeRecord(bikeSerial, recordObject).then(
      () => {
        Debug.logger(1, 'success adding new record', recordObject)
        setSource("");
        setProblem("");
        setComment("");
        fetchBikeRecords();
      },
      (error) => {
        console.error('error adding new record', error)
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  };

  return (
    <div className="container-fluid history-wrapper">
      <div className="row justify-content-center">
        {bikeBrand && bikeModel && bikeYear && (
          <div className="col-md-11">
            <header>
              <h2>
                {bikeBrand} {bikeModel} ({bikeYear})- &nbsp;
              </h2>
              <h3>
                 {bikeSerial}
              </h3>
              <span>{t("Battery")}: <span>{batterySerial}</span></span>
              <span>{t("Controller")}: <span>{ftexSerial}</span></span>
              <hr/>
            </header>
          </div>
        )}
        <div className="col-md-11">
          <h4>{t("Add a Comment")}</h4>
          <hr/>
          <Form onSubmit={handleCommentSubmit}>
            <div className="form-group">
              <label htmlFor="source">{t('Retailer')}</label>
              <Input
                type="text"
                className="form-control"
                name="source"
                placeholder={t('Name of Retailer')}
                value={source}
                onChange={onChangeSource}
                validations={[required]}
              />
            </div>
            <div className="form-group">
              <label htmlFor="problem">{t('Description of Problem')}</label>
              <Input
                type="text"
                className="form-control"
                name="problem"
                placeholder={t('Ex. Error code 01')}
                value={problem}
                onChange={onChangeProblem}
                validations={[required]}
              />
            </div>
            <div className="form-group">
              <label htmlFor="comment">{t('Description of Solution')}</label>
              <textarea
                className="form-control"
                name="comment"
                placeholder={t('Ex. How the problem was solved..')}
                value={comment}
                onChange={onChangeComment}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-big"
              >
                <span>{t('Submit')}</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </div>
        <div className="col-md-11">
          <h4>{t("Bike Comments and History")}</h4>
          <hr/>
          {bikeRecords && bikeRecords.length && bikeRecords.map((record, index) => (
            <div
              key={index}
              className={`records ${record.type}`}
            >
              <span>{t("Date")}:<span>{record.date}</span></span>
              <span>{t("Retailer/Source")}:<span>{record.source}</span></span>
              <span>{t("Problem/Error")}:<span>{record.problem}</span></span>
              <span>{t("Comment")}:<span>{record.comment}</span></span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HistoryComponent;