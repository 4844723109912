import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import useContent from "../../hooks/useContent.hook";
import RestAPIService from '../../services/rest-api.service';
import SideMenu from './SideMenu';
import ViewUsers from './ViewUsers';
import ViewStores from './ViewStores';
import StoreDetail from './storeDetail.component';
import UserDetail from './userDetail.component';
import HomeComponent from './home.component';
import { useTranslation } from 'react-i18next';

const UsersComponent = ({ userGroup }) => {
  const { fetchUsersByBrand } = useContent();
  const { t } = useTranslation();
  const [stores, setStores] = useState([]);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    listUsersByBrand();
    fetchStoresByBrand();
  }, [userGroup]);

  const fetchStoresByBrand = () => {
    RestAPIService.fetchStoresByBrand(userGroup).then(stores => {
      setStores(stores);
    }).catch(error => {
      console.error(t("There was an error fetching the stores!"), error);
      setMessage(t("There was an error fetching the stores!"));
    });
  };

  const listUsersByBrand = () => {
    fetchUsersByBrand(userGroup).then(users => {
      setUsers(users);
      setMessage("");
    }).catch(error => {
      console.error(t("There was an error listing users by brand!"), error);
      setMessage(t("There was an error listing users by brand!"));
    });
  };

  return (
    <div className="container-fluid container-users-module">
      <div className="row">
        <div className="col-2">
          <SideMenu userGroup={userGroup} listUsersByBrand={listUsersByBrand} fetchStoresByBrand={fetchStoresByBrand} />
        </div>
        <div className="col-10 main-content">
          <Routes>
            <Route path="/" element={<HomeComponent stores={stores} users={users} userGroup={userGroup}/>} />
            <Route path="all" element={<ViewUsers users={users} stores={stores} listUsersByBrand={listUsersByBrand} userGroup={userGroup} />} />
            <Route path="stores" element={<ViewStores stores={stores} fetchStoresByBrand={fetchStoresByBrand} />} />
            <Route path="stores/:id" element={<StoreDetail userGroup={userGroup} stores={stores}/>} />
            <Route path="all/:email" element={<UserDetail userGroup={userGroup}/>} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default UsersComponent;
