import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import Select from 'react-select';
import useContent from '../../hooks/useContent.hook';
import RestAPIService from '../../services/rest-api.service';
import { useTranslation } from 'react-i18next';

const AddUserModal = ({ show, onHide, userGroup, listUsersByBrand }) => {
  const { t } = useTranslation();
  const initialFormData = {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    language: 'en_US',
    brand: userGroup,
    store: ''
  };

  const { createUser } = useContent();
  const [formData, setFormData] = useState(initialFormData);
  const [stores, setStores] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (show) {
      fetchStoresByBrand();
      setFormData(initialFormData);
      setMessage('');
    }
  }, [show]);

  const fetchStoresByBrand = () => {
    RestAPIService.fetchStoresByBrand(userGroup).then(stores => {
      setStores(stores);
    }).catch(error => {
      console.error(t('There was an error fetching the stores!'), error);
      setMessage(t('There was an error fetching the stores!'));
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStoreChange = (selectedOption) => {
    setFormData({ ...formData, store: selectedOption ? selectedOption.value : '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.store === '') {
      setMessage(t('Please select a store.'));
      return;
    }
    createUser(formData).then((response) => {
      if (response.user_id && response.user_id > 0) {
        onHide();
        listUsersByBrand();
        setFormData(initialFormData);
        setMessage('');
      } else {
        setMessage(t('There was an error adding the user. Please try again.'));
      }
    }).catch((error) => {
      console.error(t('There was an error adding the user!'), error);
      setMessage(t('There was an error adding the user. Please try again.'));
    });
  };

  const storeOptions = stores.map(store => ({
    value: store._id,
    label: `${store.name} - ${store.address1}`
  }));

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div className="container-fluid">
          <div className="row">
            <div className="col-7">
              <Modal.Title>{t('Add New User')}<span>{t('We need some details')}</span></Modal.Title>
            </div>
            <div className="col-5">
              {formData.userImg ? (
                <img src={formData.userImg} alt={t('User Profile')} className="profile-img" />
              ) : (
                <div className="profile-img"></div>
              )}
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {message && <Alert variant="danger">{message}</Alert>}
        <Form onSubmit={handleSubmit}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <Form.Group controlId="formUsername">
                  <Form.Label>{t('Username')}</Form.Label>
                  <Form.Control type="text" name="username" value={formData.username} onChange={handleChange} required />
                </Form.Group>
                <Form.Group controlId="formFirstName">
                  <Form.Label>{t('First Name')}</Form.Label>
                  <Form.Control type="text" name="first_name" value={formData.first_name} onChange={handleChange} required />
                </Form.Group>
                <Form.Group controlId="formLastName">
                  <Form.Label>{t('Last Name')}</Form.Label>
                  <Form.Control type="text" name="last_name" value={formData.last_name} onChange={handleChange} required />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group controlId="formEmail">
                  <Form.Label>{t('Email')}</Form.Label>
                  <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
                </Form.Group>
                <Form.Group controlId="formLanguage">
                  <Form.Label>{t('Language')}</Form.Label>
                  <Form.Control as="select" name="language" value={formData.language} onChange={handleChange}>
                    <option value="en_US">{t('English')}</option>
                    <option value="fr">{t('French')}</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formStore">
                  <Form.Label>{t('Store')}</Form.Label>
                  <Select
                    options={storeOptions}
                    value={storeOptions.find(option => option.value === formData.store)}
                    onChange={handleStoreChange}
                    isClearable
                  />
                </Form.Group>
              </div>
              <div className="col-9">
                <Button className="btn btn-block btn-big" type="submit">
                  {t('Add User')}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserModal;
