import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import useCANStream from '../../../hooks/useCANStream.hook'; // Ensure the correct path
import { useTranslation } from 'react-i18next';

const DiagnosticsIntro = ({
  onResult
}) => {

  const next = () => {

  }

  return (
    <div>
      <h1 className="mb-4">Run Diagnotics Bike Test</h1>
      <h2 className="mt-4">The run diagnostic test is about to begin.</h2>
      <h4 className="mb-4">
        For each step, you will receive instructions to follow on the bike. A 20-second timer will start automatically at each step. If you do not complete the required action, the test will be marked as failed.
      </h4>
      <div className="test-summary warning">
        <p>
          <img src="/imgs/icons/icon-warning.svg" alt="Warning" />
          Please be careful because the motor will begin spinning. Please lift the rear wheel from the floor.
        </p>
      </div>
      <Button className="btn btn-large btn-primary mr-4" onClick={onResult}>Start Diagnostics</Button>
    </div>
  );
};

export default DiagnosticsIntro;

