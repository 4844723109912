import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import AuthService from "../../services/auth.service";
import AnalyticsService from "../../services/analytics.service";
import { useTranslation } from 'react-i18next';
import Debug from "../../services/debug.js";
import useBluetooth from '../../hooks/useBluetooth.hook';
import RestAPIService from "../../services/rest-api.service";
import Logo from '../logo.component';

const HomeComponent = ({ isConnected, userGroup, currentUser, setCurrentUser, connectToDevice, setLoadingScreen, bikeSerial, disconnectFromDevice }) => {
  const { t, i18n } = useTranslation();
  const [userReady, setUserReady] = useState(false);
  const [bleConnectionError, setBleConnectionError] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showNotRegisteredModal, setShowNotRegisteredModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { getErrorCodeByBLE } = useBluetooth();
  const navigate = useNavigate();

  const fetchBikeErrors = async () => {
    Debug.logger(2,'fetchBikeErrors');
    try {
      let bikeErrors = await getErrorCodeByBLE();
      Debug.logger(2,'bikeErrors', bikeErrors);
      navigate("/diagnostics/bike");
    } catch (error) {
      Debug.logger(2, 'Error fetching bike errors from Bluetooth', error);
      if (isConnected) {
        console.log('home isConnected', isConnected);
        navigate("/diagnostics/bike");
      }
    }
  };

  const handleFindBikes = async () => {
    setLoadingScreen(true);
    setBleConnectionError(false); // Reset error state before attempting to connect
    await connectToDevice(setLoadingScreen, setBleConnectionError, 1000);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (bikeSerial) {
        const authedUser = AuthService.getCurrentUser();
        Debug.logger(2,'serial analytics', bikeSerial, authedUser, userGroup);
        const params = {
          user: authedUser,
          bikeSerial: bikeSerial,
        }
        AnalyticsService.track('bike-connection', userGroup, params);
        Debug.logger(2,'bikeserial in home', bikeSerial);
        await fetchBikeErrors();

        try {
          const response = await RestAPIService.getBike(bikeSerial);
          if (response && response.response && response.response.status === 200) {
            fetchBikeErrors();
          } else {
            setErrorMessage(t('This bike has not been registered.'));
            setShowNotRegisteredModal(true);
          }
        } catch (error) {
          setErrorMessage(t('This bike has not been registered.'));
          setShowNotRegisteredModal(true);
        }
      }
    };

    fetchData();
  }, [bikeSerial]);


  useEffect(() => {
    if (isConnected) {
      Debug.logger(2, 'BLE connected to bike');
      setLoadingScreen(false);
    } else if (bleConnectionError) {
      Debug.logger(2, 'BLE connection failed');
      setLoadingScreen(false);
      setErrorMessage(t('Connection failed, please try again.'));
      setShowErrorModal(true); // Show the error modal
    }
  }, [isConnected, bleConnectionError]);

  useEffect(() => {
    Debug.logger(1, 'userGroup', userGroup)
  }, [userGroup]);

  const handleCloseErrorModal = () => setShowErrorModal(false);

  const handleCloseNotRegisteredModal = () => {
    setShowNotRegisteredModal(false);
    disconnectFromDevice();
    navigate("/diagnostics");
  };

  return (
    <div className="container-fluid text-center d-flex align-items-center justify-content-center h-100">
      <div className="col-md-12 connection">
        <Logo userGroup={userGroup} />
        <p>
          {t('For a successful connection, please ')}
          <span>
            {t('turn on Bluetooth on both devices.')}
          </span>
        </p>
        <button id="find-bikes" className="btn btn-primary btn-large" onClick={handleFindBikes}>
          {t('Connect to a Bike')}
        </button>
      </div>

      <Modal show={showErrorModal} onHide={handleCloseErrorModal} className="centered-modal modal-small">
        <Modal.Header closeButton>
          <img className="icon-large icon-orange" src="/imgs/icons/icon-bike.svg" alt="Download" />
        </Modal.Header>
        <Modal.Body>
          <h3>{t("Connection failed")}</h3>
          <p>{errorMessage}</p>
          <button className="btn btn-block btn-primary" onClick={handleCloseErrorModal}>
            {t("Close")}
          </button>
        </Modal.Body>
      </Modal>

      <Modal show={showNotRegisteredModal} onHide={handleCloseNotRegisteredModal} className="centered-modal modal-small">
        <Modal.Header closeButton>
          <img className="icon-large icon-orange" src="/imgs/icons/icon-bike.svg" alt="Download" />
        </Modal.Header>
        <Modal.Body>
          <h3>{t("Bike Not Registered")}</h3>
          <p>{errorMessage}</p>
          <button className="btn btn-block btn-primary" onClick={handleCloseNotRegisteredModal}>
            {t("OK")}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HomeComponent;
