// src/injectLessStyles.js
import less from 'less';
import lessVariablesConfig from './lessVariables.config';

const injectLessStyles = (userGroup) => {
  const variables = lessVariablesConfig[userGroup.toLowerCase()] || lessVariablesConfig['ftex'];
  const lessString = `
    @primary: ${variables.primary};
    @secondary: ${variables.secondary};

    .btn {
      &.btn-primary {
        background-color: @primary;
        border-color: @primary;
        &:hover {
          border-color: @primary;
          color: @primary;
        }
      }
      &.btn-primary-reverse {
        color: @primary;
        border-color: @primary;
        &:hover {
          background-color: @primary !important;
          border-color: @primary;
        }
      }
      &.btn-secondary {
        background-color: @secondary;
        border-color: @secondary;
        &:hover {
          color: @secondary;
          border-color: @secondary;
        }
      }
      &.btn-secondary-reverse {
        color: @secondary;
        border-color: @secondary;
        &:hover {
          background-color: @secondary !important;
          border-color: @secondary;
        }
      }
      &.btn-filter {
        color: @primary;
        border-color: @primary;
      }
    }

    nav {
      &.top-nav {
        ul {
          li {
            a {
              svg {
                color: @primary;
              }
            }

            &.lang-menu {
              #dropdown-language {
                &::after {
                  border-top: .3em solid @primary;
                }
              }
            }
          }
        }
      }
    }

    .ftex-side-menu {
      ul {
        li {
          &.active {
            a {
              color: @primary;
            }
          }
        }
      }
    }

    .text-secondary-color {
      color: @primary;
    }

    .progress {
      .progress-bar {
        background-color: @primary;
      } 
    }

    .stat-icon {
      .img-container {
        border: 1px solid @secondary;
      }
    }

    .users-module-users-wrapper {
      h3 {
        color: @secondary;
      }
      h4 {
        color: @secondary;
      }
      .nav-tabs {
        li {
          &:hover {
            button {
              color: @primary;
            }
          }
        }
      }
    }

    nav {
      &.top-nav {
        ul {
          li {
            a {
              svg {
                color: @primary;
              }
            }
          }
          &.lang-menu {
            #dropdown-language {
              &::after {
                border-top: .3em solid @primary;
              }
            }
          }
        }
      }
    }

    .resources-wrapper {
      .additional-resources {
        li {
          a {
            span {
              color: @secondary;
            }
            p {
              color: @secondary;
            }
          }
        }
      } 
    }

    .modal-content {
      .modal-header {
        .modal-title {
          p {
            color: @primary;
          }
        }
      }
    }

    .login-wrapper { 
      .login-column {
        .card-container.card {
          .forgot-password {
            color: @primary;
          }
        }
      }
    }

    .help-component {
      button {
        color: @primary;
      }
    }

    .help-page-component {
      h1 {
        span {
          color: @primary;
        }
      }
    }

    .fleet-module {
      h3 {
        color: @secondary;
      }
      h4 {
        color: @secondary;
      }
    }


    .pin-entry {
      margin-top: -100px;
      .form-group {
        .react-code-input {
          display: flex;
            justify-content: space-between;
            align-items: center;
        }
        padding: 0;
        label {
          font-size: 12px;
        }
      }
    }

    .diagnostics-test-modal {
      .modal-content {
        .modal-body {
          >.container-fluid {
            >.row {
              .side-col {
                .test-list-container {
                  .test-list {
                    .test-item {
                      &.active {
                        .test-index {
                          background-color: @primary;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .container-diagnostics-module {
      h3 {
        color: @secondary;
      }
      h4 {
        color: @secondary;
      }
      .additional-resources {
        li {
          a {
            span {
              color: @secondary;
            }
            p {
              color: @secondary;
            }
          }
        }
      }
      .code-guides {
        .error-code-card {
          h4 {
            color: @secondary;
            span {
              color: @secondary;
            }
          }
        }
      }
      .solution-numbering {
        .line {
          &.active {
            background-color: @primary;
          }
        }
        .number {
          &.active {
            span {
                background-color: @primary;
            }
          }
        }
      }
      .nav-tabs {
        li {
          button {
            &.active {
              color: @primary;        
            }
          }
          &:hover {
            button {
              color: @primary;
            }
          }
        }
      }
    }

    .bike-detail {
      h3 {
        color: @secondary;
      }
      h4 {
        color: @secondary;
      }
      .bike-detail-summary {
        .info-box-container {
          span {
            color: @secondary;
          }
        }
      }
    }


  `;

  less.render(lessString, (error, output) => {
    if (error) {
      console.error('Error rendering LESS:', error.message);
      console.error('Error details:', error);
    } else {
      const style = document.createElement('style');
      style.innerHTML = output.css;
      document.head.appendChild(style);
    }
  });
};

export default injectLessStyles;
