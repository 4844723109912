import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SideMenu = ({ userGroup, isConnected, disconnectFromDevice }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleBackClick = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    if (pathParts.length > 1) {
      const parentPath = '/' + pathParts.slice(0, -1).join('/');
      navigate(parentPath);
    } else {
      navigate('/'); // Or navigate to a default route
    }
  };

  const handleDisconnectClick = () => {
    disconnectFromDevice();
  };

  useEffect(() => {
    if (!isConnected) {
      navigate('/diagnostics');
    }
  }, [isConnected]);

  return (
    <div className="ftex-side-menu">
      {isConnected && (
        <ul>
          <li>{t('Menu')}</li>
          <li className={location.pathname === '/diagnostics/bike' ? 'active' : ''}>
            <Link className="bike" to="/diagnostics/bike">{t('Bike')}</Link>
          </li>
          <li className={location.pathname === '/diagnostics/maintenance' ? 'active' : ''}>
            <Link className="gear" to="/diagnostics/maintenance">{t('Maintenance')}</Link>
          </li>
          <li className={location.pathname === '/diagnostics/battery' ? 'active' : ''}>
            <Link className="battery" to="/diagnostics/battery">{t('Battery')}</Link>
          </li>
          <li className={location.pathname === '/diagnostics/run' ? 'active' : ''}>
            <Link className="engine" to="/diagnostics/run">{t('Run Diagnostics')}</Link>
          </li>
        </ul>
      )}
      <ul>
        <li>{t('Other')}</li>
        <li className={location.pathname === '/diagnostics/guides' ? 'active' : ''}>
          <Link className="overview" to="/diagnostics/guides">{t('Error Guides')}</Link>
        </li>
        <li className={location.pathname === '/diagnostics/resources' ? 'active' : ''}>
          <Link className="bike" to="/diagnostics/resources">{t('Additional Resources')}</Link>
        </li>
        <li className={location.pathname === '/diagnostics/remote' ? 'active' : ''}>
          <Link className="remote" to="/diagnostics/remote">{t('Remote Connection')}</Link>
        </li>
      </ul>
      {isConnected && (
        <button className="btn btn-block btn-big btn-secondary" onClick={handleDisconnectClick}>
          {t('Disconnect')}
        </button>
      )}
      <button className="btn btn-block btn-big" onClick={handleBackClick}>
        {t('Back')}
      </button>
    </div>
  );
};

export default SideMenu;
