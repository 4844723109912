import React, { useState, useEffect } from 'react';
import useContent from "../../hooks/useContent.hook";
import RestAPIService from '../../services/rest-api.service';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

const UsersComponent = ({ userGroup }) => {
  const { fetchUsersByBrand, fetchUserByEmail, createUser, resetUserPassword, deleteUser } = useContent();
  const [email, setEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeQuery, setStoreQuery] = useState("");
  const [storeResults, setStoreResults] = useState([]);
  const [newUser, setNewUser] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    language: 'en_US',
    brand: userGroup,
    store: ''
  });
  const [newStore, setNewStore] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    phone: '',
    brand: userGroup,
  });
  const [message, setMessage] = useState("");
  const [showTable, setShowTable] = useState("");
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showStoreModal, setShowStoreModal] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchStoresByBrand();
  }, [userGroup]);

  const fetchStoresByBrand = () => {
    RestAPIService.fetchStoresByBrand(userGroup).then(stores => {
      setStores(stores);
    }).catch(error => {
      console.error("There was an error fetching the stores!", error);
      setMessage("There was an error fetching the stores!");
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleStoreInputChange = (e) => {
    const { name, value } = e.target;
    setNewStore({ ...newStore, [name]: value });
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    createUser(newUser).then(() => {
      setMessage("User created successfully");
      listUsersByBrand();
      setNewUser({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        language: 'en_US',
        brand: userGroup,
        store: ''
      });
    }).catch(error => {
      console.error("There was an error creating the user!", error);
      setMessage("There was an error creating the user!");
    });
  };

  const handleCreateStore = (e) => {
    e.preventDefault();
    RestAPIService.createStore(newStore).then(() => {
      setMessage("Store created successfully");
      fetchStoresByBrand(); // Refresh the store list after creating a new store
      setNewStore({
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        phone: '',
        brand: userGroup,
      });
      listStoresByBrand(); // Refresh the stores list in the table
    }).catch(error => {
      console.error("There was an error creating the store!", error);
      setMessage("There was an error creating the store!");
    });
  };

  const searchUserByEmail = () => {
    fetchUserByEmail(email, userGroup).then(user => {
      if (user) {
        setUsers([user]);
        setMessage("");
      } else {
        setUsers([]);
        setMessage("No user found with that email!");
      }
      setShowTable("users"); // Show users table
      setStoreResults([]); // Clear previous store results
    }).catch(error => {
      console.error("There was an error searching for the user!", error);
      setMessage("No user found with that email!");
    });
  };

  const searchStore = () => {
    RestAPIService.searchStore(storeQuery).then(stores => {
      setStoreResults(stores);
      setMessage("");
      setShowTable("stores"); // Show stores table
      setUsers([]); // Clear previous user results
    }).catch(error => {
      console.error("There was an error searching for the store!", error);
      setMessage("There was an error searching for the store!");
    });
  };

  const handleDeleteUser = () => {
    if (selectedUserId) {
      deleteUser(selectedUserId).then(() => {
        setMessage("User deleted successfully");
        listUsersByBrand();
        setShowUserModal(false);
        setSelectedUserId(null);
      }).catch(error => {
        console.error("There was an error deleting the user!", error);
        setMessage("There was an error deleting the user!");
        setShowUserModal(false);
        setSelectedUserId(null);
      });
    }
  };

  const handleDeleteStore = () => {
    if (selectedStoreId) {
      RestAPIService.deleteStore(selectedStoreId).then(() => {
        setMessage("Store deleted successfully");
        fetchStoresByBrand();
        listStoresByBrand();
        setShowStoreModal(false);
        setSelectedStoreId(null);
      }).catch(error => {
        console.error("There was an error deleting the store!", error);
        setMessage("There was an error deleting the store!");
        setShowStoreModal(false);
        setSelectedStoreId(null);
      });
    }
  };

  const resetPassword = (userId) => {
    resetUserPassword(userId).then(() => {
      setMessage("Password reset email sent successfully");
    }).catch(error => {
      console.error("There was an error sending the password reset email!", error);
      setMessage("There was an error sending the password reset email!");
    });
  };

  const listUsersByBrand = () => {
    fetchUsersByBrand(userGroup).then(users => {
      setUsers(users);
      setMessage("");
      setShowTable("users"); // Show users table
      setStoreResults([]); // Clear previous store results
    }).catch(error => {
      console.error("There was an error listing users by brand!", error);
      setMessage("There was an error listing users by brand!");
    });
  };

  const listStoresByBrand = () => {
    RestAPIService.fetchStoresByBrand(userGroup).then(stores => {
      setStoreResults(stores);
      setMessage("");
      setShowTable("stores"); // Show stores table
      setUsers([]); // Clear previous user results
    }).catch(error => {
      console.error("There was an error listing stores by brand!", error);
      setMessage("There was an error listing stores by brand!");
    });
  };

  const handleShowUserModal = (userId) => {
    setSelectedUserId(userId);
    setShowUserModal(true);
  };

  const handleCloseUserModal = () => {
    setShowUserModal(false);
    setSelectedUserId(null);
  };

  const handleShowStoreModal = (storeId) => {
    setSelectedStoreId(storeId);
    setShowStoreModal(true);
  };

  const handleCloseStoreModal = () => {
    setShowStoreModal(false);
    setSelectedStoreId(null);
  };

  return (
    <div className="container-fluid users-module-users-wrapper">
      <div className="row">
        <div className="col-md-6">
          <h3>Create Store/Retailer</h3>
          <form onSubmit={handleCreateStore} className="form-container">
            <div className="form-group">
              <label htmlFor="storeName">Store Name:</label>
              <input type="text" name="name" value={newStore.name} onChange={handleStoreInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="address1">Address Line 1:</label>
              <input type="text" name="address1" value={newStore.address1} onChange={handleStoreInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="address2">Address Line 2:</label>
              <input type="text" name="address2" value={newStore.address2} onChange={handleStoreInputChange} className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="city">City:</label>
              <input type="text" name="city" value={newStore.city} onChange={handleStoreInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="state">Province/State:</label>
              <input type="text" name="state" value={newStore.state} onChange={handleStoreInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="country">Country:</label>
              <select name="country" value={newStore.country} onChange={handleStoreInputChange} className="form-control" required>
                <option value="">Select Country</option>
                <option value="Canada">Canada</option>
                <option value="United States">United States</option>
                {/* Add other countries as needed */}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="postalCode">Postal Code:</label>
              <input type="text" name="postalCode" value={newStore.postalCode} onChange={handleStoreInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="storePhone">Store Phone Number:</label>
              <input type="text" name="phone" value={newStore.phone} onChange={handleStoreInputChange} className="form-control" required />
            </div>
            <button type="submit" className="btn btn-success">Create Store</button>
          </form>
          <h3>Search Store</h3>
          <div className="form-container">
            <div className="form-group">
              <input
                type="text"
                placeholder="Search by name, address, or phone"
                value={storeQuery}
                onChange={(e) => setStoreQuery(e.target.value)}
                className="form-control"
              />
              <button onClick={searchStore} className="btn btn-primary">Search Store</button>
            </div>
          </div>
          <div className="form-container">
            <button onClick={listStoresByBrand} className="btn btn-info">List All Stores</button>
          </div>
        </div>
        <div className="col-md-6">
          <h3>Create User</h3>
          <form onSubmit={handleCreateUser} className="form-container">
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input type="text" name="username" value={newUser.username} onChange={handleInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="first_name">First Name:</label>
              <input type="text" name="first_name" value={newUser.first_name} onChange={handleInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="last_name">Last Name:</label>
              <input type="text" name="last_name" value={newUser.last_name} onChange={handleInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" name="email" value={newUser.email} onChange={handleInputChange} className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="language">Language:</label>
              <select name="language" value={newUser.language} onChange={handleInputChange} className="form-control">
                <option value="en_US">English</option>
                <option value="fr">French</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="store">Store:</label>
              <select name="store" value={newUser.store} onChange={handleInputChange} className="form-control" required>
                <option value="">Select Store</option>
                {stores.map(store => (
                  <option key={store._id} value={store._id}>{store.name} - {store.address1}</option>
                ))}
              </select>
            </div>
            <button type="submit" className="btn btn-success">Create User</button>
          </form>
          <h3>Search User</h3>
          <div className="form-container">
            <div className="form-group">
              <input
                type="text"
                placeholder="Search by email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
              />
              <button onClick={searchUserByEmail} className="btn btn-primary">Search User</button>
            </div>
          </div>
          <div className="form-container">
            <button onClick={listUsersByBrand} className="btn btn-info">List All Users</button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-11">
          {message && (
            <div className="alert alert-info" role="alert">
              {message}
            </div>
          )}
          {showTable === "users" && users.length > 0 && (
            <div className="row">
              <div className="col-md-12">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Username</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Store</th>
                      <th>Edit</th>
                      <th>Delete</th>
                      <th>Reset Password</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(user => (
                      <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>{user.username}</td>
                        <td>{user.first_name}</td>
                        <td>{user.last_name}</td>
                        <td>{user.email}</td>
                        <td>{user.store_id ? `${stores.find(store => store._id === user.store_id)?.name} - ${stores.find(store => store._id === user.store_id)?.address1}` : 'N/A'}</td>
                        <td>
                          <button onClick={() => navigate(`/admin/user/${user.email}`)} className="btn btn-primary">Edit</button>
                        </td>
                        <td>
                          <button onClick={() => handleShowUserModal(user.id)} className="btn btn-danger">Delete</button>
                        </td>
                        <td>
                          <button onClick={() => resetPassword(user.id)} className="btn btn-warning">Reset Password</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {showTable === "stores" && storeResults.length > 0 && (
            <div className="row">
              <div className="col-md-12">
                <h3>Store Results</h3>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Phone</th>
                      <th>Delete Store</th>
                      <th>View Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storeResults.map(store => (
                      <tr key={store._id}>
                        <td>{store._id}</td>
                        <td>{store.name}</td>
                        <td>{`${store.address1}, ${store.address2}, ${store.city}, ${store.state}, ${store.country}, ${store.postalCode}`}</td>
                        <td>{store.phone}</td>
                        <td>
                          <button onClick={() => handleShowStoreModal(store._id)} className="btn btn-danger">Delete</button>
                        </td>
                        <td>
                          <button onClick={() => navigate(`/admin/stores/${store._id}`)} className="btn btn-info">View Details</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Confirmation Modal for Users */}
      <Modal show={showUserModal} onHide={handleCloseUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUserModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal for Stores */}
      <Modal show={showStoreModal} onHide={handleCloseStoreModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this store?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseStoreModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteStore}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UsersComponent;
