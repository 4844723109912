import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AddCommentModal = ({ show, handleClose, bikeId, fetchBikeRecords, RestAPIService }) => {
  const { t } = useTranslation();
  const [source, setSource] = useState("");
  const [problem, setProblem] = useState("");
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState("");

  const handleCommentSubmit = (e) => {
    e.preventDefault();

    setMessage("");

    let recordObject = {
      type: "Manual",
      source: source,
      problem: problem,
      comment: comment
    };

    RestAPIService.addBikeRecord(bikeId, recordObject).then(
      () => {
        setSource("");
        setProblem("");
        setComment("");
        fetchBikeRecords();
        handleClose();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Add a Comment")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleCommentSubmit}>
          <Form.Group controlId="source">
            <Form.Label>{t('Retailer')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('Name of Retailer')}
              value={source}
              onChange={(e) => setSource(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="problem">
            <Form.Label>{t('Description of Problem')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('Ex. Error code 01')}
              value={problem}
              onChange={(e) => setProblem(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="comment">
            <Form.Label>{t('Description of Solution')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={t('Ex. How the problem was solved..')}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
            />
          </Form.Group>
          <Button type="submit" className="btn btn-primary btn-big">
            {t('Submit')}
          </Button>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCommentModal;
