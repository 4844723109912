import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HelpComponent = ({ userGroup }) => {
  const [show, setShow] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(userGroup);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setCurrentGroup(userGroup);
    console.log('HELP HELP', userGroup, currentGroup)
  }, [userGroup]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleNavigate = (brandGroup) => {
    navigate('/help', { state: { brandGroup } });
    setShow(false);
  };

  // Hide the button and modal if the current path is /help
  if (location.pathname === '/help') {
    return null;
  }

  return (
    <div className="help-component">
      <button className="btn btn-block btn-large" onClick={handleShow}>
        {t('Need Help?')} <img src="/imgs/sidebar/icon-help.svg" alt={t('Get Help')} />
      </button>

      <Modal show={show} onHide={handleClose} className="help-modal" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <div className="container-fluid">
            <div className="row">
              <div className="col-7">
                <Modal.Title>{t('How can we')}<br /><p>{t('help you?')}</p><span>{t('We need some details')}</span></Modal.Title>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <div className="card card-container module-card" onClick={() => handleNavigate(currentGroup)}>
                  <img src="/imgs/modules/diagnostics-icon.svg" alt={t('Diagnostics tool icon')} />
                  <h3>{t('Bike problem?')}</h3>
                  <h4>{t('Technical Support')}</h4>
                  <p>{t('I have a problem with a bike. I would like to talk with a technician.')}</p>
                  <button className="btn btn-primary btn-inline btn-small">
                    {t('Contact')}
                  </button>
                </div>
              </div>
              <div className="col-6">
                <div className="card card-container module-card" onClick={() => handleNavigate('FTEX')}>
                  <img src="/imgs/modules/diagnostics-icon.svg" alt={t('Diagnostics tool icon')} />
                  <h3>{t('Platform problem?')}</h3>
                  <h4>{t('Get Support')}</h4>
                  <p>{t('I have a problem with the website. I would like to talk with an FTEX employee.')}</p>
                  <button className="btn btn-primary btn-small">
                    {t('Contact')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HelpComponent;
