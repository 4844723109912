import React, { useState, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import AuthService from "../services/auth.service";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Debug from "../services/debug.js";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const ResetPassword = ({setLoadingScreen}) => {
  const { t, i18n } = useTranslation();

  const [loggedIn, setLoggedIn] = useState(false);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const key = searchParams.get("key");

  useEffect(() => {
    //check if request is valid
    if (!email || !key) {
      navigate("/login");
    }

    const checkLoginStatus = async () => {
      try {
        const currentUser = await AuthService.getCurrentUser();
        if (currentUser) {
          setLoggedIn(true);
        }
      } catch (error) {
        Debug.logger(1, "Error checking user status:", error);
      }
    };

    checkLoginStatus();
  }, []);

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();

    setMessage("");
    setLoadingScreen(true); // Use the setLoading function


    AuthService.resetPassword(email, key, password)
      .then(() => {
        setLoadingScreen(false);
        AuthService.getCurrentUserData().then(
          () => {
            navigate("/bleConnect");
          }
        );
      })
      .catch((error) => {
        if (error.data && error.data.data && error.data.data.message) {
          setMessage(t(error.data.data.message));
        } else {
          setMessage(t('An error occurred during the password reset'));
        }
        setLoadingScreen(false);
      });
  };

  return (
    <div className="container-fluid login-wrapper text-center">
      <div className="col-md-12">
        {loggedIn && navigate("/bleConnect")}
        <img src="../imgs/ftex-logo-color.png" alt="FTEX Evionics Operating System" />
        <h1>
          {t('Diagnostics Tool')}
        </h1>
        <div className="card card-container">
          <Form onSubmit={handlePasswordReset}>

            <div className="form-group">
              <label htmlFor="password">{t('New Password')}</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                placeholder={t('New Password')}
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-big"
              >
                <span>{t('Change Password')}</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
          <Link to={"/login"}>
            {t('Back to Login')}
          </Link>       
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;