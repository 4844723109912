import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import useCANStream from '../../../hooks/useCANStream.hook'; // Ensure the correct path
import { useTranslation } from 'react-i18next';

const CadenceTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const nodeId = 0x00000601;
  const index = 0x2024;
  const subIndex = 0x00;

  const timeAvailable = 20;

  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("Please start turning the pedals.");
  const [cadenceChangeCounter, setCadenceChangeCounter] = useState(0);
  const [initialValueFailed, setInitialValueFailed] = useState(false);
  const [countdown, setCountdown] = useState(timeAvailable);
  const { t } = useTranslation();

  const currentValue = useRef([]);
  const initialValueChecked = useRef(false);
  const startTime = useRef(null);
  const streamStarted = useRef(false);
  const timerId = useRef(null);

  const setNewValue = (value) => {
    currentValue.current.push(value);
    valueUpdated();
  };

  const countValueChanges = (arr) => {
    if (arr.length === 0) return 0;

    let changeCount = 0;
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] !== arr[i - 1]) {
        changeCount++;
      }
    }

    return changeCount;
  };

  const { startStream, stopStream } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    [{ nodeId, index, subIndex }],
    (idx, value) => {
      if (idx.index === index && idx.subIndex === subIndex) {
        setNewValue(value);
      }
    }
  );

  const handleTestStop = useCallback((result) => {
    clearInterval(timerId.current);
    setInstruction(null);
    setTestResult(result);
    setTestRunning(false);
    stopStream();
  }, [stopStream]);

  useEffect(() => {
    return () => {
      clearInterval(timerId.current);
      stopStream();
    };
  }, [stopStream]);

  const startTestStream = () => {
    streamStarted.current = true;
    setTestRunning(true);
    startTime.current = new Date();
    startStream();
    console.log('start stream and timerID');
    timerId.current = setInterval(() => {
      const elapsedSeconds = Math.floor((new Date() - startTime.current) / 1000);
      setCountdown(timeAvailable - elapsedSeconds);

      if (elapsedSeconds >= timeAvailable) {
        handleTestStop(false);
        setTestFinished(true);
      }
    }, 1000);
  }

  useEffect(() => {
    if (!streamStarted.current) {
      startTestStream();
    }
  }, [startStream, handleTestStop]);

  const valueUpdated = () => {
    const latestValue = currentValue.current.length > 0 ? currentValue.current[currentValue.current.length - 1] : null;

    if (latestValue !== null) {
      if (!initialValueChecked.current) {
        if (latestValue === 0) {
          initialValueChecked.current = true;
          setInstruction("Turn the pedals on the bike");
        } else {
          setInitialValueFailed(true);
          setInstruction("Initial value of zero not detected");
          initialValueChecked.current = true;
        }
      } else {
        const changes = countValueChanges(currentValue.current);
        setCadenceChangeCounter(changes);

        const elapsedSeconds = Math.floor((new Date() - startTime.current) / 1000);
        setCountdown(timeAvailable - elapsedSeconds);

        if (changes >= 5) {
          setTestFinished(true);
          if (initialValueFailed) {
            handleTestStop(false);            
          } else {
            handleTestStop(true);
          }
        } else if (elapsedSeconds >= timeAvailable) {
          setTestFinished(true);
          if(cadenceChangeCounter >= 5 && !initialValueFailed) {
            handleTestStop(true);
          } else {
            handleTestStop(false);
          }
        }
      }
    }
  };

  const restartTest = () => {
    setTestRunning(true);
    setTestResult(null);
    setCadenceChangeCounter(0);
    currentValue.current = [];
    initialValueChecked.current = false;
    setInstruction("Please start turning the pedals.");
    setInitialValueFailed(false);
    setCountdown(timeAvailable);
    setTestFinished(false);
    startTime.current = new Date();
    startTestStream();
    setTimeout(() => {
      console.log('set timeout', countdown, timeAvailable)
      if (countdown === timeAvailable) {
        startTestStream();
      }
    }, 2000);
  };

  return (
    <div>
      <h1>Step {stepNumber} - Cadence Test</h1>
      <p className="instructions">{instruction}</p>
      {testFinished && initialValueFailed && (
        <div className="fail-title">
          <h3>Test Failed</h3>
          Cadence initial state is not zero. This means that the cadence/torque sensor is sending a cadence input when pedals are not turning, this can be dangerouse and the cadence/torque sensor needs to be replaced.
        </div>
      )}
      {testFinished && !initialValueFailed && cadenceChangeCounter < 5 && (
        <div className="fail-title">
          <h3>Test Failed</h3>
          A cadence (pedal turning) was not detected. If your turned the pedals within the timer and you are getting this error, it means the cadence sensor is not functioning correct, or is not correctly plugged into the controller.
        </div>
      )}
      {testFinished && !initialValueFailed && cadenceChangeCounter >= 5 && (
        <div className="success-title">
          <h3>Test Successful</h3>
          The initial cadence state is 0 (no turning detect when not turning pedals), and we are able to detect the cadence when the pedals are turned.
        </div>
      )}
      {testFinished && (
        <div className="test-summary">
          <p>
            <img src="/imgs/icons/icon-warning.svg" alt="Warning" />
            A summary of the entire test will be displayed at the end.
          </p>
        </div>
      )}
      {testResult === null && (
        <p>Current Cadence: {currentValue.current.length > 0 ? currentValue.current[currentValue.current.length - 1] : 'Waiting for data...'}</p>
      )}
      {!testFinished && (
        <div>
          <span className="countdown">Time remaining: {countdown} seconds</span>
          <ProgressBar
            now={(timeAvailable - countdown) * 5}
            className={countdown > 0 ? 'in-progress' : 'timeout'}
          />
        </div>
      )}
      {testResult === true && (
        <div>
          <Button onClick={() => onResult({ 
            result: true, 
            data: cadenceChangeCounter,
            message: "The initial cadence state is 0 (no turning detect when not turning pedals), and we are able to detect the cadence when the pedals are turned." 
          })}>Next</Button>
        </div>
      )}
      {testResult === false && (
        <div>
          <Button className="btn btn-large btn-primary mr-4" onClick={restartTest}>Restart Test</Button>
          {testFinished && initialValueFailed && (
            <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
              result: false, 
              data: cadenceChangeCounter,
              message: "Cadence initial state is not zero. This means that the cadence/torque sensor is sending a cadence input when pedals are not turning, this can be dangerouse and the cadence/torque sensor needs to be replaced." 
            })}>Skip Test</Button>
          )}
          {testFinished && !initialValueFailed && cadenceChangeCounter < 5 && (
            <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
              result: false, 
              data: cadenceChangeCounter,
              message: "A cadence (pedal turning) was not detected. If your turned the pedals within the timer and you are getting this error, it means the cadence sensor is not functioning correct, or is not correctly plugged into the controller." 
            })}>Skip Test</Button>
          )}

        </div>
      )}
    </div>
  );
};

export default CadenceTest;
