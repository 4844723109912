import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import RestAPIService from '../../services/rest-api.service';
import { useTranslation } from 'react-i18next';

const AddStoreModal = ({ show, onHide, userGroup, fetchStoresByBrand }) => {
  const { t } = useTranslation();
  const initialStoreState = {
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: 'Canada',
    postalCode: '',
    phone: '',
    brand: userGroup,
  };

  const [newStore, setNewStore] = useState(initialStoreState);
  const [statesOrProvinces, setStatesOrProvinces] = useState([]);
  const [error, setError] = useState('');

  const provincesAndStates = {
    Canada: ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Northwest Territories", "Nunavut", "Yukon"],
    "United States": ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming", "American Samoa", "Guam", "Northern Mariana Islands", "Puerto Rico", "U.S. Virgin Islands"],
    "Taiwan": ["Taiwan"]
  };

  useEffect(() => {
    setStatesOrProvinces(provincesAndStates[newStore.country]);
  }, [newStore.country]);

  useEffect(() => {
    if (show) {
      setNewStore(initialStoreState);
      setError('');
    }
  }, [show]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStore({ ...newStore, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    RestAPIService.createStore(newStore).then((response) => {
      if (response.status === 201) {
        setError('');
        onHide();
        fetchStoresByBrand();
      } else {
        setError(t('There was an error adding the store. Please try again.'));
      }
    }).catch((error) => {
      console.error(t('There was an error adding the store!'), error);
      setError(t('There was an error adding the store. Please try again.'));
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Modal.Title>{t('Add New Store')}<span>{t('We need some details')}</span></Modal.Title>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <Form.Group controlId="formStoreName">
                  <Form.Label>{t('Store Name')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={newStore.name}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formStorePhone">
                  <Form.Label>{t('Store Phone Number')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={newStore.phone}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formStoreAddress1">
                  <Form.Label>{t('Address Line 1')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="address1"
                    value={newStore.address1}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formStoreAddress2">
                  <Form.Label>{t('Address Line 2')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    value={newStore.address2}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group controlId="formStoreCity">
                  <Form.Label>{t('City')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={newStore.city}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formStorePostalCode">
                  <Form.Label>{t('Postal Code')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="postalCode"
                    value={newStore.postalCode}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formStoreCountry">
                  <Form.Label>{t('Country')}</Form.Label>
                  <Form.Control
                    as="select"
                    name="country"
                    value={newStore.country}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="Canada">{t('Canada')}</option>
                    <option value="United States">{t('United States')}</option>
                    <option value="Taiwan">{t('Taiwan')}</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formStoreState">
                  <Form.Label>{t('Province/State')}</Form.Label>
                  <Form.Control
                    as="select"
                    name="state"
                    value={newStore.state}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">{t('Select Province/State')}</option>
                    {statesOrProvinces.map((stateOrProvince, index) => (
                      <option key={index} value={stateOrProvince}>{stateOrProvince}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-9">
                <Button className="btn btn-block btn-big" type="submit">
                  {t('Add Store')}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddStoreModal;
