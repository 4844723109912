import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import useCANStream from '../../../hooks/useCANStream.hook'; // Ensure the correct path
import { useTranslation } from 'react-i18next';

const PASTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const nodeId = 0x00000601;
  const index = 0x2003;
  const subIndex = 0x00;

  const timeAvailable = 20;

  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("Please change the PAS level three times.");
  const [pasChangeCounter, setPasChangeCounter] = useState(0);
  const [countdown, setCountdown] = useState(timeAvailable);
  const { t } = useTranslation();

  const currentValue = useRef([]);
  const startTime = useRef(null);
  const streamStarted = useRef(false);

  const { startStream, stopStream } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    [{ nodeId, index, subIndex }],
    (idx, value) => {
      if (idx.index === index && idx.subIndex === subIndex) {
        setNewValue(value);
      }
    }
  );


  useEffect(() => {
    startStream();
    return () => {
      stopStream();
    };
  }, []);

  const setNewValue = (value) => {
    currentValue.current.push(value);
    valueUpdated(testFinished);
  };

  const countValueChanges = (arr) => {
    if (arr.length === 0) return 0;

    let changeCount = 0;
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] !== arr[i - 1]) {
        changeCount++;
      }
    }

    return changeCount;
  };

  const handleTestStop = useCallback((result) => {
    setInstruction(null);
    setTestResult(result);
    setTestRunning(false);
  }, [pasChangeCounter]);

  useEffect(() => {
    if (!streamStarted.current) {
      streamStarted.current = true;
      setTestRunning(true);
      startTime.current = new Date();
    }
  }, [streamStarted]);

  const valueUpdated = (testFinished) => {
    const latestValue = currentValue.current.length > 0 ? currentValue.current[currentValue.current.length - 1] : null;

    if (latestValue !== null && !testFinished) {
      const changes = countValueChanges(currentValue.current);
      setPasChangeCounter(changes);

      const elapsedSeconds = Math.floor((new Date() - startTime.current) / 1000);
      const newCountdown = timeAvailable - elapsedSeconds < 0 ? 0 : timeAvailable - elapsedSeconds; 
      setCountdown(newCountdown);
    }
  };

  useEffect(() => {
    if (testRunning && pasChangeCounter >= 3) {
      handleTestStop(true);
      setTestFinished(true);
    } else if (testRunning && countdown <= 0) {
      handleTestStop(pasChangeCounter >= 3);
      setTestFinished(true);
    }
  }, [pasChangeCounter, countdown, handleTestStop, testRunning]);

  const restartTest = () => {
    setTestRunning(true);
    setTestResult(null);
    setPasChangeCounter(0);
    currentValue.current = [];
    setInstruction("Please change the PAS level three times.");
    setCountdown(timeAvailable);
    setTestFinished(false);
    startTime.current = new Date();
  };

  return (
    <div>
      <h1>Step {stepNumber} - Screen Communication Test</h1>
      <p className="instructions">{instruction}</p>
      {testFinished && pasChangeCounter < 3 && (
        <div className="fail-title">
          <h3>Test Failed</h3>
          PAS level changes not detected. This means either the PAS selector is not connected, broken, or that the screen is not communicating correctly with the controller.
        </div>
      )}
      {testFinished && pasChangeCounter >= 3 && (
        <div className="success-title">
          <h3>Test Successful</h3>
          PAS successfully completed. Detecting PAS changes indicates that the screen is successfully communicating with the controller. All other information should display correctly on the screen.
        </div>
      )}
      {testFinished && (
        <div className="test-summary">
          <p>
            <img src="/imgs/icons/icon-warning.svg" alt={t("Warning")} />
            A summary of the entire test will be displayed at the end.
          </p>
        </div>
      )}
      {testResult === null && (
        <p>Current PAS Level: {currentValue.current.length > 0 ? currentValue.current[currentValue.current.length - 1] : 'Waiting for data...'}</p>
      )}
      {!testFinished && (
        <div>
          <span className="countdown">Time remaining: {countdown} seconds</span>
          <ProgressBar
            now={(timeAvailable - countdown) * 5}
            className={countdown > 0 ? 'in-progress' : 'timeout'}
          />
        </div>
      )}
      {testResult === true && (
        <div>
          <Button onClick={() => onResult({ 
            result: true, 
            data: pasChangeCounter,
            message: "PAS successfully completed. Detecting PAS changes indicates that the screen is successfully communicating with the controller. All other information should display correctly on the screen."
          })}>Next</Button>
        </div>
      )}
      {testResult === false && (
        <div>
          <Button className="btn btn-large btn-primary mr-4" onClick={restartTest}>Restart Test</Button>
          <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
            result: false, 
            data: pasChangeCounter, 
            message: "PAS level changes not detected. This means either the PAS selector is not connected, broken, or that the screen is not communicating correctly with the controller." 
          })}>Skip Test</Button>
        </div>
      )}
    </div>
  );
};

export default PASTest;
