import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import AnalyticsService from "../services/analytics.service";
import Debug from "../services/debug.js";
import { useTranslation } from 'react-i18next';


const BikeSolution = ({
  isConnected,
  isPreflightChecked,
  currentBikeError,
  bikeSerial,
  getBikeBrand,
  getBikeModel,
  currentSolution,
}) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(1);

  const previousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  }

  const nextStep = () => {
    if (currentStep < currentSolution.solution.steps.length) {
      setCurrentStep(currentStep + 1);
    }
  }

  const fixedBike = () => {

    let solutionText = new DOMParser()
        .parseFromString(currentSolution.solution.steps[currentStep -1].description, 'text/html').body.textContent || '';


    let error = "Error Code: " + currentBikeError;
    let comment = "Error Code " + currentBikeError + " fixed with solution: " + currentSolution.solution.solution_description + ". Fixed at step " + solutionText;
    let params = {
      type: "errorFixed",
      error: currentBikeError,
      solution: currentSolution.index,
      step: currentStep,
    }
    AnalyticsService.addRecord(bikeSerial, error, comment, params);
    navigate('/bike');
  }

  useEffect(() => {
    if (!isConnected && !isPreflightChecked) {
      Debug.logger(1, 'battery redirect', isConnected, isPreflightChecked)
      navigate('/bleConnect');
    }

    if (isConnected && !isPreflightChecked) {
      Debug.logger(1, 'battery redirect 2', isConnected, isPreflightChecked)
      navigate('/bluetoothPreflight');
    }

    if (!currentSolution || !currentSolution.solution.steps) {
      navigate('/bike');
    }
    console.log('currentSolution', currentSolution);
  }, [isConnected, isPreflightChecked, navigate, currentSolution]);

  useEffect(() => {

  }, [currentStep]);

  return (
    <>
      {currentSolution && (
        <div className="container-fluid bike-solution-wrapper">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <header>
                <Link to={"/bike"} className="btn btn-secondary">
                  {t("Go back to error codes")}
                </Link>
                <h1>
                  {t("Step")} {currentStep}/{currentSolution.solution.steps.length}
                </h1>
              </header>
            </div>
            <div className="col-md-10">
              <p>
                {t("Error Code")} : {currentBikeError}
              </p>
              <h3>
                {t("Step to Complete")}
              </h3>
              <div dangerouslySetInnerHTML={{ __html: currentSolution.solution.steps[currentStep - 1].description }}></div>
            </div>
            <div className="col-md-10 steps-buttons">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  {currentStep > 1 && (
                    <button 
                      className="btn btn-big btn-secondary"
                      onClick={() => previousStep()}
                    >
                      {t("Previous Step")}
                    </button>
                  )}
                </div>
                <div className="col-md-4 d-flex justify-content-center">
                  <button 
                    className="btn btn-big btn-success"
                    onClick={() => fixedBike()}
                  >
                    {t("Problem Solved")}
                  </button>
                </div>
                <div className="col-md-4 d-flex align-items-end flex-column">
                  {currentStep < currentSolution.solution.steps.length && (
                    <button 
                      className="btn btn-big btn-primary"
                      onClick={() => nextStep()}
                    >
                      {t("Next Step")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      </>
  );
};

export default BikeSolution;