// define this environment [local, dev, prod]
let env = "local";
// let env = "dev";
// let env = "prod";

//defaults, don't touch
let defaults = {
  isDebugMode: false, 
  debugDetail: 1, 
  wordpressAPI: 'http://diagnosticsServer.ftex.ca/wp-json/acf/v3',
  API_URLL: "http://diagnosticsServer.ftex.ca",
  AUTH_KEYL: "super_secret_key",
  allowedFirmwares: [
    "01.01.0005"
  ],
}

let local = {
  isDebugMode: true, 
  debugDetail: 3, 
  //wordpressAPI: 'http://localhost/ftex-diagnostics/wp-json/acf/v3', // local deployment
  //API_URL: "http://localhost/ftex-diagnostics",
  // wordpressAPI: 'https://diagnosticsftexca.kinsta.cloud/wp-json/acf/v3', // site preview deployment on Kinsta
  // API_URL: "https://diagnosticsftexca.kinsta.cloud",
  wordpressAPI: 'https://diagnostics-wp.ftex.ca/wp-json/acf/v3', // prod
  wordpressURL: 'https://diagnostics-wp.ftex.ca', // prod
  wordpressPath: '/wp-json/acf/v3', // prod
  API_URL: "https://diagnostics-wp.ftex.ca", // prod
  AUTH_KEY: "ftex1234",
}

let prod = {
  isDebugMode: false, 
  debugDetail: 1, 
  wordpressAPI: 'https://diagnostics-wp.ftex.ca/wp-json/acf/v3', // prod
  wordpressURL: 'https://diagnostics-wp.ftex.ca', // prod
  wordpressPath: '/wp-json/acf/v3', // prod
  API_URL: "https://diagnostics-wp.ftex.ca", // prod
  AUTH_KEY: "ftex1234",
}

let config = {};
if (env = "local") {
  Object.assign(config, defaults, prod);
}

console.log('config', config);

export default config;