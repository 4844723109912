import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import RestAPIService from '../../services/rest-api.service';
import useContent from '../../hooks/useContent.hook'; // Import the useContent hook

const AddBikeModal = ({ show, onHide, userGroup, listBikesByBrand }) => {
  const { t } = useTranslation();
  const { errorCodeDocumentation } = useContent(); // Destructure errorCodeDocumentation from useContent
  const [bike, setBike] = useState({
    brand: userGroup, // Set the brand as the userGroup and make it non-editable
    model: '',
    year: '',
    bike_serial: '',
    battery_serial: '',
    ftex_serial: ''
  });
  const [models, setModels] = useState([]);

  useEffect(() => {
    if (errorCodeDocumentation) {
      const bikeModels = Object.keys(errorCodeDocumentation);
      setModels(bikeModels);
    }
  }, [errorCodeDocumentation]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBike({ ...bike, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    RestAPIService.createBike(bike)
      .then(() => {
        listBikesByBrand(userGroup);
        onHide();
      })
      .catch(error => {
        console.error(t("There was an error creating the bike!"), error);
      });
  };

  const uniqueModels = [...new Set(models.map(model => model.split(' ')[0]))];
  const yearsForModel = bike.model ? models.filter(model => model.startsWith(bike.model)).map(model => model.split(' ')[1]) : [];

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Add Bike")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBrand">
            <Form.Label>{t("Brand")}</Form.Label>
            <Form.Control
              type="text"
              name="brand"
              value={bike.brand}
              onChange={handleChange}
              required
              readOnly // Make the brand field non-editable
            />
          </Form.Group>
          <Form.Group controlId="formModel">
            <Form.Label>{t("Model")}</Form.Label>
            <Form.Control
              as="select"
              name="model"
              value={bike.model}
              onChange={handleChange}
              required
            >
              <option value="">{t("Select Model")}</option>
              {uniqueModels.map((model, index) => (
                <option key={index} value={model}>{model}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formYear">
            <Form.Label>{t("Year")}</Form.Label>
            <Form.Control
              as="select"
              name="year"
              value={bike.year}
              onChange={handleChange}
              required
              disabled={!bike.model} // Disable the year dropdown until a model is selected
            >
              <option value="">{t("Select Year")}</option>
              {yearsForModel.map((year, index) => (
                <option key={index} value={year}>{year}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formBikeSerial">
            <Form.Label>{t("Bike Serial")}</Form.Label>
            <Form.Control
              type="text"
              name="bike_serial"
              value={bike.bike_serial}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBatterySerial">
            <Form.Label>{t("Battery Serial")}</Form.Label>
            <Form.Control
              type="text"
              name="battery_serial"
              value={bike.battery_serial}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formFtexSerial">
            <Form.Label>{t("Controller Serial")}</Form.Label>
            <Form.Control
              type="text"
              name="ftex_serial"
              value={bike.ftex_serial}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            {t("Add Bike")}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddBikeModal;
