import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Debug from '../../services/debug.js';
import RestAPIService from '../../services/rest-api.service';
import useContent from '../../hooks/useContent.hook';
import { format } from 'date-fns';
import AddCommentModal from '../fleet/AddCommentModal'; // Import the AddCommentModal component
import BatterySerialModal from '../fleet/BatterySerialModal'; // Import the BatterySerialModal component
import ControllerSerialModal from '../fleet/ControllerSerialModal'; // Import the ControllerSerialModal component

const BikeComponent = ({ bikeSerial, debugClearError, isConnected, getErrorCodeByBLE }) => {
  const { t } = useTranslation();
  const [batterySerial, setBatterySerial] = useState('');
  const [ftexSerial, setFtexSerial] = useState('');
  const [bikeBrand, setBikeBrand] = useState('');
  const [bikeModel, setBikeModel] = useState('');
  const [bikeYear, setBikeYear] = useState('');
  const [bikeRecords, setBikeRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedErrorCode, setSelectedErrorCode] = useState('');
  const [selectedSource, setSelectedSource] = useState('');
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showBatteryModal, setShowBatteryModal] = useState(false);
  const [showControllerModal, setShowControllerModal] = useState(false);
  const [bikeErrors, setBikeErrors] = useState([]);
  const [errorSolutions, setErrorSolutions] = useState({});
  const navigate = useNavigate();

  const { errorCodeDocumentation } = useContent();
  const location = useLocation();

  const fetchBikeInfo = async () => {
    try {
      let bikeInfo = await RestAPIService.getBike(bikeSerial); // Use bike serial from props
      Debug.logger(2, 'bikeInfo', bikeInfo);
      setBatterySerial(bikeInfo.battery_serial);
      setFtexSerial(bikeInfo.ftex_serial);
      setBikeBrand(bikeInfo.brand);
      setBikeModel(bikeInfo.model);
      setBikeYear(bikeInfo.year);
    } catch (error) {
      Debug.logger(2, 'Error fetching bike info from Rest API Server', error);
    }
  };

  const fetchBikeRecords = async () => {
    try {
      let results = await RestAPIService.getBikeRecords(bikeSerial); // Use bike serial from props
      Debug.logger(1, 'bike getBikeRecords', results);
      setBikeRecords(results);
      setFilteredRecords(results);
    } catch (error) {
      console.error('Error fetching bike records:', error);
    }
  };

  const fetchBikeErrors = async () => {
    console.log('fetching bike errors')
    try {
      let errors = await getErrorCodeByBLE(setBikeErrors, debugClearError);
      console.log('errors', errors);
      setBikeErrors(errors);
    } catch (error) {
      console.log('Error fetching bike errors from Bluetooth', error);
    }
  };

  useEffect(() => {
    console.log('isConnected', isConnected);
    if (!isConnected) {
      navigate('/diagnostics');
    }
  }, [isConnected]);

  useEffect(() => {
    if (bikeSerial) {
      fetchBikeInfo();
      fetchBikeRecords();
      fetchBikeErrors();
    }
  }, [bikeSerial, debugClearError]);

  useEffect(() => {
    if (errorCodeDocumentation && bikeModel && bikeYear) {
      const selectedBikeModel = `${bikeModel} ${bikeYear}`;
      if (errorCodeDocumentation[selectedBikeModel]) {
        setErrorSolutions(errorCodeDocumentation[selectedBikeModel]);
      }
    }
  }, [errorCodeDocumentation, bikeModel, bikeYear]);

  useEffect(() => {
    if (location.pathname === '/diagnostics/bike') {
      fetchBikeRecords();
      fetchBikeErrors();
    }
  }, [location]);

  const determineRecordType = (record) => {
    if (record.comment.includes('Battery Serial Number')) {
      return t('Battery Serial Change');
    } else if (record.comment.includes('Controller Serial Number')) {
      return t('Controller Serial Change');
    } else if (record.problem && record.problem.toLowerCase().includes('error')) {
      return t('Error');
    } else if (record.source && record.source.toLowerCase().includes('retailer')) {
      return t('Retailer Comment');
    } else {
      return t('Brand Comment');
    }
  };

  const getAvailableTypes = () => {
    const types = bikeRecords.map(record => determineRecordType(record));
    return [...new Set(types)];
  };

  const getAvailableErrorCodes = () => {
    const errorCodes = bikeRecords.map(record => {
      if (record.problem && record.problem.toLowerCase().includes('error')) {
        return record.problem;
      }
      return '';
    }).filter(Boolean);
    return [...new Set(errorCodes)];
  };

  const getAvailableSources = () => {
    const sources = bikeRecords.map(record => record.source).filter(Boolean);
    return [...new Set(sources)];
  };

  const applyFilters = () => {
    let filtered = bikeRecords;

    if (startDate) {
      filtered = filtered.filter(record => new Date(record.date) >= new Date(startDate));
    }

    if (endDate) {
      filtered = filtered.filter(record => new Date(record.date) <= new Date(endDate));
    }

    if (selectedType) {
      filtered = filtered.filter(record => determineRecordType(record) === selectedType);
    }

    if (selectedErrorCode) {
      filtered = filtered.filter(record => record.problem && record.problem.includes(selectedErrorCode));
    }

    if (selectedSource) {
      filtered = filtered.filter(record => record.source === selectedSource);
    }

    setFilteredRecords(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [startDate, endDate, selectedType, selectedErrorCode, selectedSource, bikeRecords]);

  const showSolution = (solution, errorCode, solutionIndex) => {
    navigate('/diagnostics/bike/solution', {
      state: {
        bikeSerial,
        bikeBrand,
        bikeModel,
        bikeYear,
        ftexSerial,
        batterySerial,
        errorCode,
        solutionIndex,
        solution
      }
    });
  };

  const bikeConnectionsCount = bikeRecords.filter(record => record.action === 'bike-connection').length;
  const bikeErrorsCount = bikeRecords.filter(record => record.params && record.params.type === 'error').length;

  return (
    <div className="container-fluid bike-detail">
      <div className="row">
        <div className="col-6">
          <h3>{t('Bike Details')}</h3>
          <h4>{t('Info and History')}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          <div className="bike-detail-summary">
            {bikeBrand && bikeModel && bikeYear && (
              <>
                <h2>
                  <span>{bikeBrand}</span> {bikeModel} ({bikeYear})&nbsp;-&nbsp;
                </h2>
                <h3>{bikeSerial}</h3>
                <div className="row info-box-container">
                  <div className="col info-box">
                    <h5>{t('Controller Serial')}:</h5>
                    <span>{ftexSerial}</span>
                  </div>
                  <div className="col info-box">
                    <h5>{t('Battery Serial')}:</h5>
                    <span>{batterySerial}</span>
                    <button className="btn btn-danger btn-block" onClick={() => setShowBatteryModal(true)}>{t('Edit')}</button>
                  </div>
                  <div className="col info-box">
                    <h5>{t('Model')}:</h5>
                    <span>{bikeModel}</span>
                  </div>
                  <div className="col info-box">
                    <h5>{t('Year')}:</h5>
                    <span>{bikeYear}</span>
                  </div>
                  <div className="col info-box">
                    <h5>{t('Bike Serial')}:</h5>
                    <span>{bikeSerial}</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row fleet-module">
        {bikeErrors?.filter(error => error.code != 0).length > 0 && (
          <div className="col-12 code-guides">
            <h4>{t('Errors on This Bike')}</h4>
            <ul className="row">
              {bikeErrors.filter(error => error.code != 0).map((error, index) => (
                <li key={index} className="col-sm-6 col-md-3 col-xl-3">
                  <div className="error-code-card error mb-3">
                    <h4>{t('Error Code')} - <span>{error.code}</span></h4>
                    <p>{error.description}</p>
                    <img src="/imgs/icons/icon-error.svg" alt={t('Error')} />
                    {errorSolutions[error.code] && (
                      errorSolutions[error.code].solutions.length === 1 ? (
                        <button
                          className="solution btn-block btn btn-large btn-primary"
                          onClick={() => showSolution(errorSolutions[error.code].solutions[0], error.code, 1)}
                        >
                          {t('Solution')}
                        </button>
                      ) : (
                        errorSolutions[error.code].solutions.map((solution, idx) => (
                          <button
                            key={idx}
                            className="solution btn-block btn btn-large btn-primary"
                            onClick={() => showSolution(solution, error.code, idx + 1)}
                          >
                            {t('Solution')} {idx + 1}
                          </button>
                        ))
                      )
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="row fleet-module">
        <div className="col-12">
          <h4 className="mt-4">{t('Run Diagnostics')}</h4>
        </div>
        <div className="col-12">
          <Link to="/diagnostics/run" className="btn btn-primary btn-large">
            {t('Run Diagnostics')}
          </Link>
        </div>
      </div>
      <div className="bike-detail">
        <div className="row">
          <div className="col-md-12">
            <button className="btn btn-secondary add-comment mb-3" onClick={() => setShowCommentModal(true)}>
              {t('Add Comment')}
              <img src="/imgs/icons/icon-plus.svg" alt={t('Add Comment')} />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="filters">
              <div className="clear-filters col">
                {startDate && (
                  <button className="btn btn-filter btn-small btn-inline-block" onClick={() => setStartDate('')}>
                    {t('After Date')}
                    <img src="/imgs/icons/icon-x.svg" alt={t('Remove filter')} />
                  </button>
                )}
                {endDate && (
                  <button className="btn btn-filter btn-small btn-inline-block" onClick={() => setEndDate('')}>
                    {t('Before Date')}
                    <img src="/imgs/icons/icon-x.svg" alt={t('Remove filter')} />
                  </button>
                )}
                {selectedType && (
                  <button className="btn btn-filter btn-small btn-inline-block" onClick={() => setSelectedType('')}>
                    {t('Type')}
                    <img src="/imgs/icons/icon-x.svg" alt={t('Remove filter')} />
                  </button>
                )}
                {selectedErrorCode && (
                  <button className="btn btn-filter btn-small btn-inline-block" onClick={() => setSelectedErrorCode('')}>
                    {t('Error Code')}
                    <img src="/imgs/icons/icon-x.svg" alt={t('Remove filter')} />
                  </button>
                )}
                {selectedSource && (
                  <button className="btn btn-filter btn-small btn-inline-block" onClick={() => setSelectedSource('')}>
                    {t('Source')}
                    <img src="/imgs/icons/icon-x.svg" alt={t('Remove filter')} />
                  </button>
                )}
              </div>
              <div className="filter-input col">
                <div className="filter">
                  <label htmlFor="startDate">{t('After')}:</label>
                  <input
                    type="date"
                    id="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className="filter">
                  <label htmlFor="endDate">{t('Before')}:</label>
                  <input
                    type="date"
                    id="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                {getAvailableTypes().length > 0 && (
                  <div className="filter">
                    <select
                      className="form-select"
                      id="typeFilter"
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      <option value="">{t('Sort by Comment Type')}</option>
                      {getAvailableTypes().map((type, index) => (
                        <option key={index} value={type}>{type}</option>
                      ))}
                    </select>
                  </div>
                )}
                {getAvailableErrorCodes().length > 0 && (
                  <div className="filter">
                    <select
                      className="form-select"
                      id="errorCodeFilter"
                      value={selectedErrorCode}
                      onChange={(e) => setSelectedErrorCode(e.target.value)}
                    >
                      <option value="">{t('Sort by Error Code')}</option>
                      {getAvailableErrorCodes().map((code, index) => (
                        <option key={index} value={code}>{code}</option>
                      ))}
                    </select>
                  </div>
                )}
                {getAvailableSources().length > 0 && (
                  <div className="filter">
                    <select
                      className="form-select"
                      id="sourceFilter"
                      value={selectedSource}
                      onChange={(e) => setSelectedSource(e.target.value)}
                    >
                      <option value="">{t('Sort by Source')}</option>
                      {getAvailableSources().map((source, index) => (
                        <option key={index} value={source}>{source}</option>
                      ))}
                    </select>
                  </div>
                )}
                {bikeRecords.some(record => record.source === 'brand') && (
                  <div className="filter">
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedSource === 'brand'}
                        onChange={(e) => setSelectedSource(e.target.checked ? 'brand' : '')}
                      />
                      {t('Brand Comments Only')}
                    </label>
                  </div>
                )}
              </div>
            </div>
            <table className="table table-no-borders">
              <thead>
                <tr>
                  <th>{t('Date')}</th>
                  <th>{t('Retailer/Source')}</th>
                  <th>{t('Problem/Error')}</th>
                  <th>{t('Comment')}</th>
                  <th>{t('Error Code')}</th>
                  <th>{t('Type')}</th>
                </tr>
              </thead>
              <tbody>
                {filteredRecords.length > 0 ? (
                  filteredRecords.map((record, index) => (
                    <tr key={index}>
                      <td>{format(new Date(record.date), 'dd MM yyyy - HH:mm')}</td>
                      <td>{record.source}</td>
                      <td>{record.problem}</td>
                      <td>{record.comment}</td>
                      <td>{record.problem && record.problem.toLowerCase().includes('error') ? record.problem : ''}</td>
                      <td>{determineRecordType(record)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">{t('No records found')}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* AddCommentModal */}
      <AddCommentModal
        show={showCommentModal}
        handleClose={() => setShowCommentModal(false)}
        bikeId={bikeSerial}
        fetchBikeRecords={fetchBikeRecords}
        RestAPIService={RestAPIService}
      />

      {/* BatterySerialModal */}
      <BatterySerialModal
        show={showBatteryModal}
        handleClose={() => setShowBatteryModal(false)}
        bikeId={bikeSerial}
        fetchBikeInfo={fetchBikeInfo}
        RestAPIService={RestAPIService}
      />

      {/* ControllerSerialModal */}
      <ControllerSerialModal
        show={showControllerModal}
        handleClose={() => setShowControllerModal(false)}
        bikeId={bikeSerial}
        fetchBikeInfo={fetchBikeInfo}
        RestAPIService={RestAPIService}
      />
    </div>
  );
};

export default BikeComponent;
