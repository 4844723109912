import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import useCANStream from '../../../hooks/useCANStream.hook'; // Ensure the correct path
import { useTranslation } from 'react-i18next';

const ThrottleTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const nodeId = 0x00000601;
  const throttleADCIndex = 0x2027;
  const throttleADCSubIndex = 0x00;
  const throttleValueIndex = 0x2027;
  const throttleValueSubIndex = 0x01;
  const pasIndex = 0x2003;
  const pasSubIndex = 0x00;

  const timeAvailable = 20;

  const [testRunning, setTestRunning] = useState(true);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("Please press and hold the throttle.");
  const [initialValueFailed, setInitialValueFailed] = useState(false);
  const [countdown, setCountdown] = useState(timeAvailable);
  const { t } = useTranslation();

  const currentADCValue = useRef([]);
  const currentValue = useRef([]);
  const initialValueChecked = useRef(false);
  const startTime = useRef(null);

  const setNewValue = (value, isADC) => {
    if (isADC) {
      currentADCValue.current.push(value);
    } else {
      currentValue.current.push(value);
    }
    valueUpdated();
  };

  const writePASState = async (state) => {
    console.log('writing PAS', state);
    //writeValue(nodeId, pasIndex, pasSubIndex, state);
  };

  const { startStream, stopStream, writeValue } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    [
      { nodeId, index: throttleADCIndex, subIndex: throttleADCSubIndex },
      { nodeId, index: throttleValueIndex, subIndex: throttleValueSubIndex }
    ],
    (idx, value) => {
      if (idx.index === throttleADCIndex && idx.subIndex === throttleADCSubIndex) {
        setNewValue(value, true);
      } else if (idx.index === throttleValueIndex && idx.subIndex === throttleValueSubIndex) {
        setNewValue(value, false);
      }
    }
  );

  const instanceId = useRef(`instance-${Math.random().toString(36).substr(2, 9)}`);

  useEffect(() => {
    startStream();
    startTime.current = new Date();
    const intervalId = setInterval(() => {
      const elapsedSeconds = Math.floor((new Date() - startTime.current) / 1000);
      setCountdown((prevCountdown) => {
        const newCountdown = timeAvailable - elapsedSeconds;
        if (newCountdown <= 0) {
          handleTestStop(false);
          // clearInterval(intervalId);
        } else {
          return newCountdown;
        }
      });
    }, 1000);

    return () => {
      stopStream();
      clearInterval(intervalId);
    };
  }, []);

  const handleTestStop = (result) => {
    console.log('handleTestStop');
    setInstruction(null);
    setTestResult(result);
    setTestRunning(false);
    setTestFinished(true);
  };

  const restartTest = () => {
    writePASState(0);
    setTestRunning(true);
    setTestResult(null);
    currentADCValue.current = [];
    currentValue.current = [];
    initialValueChecked.current = false;
    setInstruction("Please press and hold the throttle.");
    setInitialValueFailed(false);
    setCountdown(timeAvailable);
    setTestFinished(false);
    startTime.current = new Date();
  };

  const valueUpdated = () => {
    const latestValue = currentValue.current.length > 0 ? currentValue.current[currentValue.current.length - 1] : null;
    console.log('value updated', testRunning, latestValue);
    if (testRunning) {
      if (latestValue !== null) {
        if (!initialValueChecked.current) {
          if (latestValue === 0) {
            initialValueChecked.current = true;
            setInstruction("Press and hold the throttle.");
          } else {
            setInitialValueFailed(true);
            setInstruction("Initial throttle value incorrect, release the throttle and restart the test.");
            initialValueChecked.current = true;
            handleTestStop(false);
          }
        } else if (latestValue === 10000) {
          handleTestStop(true);
        }
      }
    }
  };

  return (
    <div>
      <h1>Step {stepNumber} - Throttle Test</h1>
      <p className="instructions">{instruction}</p>
      {testFinished && initialValueFailed && (
        <div className="fail-title">
          <h3>Test Failed</h3>
          Throttle initial state is not zero. This means that the throttle sensor is sending an input when it shouldn't, which can be dangerous and the throttle sensor needs to be replaced.
        </div>
      )}
      {testFinished && !initialValueFailed && testResult === false && (
        <div className="fail-title">
          <h3>Test Failed</h3>
          The throttle was not detected at 100% within the time limit. If you pressed it all the way and still get this error, the throttle likely needs to be replaced.
        </div>
      )}
      {testFinished && testResult === true && (
        <div className="success-title">
          <h3>Test Successful</h3>
          The throttle correctly reads 0% when not pressed, and 100% when fully pressed.
        </div>
      )}
      {testFinished && (
        <div className="test-summary">
          <p>
            <img src="/imgs/icons/icon-warning.svg" alt="Warning" />
            A summary of the entire test will be displayed at the end.
          </p>
        </div>
      )}
      {testResult === null && (
        <p>Current Throttle Value: {currentValue.current.length > 0 ? currentValue.current[currentValue.current.length - 1] : 'Waiting for data...'}</p>
      )}
      {!testFinished && (
        <div>
          <span className="countdown">Time remaining: {countdown} seconds</span>
          <ProgressBar
            now={(timeAvailable - countdown) * 5}
            className={countdown > 0 ? 'in-progress' : (countdown <= 0 && testResult === false ? 'timeout' : '')}
          />
        </div>
      )}
      {testResult === true && (
        <div>
          <Button onClick={() => onResult({ 
            result: true, 
            data: "Throttle Detected at 100%",
            message: "The throttle correctly reads 0% when not pressed, and 100% when fully pressed." 
          })}>Next</Button>
        </div>
      )}
      {testResult === false && (
        <div>
          <Button className="btn btn-large btn-primary mr-4" onClick={restartTest}>Restart Test</Button>
          {testFinished && initialValueFailed && (
            <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
              result: false,
              data: "Throttle Not Detected at 100%",
              message: "Throttle initial state is not zero. This means that the throttle sensor is sending an input when it shouldn't, which can be dangerous and the throttle sensor needs to be replaced." 
            })}>Skip Test</Button>
          )}
          {testFinished && !initialValueFailed && testResult === false && (
            <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
              result: false,
              data: "Throttle Not Detected at 100%",
              message: "The throttle was not detected at 100% within the time limit. If you pressed it all the way and still get this error, the throttle likely needs to be replaced." 
            })}>Skip Test</Button>
          )}          
        </div>
      )}
    </div>
  );
};

export default ThrottleTest;
