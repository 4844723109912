import React, { useState, useEffect, useRef } from 'react';
import { Button, Alert, ProgressBar } from 'react-bootstrap';
import useCANStream from '../../../hooks/useCANStream.hook'; // Ensure the correct path

const MotorTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const nodeId = 0x00000601;
  const speedIndex = 0x2000;
  const speedSubIndex = 0x01;
  const pasIndex = 0x2003;
  const pasSubIndex = 0x00;
  const errorsIndex = 0x2006;
  const errorsSubIndex = 0x00;

  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("Please lift the rear wheel from the floor. Click 'Ready' when you are ready.");
  const [speed, setSpeed] = useState(null);
  const [errors, setErrors] = useState([]);
  const [pas, setPas] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [speedLog, setSpeedLog] = useState([]);
  const [throttleHeld, setThrottleHeld] = useState(false);
  const [throttleStart, setThrottleStart] = useState(null);

  const startTime = useRef(null);
  const testInProgress = useRef(false);
  const throttleTestSuccess = useRef(false);
  const initialSpeedChecked = useRef(false);
  const testStep = useRef(0);

  let streamStarted = false;

  const { startStream, stopStream, writeValue, errors: canErrors } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    [
      { nodeId, index: speedIndex, subIndex: speedSubIndex },
      { nodeId, index: errorsIndex, subIndex: errorsSubIndex },
      { nodeId, index: pasIndex, subIndex: pasSubIndex }
    ],
    (idx, value) => {
      if (idx.index === speedIndex && idx.subIndex === speedSubIndex) {
        testLoop(value);
      }
      if (idx.index === pasIndex && idx.subIndex === pasSubIndex) {
        setPas(value);
        testLoop(null, value);
      }
    }
  );

  useEffect(() => {
    if (canErrors.length > 0) {
      setErrors(canErrors.filter(error => error.code !== '0'));
      if (canErrors.some(error => error.code !== '0')) {
        testLoop(null, null, true);
      }
    }
  }, [canErrors]);

  const writePas = async (pas, preventRestart) => {
    console.log('writePas', pas);
    try {
      await writeValue(nodeId, pasIndex, pasSubIndex, pas, preventRestart);
    } catch (error) {
      console.error('Error writing PAS:', error);
    }
  };

  const handleTestStop = (result, message) => {
    setInstruction(message);
    setTestResult(result);
    setTestRunning(false);
    stopStream();
    streamStarted = false;
    setTestFinished(true);
  };

  const testLoop = (currentSpeed, PAS, error) => {
    const elapsedTime = Math.floor((Date.now() - startTime.current) / 1000);
    setCountdown(elapsedTime);

    console.log('testloop', currentSpeed, PAS, error, elapsedTime, testStep.current);
    if (testStep.current === 0) {
      checkInitSpeed(currentSpeed, elapsedTime);
    } else if (testStep.current === 1) {
      checkPAS(PAS, elapsedTime);
    } else if (testStep.current === 2) {
      checkThrottle(currentSpeed, elapsedTime);
    } else if (testStep.current === 3) {
      stopStream();
    }
  };

  const checkInitSpeed = (speed, time) => {
    if (speed === 0) {
      setInstruction("Set the PAS to 1.");
      startTime.current = Date.now();
      testStep.current = 1;
    }
    if (time > 10) {
      handleTestStop(false, 'The initial speed must be at Zero to start the test.');
    }
  };

  const checkPAS = (PAS, time) => {
    if (PAS === 1) {
      setInstruction("Press and hold the throttle for 5 seconds.");
      startTime.current = Date.now();
      testStep.current = 2;
    }
    if (time > 10) {
      handleTestStop(false, 'The PAS was not set to 1 to begin the test.');
    }
  };

  const checkThrottle = (speed, time) => {
    const newSpeedEntry = { speed, time: new Date() };

    // Ignore initial zeros
    if (speedLog.length === 0 && speed === 0) {
      return;
    }

    if (time > 20) {
      handleTestStop(false, 'Throttle or motor turn not detected.');
      testStep.current = 3;
      stopStream();
    }

    // Add the speed entry to the log
    setSpeedLog((prevLog) => {
      const updatedLog = [...prevLog, newSpeedEntry];

      // Check if we have 5 seconds of values greater than zero
      const nonZeroSpeeds = updatedLog.filter(entry => entry.speed > 0);
      if (nonZeroSpeeds.length >= 5) {
        const startTime = nonZeroSpeeds[0].time;
        const endTime = nonZeroSpeeds[nonZeroSpeeds.length - 1].time;
        const duration = (endTime - startTime) / 1000; // duration in seconds
        console.log('nonZeroSpeeds.length', nonZeroSpeeds.length, duration)
        if (duration >= 5) {
          throttleTestSuccess.current = true;
          if (errors.length > 0) {
            handleTestStop(false, 'Errors detected when turning motor');            
          } else {
            handleTestStop(true, 'Test Successful.');
          }
          testStep.current = 3;
          stopStream();
        }
      }

      return updatedLog;
    });
  };

  const startTesting = () => {
    console.log('startTesting', streamStarted);
    writePas(0, true);
    setTestRunning(true);
    setInstruction("Waiting for speed to be zero...");
    if (!streamStarted) {
      startStream();
    }
    streamStarted = true;
    startTime.current = Date.now();
    testInProgress.current = true;
  };

  const beginThrottleTest = () => {
    setThrottleHeld(true);
    setThrottleStart(Date.now());
  };

  const resetTest = () => {
    stopStream();
    setTestRunning(false);
    setTestFinished(false);
    setTestResult(null);
    setInstruction("Please lift the rear wheel from the floor. Click 'Ready' when you are ready.");
    setSpeed(null);
    setErrors([]);
    initialSpeedChecked.current = false;
    setCountdown(0);
    setSpeedLog([]);
    testInProgress.current = false;
    streamStarted = false;
  };

  useEffect(() => {
    return () => {
      writePas(0, true); // Ensure PAS is set to 0 before unmounting
      stopStream();
    };
  }, []);

  return (
    <div>
      <h1>Step {stepNumber} - Motor Test</h1>
      <Alert variant="warning">
        Be careful because the motor will begin spinning. Please lift the rear wheel from the floor.
      </Alert>
      <p className="instructions">{instruction}</p>
      {!testRunning && !testFinished && (
        <Button onClick={startTesting}>Ready</Button>
      )}
      {testRunning && (
        <div>
          <p>Current Speed: {speed !== null ? speed : 'Waiting for data...'}</p>
          <p>Time elapsed: {countdown} seconds</p>
          <ProgressBar
            now={Math.min((countdown / 20) * 100, 100)}
            className={countdown > 0 ? 'in-progress' : (countdown <= 0 && !testResult ? 'timeout' : '')}
          />
          {errors.length > 0 ? (
            <div>
              <p style={{ color: 'red' }}>Errors detected:</p>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>{`Error Code ${error.code}: ${error.description} at ${error.timestamp}`}</li>
                ))}
              </ul>
            </div>
          ) : (
            <p>No errors detected</p>
          )}
        </div>
      )}
      {testFinished && (
        <div>
          {testResult ? (
            <div>
              <p style={{ color: 'green' }}>{instruction}</p>
              <Button onClick={() => onResult({
                result: true, 
                data: "",
                message: "Motor turned and the speed was succesfully read, no errors have been detected." 
              })}>Next</Button>
            </div>
          ) : (
            <div>
              <p style={{ color: 'red' }}>{instruction}</p>
              <Button className="btn btn-large btn-primary mr-4" onClick={resetTest}>Restart Test</Button>
              <Button className="btn btn-large btn-secondary" onClick={() => onResult({
                result: false, 
                data: "Errors",
                message: "Errors have been detected in the motor, or the motor was not turning." 
              })}>Skip Test</Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MotorTest;
