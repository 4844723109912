import { useState, useRef, useCallback, useEffect } from 'react';

const useCANStream = (
  primaryService,
  pin,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener,
  indices,
  onValueRead
) => {
  const [values, setValues] = useState(new Array(indices.length).fill(null));
  const [errors, setErrors] = useState([]);
  const intervalRef = useRef(null);
  const isStreamingRef = useRef(false); // Ref to track if streaming has started
  const [writeError, setWriteError] = useState(null);
  const [isWriting, setIsWriting] = useState(false);

  const errorDescriptions = {
    0x00000000: "No error",
    0x00000001: "Throttle stuck",
    0x00000002: "Under voltage",
    0x00000004: "Over voltage",
    0x00000008: "Under temperature",
    0x00000010: "Motor Over temperature",
    0x00000020: "Motor foldback temperature",
    0x00000040: "Motor NTC Disconnected/ low temperature",
    0x00000080: "Controller over temperature",
    0x00000100: "Controller foldback temperature",
    0x00000200: "Motor Hall error",
    0x00000400: "Motor Phase Error",
    0x00000800: "IoT Comm Error",
    0x00001000: "Dual Comm Error",
    0x00002000: "Over Current",
    0x00004000: "Battery Low",
    0x00008000: "PAS Sensor Error",
    0x00010000: "Controller Error",
    0x00020000: "Brake Error"
  };

  const cloudDriveErrorMapping = {
    1: '7', // Throttle stuck
    2: '4', // Under voltage
    4: '12', // Over voltage
    8: '8', // Under temperature
    16: '11', // Motor Over temperature
    32: '11', // Motor foldback temperature
    64: '14', // Motor NTC Disconnected/ low temperature
    128: '9', // Controller over temperature
    256: '9', // Controller foldback temperature
    512: '6', // Motor Hall error
    1024: '3', // Motor Phase Error
    2048: '10', // IoT Comm Error
    16384: '13', // Battery Low
    32768: '1', // PAS Sensor Error
    65536: '2', // Controller Anomaly
    131072: '5'  // Brake Error
  };

  const decodePassthroughResponse = (response) => {
    if (response.length !== 9) {
      console.error('CANSTREAM Hook - Invalid passthrough response length:', response.length);
      return null;
    }

    const canHeader = response.slice(1, 5);
    const canDataBytes = response.slice(5, 9);
    const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

    const msgIndex = (canHeader[2] << 8) | canHeader[1];
    const msgSubIndex = canHeader[3];

    return { msgIndex, msgSubIndex, canData };
  };

  const decodeErrorResponse = (value) => {
    const errorValue = new DataView(new ArrayBuffer(8));
    new Uint8Array(errorValue.buffer).set(new Uint8Array(value.slice(1, 9)));

    const errorValueString = JSON.stringify(Array.from(new Uint8Array(errorValue.buffer)));
    console.log('error detected: ' + errorValueString);

    return readErrorCodeCharacteristic(errorValue);
  };

  const readErrorCodeCharacteristic = (dataView) => {
    if (dataView.byteLength !== 8) {
      throw new Error("Invalid data length for error code characteristic");
    }
    const errorCode = dataView.getUint32(0, true);
    const timestamp = dataView.getUint32(4, true);
    const binaryString = errorCode.toString(2).padStart(32, '0');
    console.log('error code char', errorCode, binaryString, dataView);

    const currentErrors = [];
    Object.keys(errorDescriptions).forEach((key, index) => {
      if (binaryString[31 - index] === '1') {
        currentErrors.push({
          code: cloudDriveErrorMapping[key],
          description: errorDescriptions[key],
          timestamp: new Date(timestamp * 1000).toISOString(),
        });
      }
    });

    return currentErrors.length > 0 ? currentErrors : [{
      code: '0',
      description: 'No error',
      timestamp: new Date(timestamp * 1000).toISOString(),
    }];
  };

  const notificationCallback = (event) => {
    console.log('USECAN NOTIFICATION');
    if (event) {
      const value = new Uint8Array(event.buffer);
      const messageType = String.fromCharCode(value[0]);

      if (messageType === 'P') {
        const { msgIndex, msgSubIndex, canData } = decodePassthroughResponse(value);
        if (canData !== null) {
          indices.forEach((idx, i) => {
            if (msgIndex === idx.index && msgSubIndex === idx.subIndex) {
              setValues((prevValues) => {
                const newValues = [...prevValues];
                newValues[i] = canData;
                return newValues;
              });
              onValueRead(idx, canData);
            }
          });
        }
      } else if (messageType === 'E') {
        const decodedErrors = decodeErrorResponse(value);
        if (decodedErrors) {
          setErrors(decodedErrors);
        }
      }
    }
  };

  const cleanup = useCallback(async () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    await stopNotificationListener(primaryService);
    isStreamingRef.current = false;
    console.log('CANSTREAM Hook - Cleanup completed');
  }, [stopNotificationListener, primaryService]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  const startStream = useCallback(async () => {
    await cleanup();
    console.log('startStream', isStreamingRef.current)
    if (isStreamingRef.current) {
      console.log('startStream RETURN', isStreamingRef.current)
      return; // Prevent starting multiple streams
    }
    isStreamingRef.current = true;

    try {
      const sendFunction = await getSendPassthroughRW(primaryService);
      if (!sendFunction) throw new Error('CANSTREAM Hook - Failed to initialize passthrough function');

      await stopNotificationListener(primaryService);
      await startNotificationListener(primaryService, notificationCallback);

      intervalRef.current = setInterval(async () => {
        try {
          for (const idx of indices) {
            // console.log(`CANSTREAM Hook - Sending read command for nodeId: ${idx.nodeId}, index: ${idx.index}, subIndex: ${idx.subIndex}`);
            await sendFunction('read', pin, idx.nodeId, idx.index, idx.subIndex);
          }
        } catch (error) {
          console.error('CANSTREAM Hook - Error reading parameter:', error);
        }
      }, 500);
    } catch (error) {
      console.error('CANSTREAM Hook - Error setting up stream:', error);
      isStreamingRef.current = false; // Reset the flag if an error occurs
    }
  }, [primaryService, pin, getSendPassthroughRW, startNotificationListener, stopNotificationListener, indices, onValueRead]);

  const stopStream = useCallback(() => {
    cleanup();
  }, [cleanup]);

  const writeValue = useCallback(async (nodeId, index, subIndex, value, preventRestart) => {
    setIsWriting(true);
    setWriteError(null);

    try {
      await cleanup();

      const sendFunction = await getSendPassthroughRW(primaryService);
      if (!sendFunction) throw new Error('CANSTREAM Hook - Failed to initialize passthrough function');

      await sendFunction('write', pin, nodeId, index, subIndex, value);
      setIsWriting(false);
      if (!preventRestart) {
        startStream();
      }
    } catch (error) {
      console.error('CANSTREAM Hook - Error writing value:', error);
      setWriteError(error);
      setIsWriting(false);
    }
  }, [primaryService, pin, getSendPassthroughRW]);

  return { values, errors, startStream, stopStream, writeValue, isWriting, writeError };
};

export default useCANStream;
