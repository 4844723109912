import React, { useEffect, useState } from 'react';
import RestAPIService from '../../services/rest-api.service';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet.heat';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const BikesComponent = ({ userGroup }) => {
  const { t } = useTranslation();
  const [trips, setTrips] = useState([]);
  const [bikeLocations, setBikeLocations] = useState([]);
  const [stores, setStores] = useState([]);
  const [totalTripDistance, setTotalTripDistance] = useState(0);
  const [totalTrips, setTotalTrips] = useState(0);

  useEffect(() => {
    RestAPIService.fetchSessions()
      .then(data => {
        setTrips(data);
        setTotalTrips(data.length);
        const totalDistance = Math.round(data.reduce((acc, trip) => acc + (trip.distance || 0), 0));
        setTotalTripDistance(totalDistance);
      })
      .catch(error => {
        console.error(t("Error fetching trips:"), error);
      });

    RestAPIService.fetchBikeLocations(userGroup)
      .then(data => {
        setBikeLocations(data);
      })
      .catch(error => {
        console.error(t("Error fetching bike locations:"), error);
      });

    RestAPIService.fetchStoresByBrand(userGroup)
      .then(data => {
        setStores(data);
      })
      .catch(error => {
        console.error(t("Error fetching stores:"), error);
      });
  }, [userGroup, t]);

  const tripHeatmapData = trips
    .flatMap(trip => trip.gpsLocations)
    .filter(location => location && location.longitude !== undefined && location.latitude !== undefined)
    .map(location => [location.latitude, location.longitude, 1]);

  const bikeHeatmapData = bikeLocations
    .filter(bike => bike.geolocation && bike.geolocation.length >= 2 && !(bike.geolocation[0] === 0 && bike.geolocation[1] === 0))
    .map(bike => [bike.geolocation[1], bike.geolocation[0], 1]);

  const getLastSixMonths = () => {
    const months = [];
    const date = new Date();
    for (let i = 5; i >= 0; i--) {
      const month = new Date(date.getFullYear(), date.getMonth() - i, 1);
      months.push(month.toLocaleString('en-US', { month: 'short' }));
    }
    return months;
  };

  const processTotalTripDistance = () => {
    const counts = trips.reduce((acc, trip) => {
      const date = new Date(trip.startTime);
      const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}`;
      if (!isNaN(date)) {
        acc[formattedDate] = (acc[formattedDate] || 0) + trip.distance;
      }
      return acc;
    }, {});

    const dates = Object.keys(counts).sort((a, b) => new Date(a.split('/').reverse().join('/')) - new Date(b.split('/').reverse().join('/')));
    const cumulativeCounts = dates.map((date, index) => {
      return dates.slice(0, index + 1).reduce((sum, currentDate) => sum + counts[currentDate], 0);
    });

    return {
      labels: dates,
      datasets: [
        {
          label: t('Total Trip Distance Over Time (km)'),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: 'rgba(255,159,64,0.4)',
          borderColor: 'rgba(255,159,64,1)',
          tension: 0.6,
          borderWidth: 2,
        },
      ],
    };
  };

  const processTripDistancePerDay = () => {
    const counts = trips.reduce((acc, trip) => {
      const date = new Date(trip.startTime);
      const month = date.toLocaleString('en-US', { month: 'short' });
      acc[month] = (acc[month] || 0) + trip.distance;
      return acc;
    }, {});

    const months = getLastSixMonths();
    const tripDistances = months.map(month => Math.round(counts[month] || 0));

    return {
      labels: months,
      datasets: [
        {
          label: t('Trip Distance per Month (Last 6 Months)'),
          data: tripDistances,
          fill: false,
          backgroundColor: 'rgba(153,102,255,0.4)',
          borderColor: 'rgba(153,102,255,1)',
          tension: 0.4,
          borderWidth: 2,
        },
      ],
    };
  };

  const processTotalTrips = () => {
    const counts = trips.reduce((acc, trip) => {
      const date = new Date(trip.startTime);
      const month = date.toLocaleString('en-US', { month: 'short' });
      acc[month] = (acc[month] || 0) + 1;
      return acc;
    }, {});

    const months = getLastSixMonths();
    const totalTrips = months.map(month => counts[month] || 0);

    return {
      labels: months,
      datasets: [
        {
          label: t('Total Trips Over Time'),
          data: totalTrips,
          fill: false,
          backgroundColor: 'rgba(255,159,64,0.2)',
          borderColor: 'rgba(255,159,64,1)',
          tension: 0.4,
        },
      ],
    };
  };

  const chartOptions = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: false,
        grid: {
          display: false,
        },
        ticks: {
          precision: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="container-fluid fleet-module">
      <div className="row">
        <div className="col-12">
          <h3>{t("General Overview")}</h3>
          <h4>{t("Location and Activity")}</h4>
        </div>
      </div>
      <div className="row stats-row">
        <div className="col-md-3">
          <div className="stat-icon white">
            <div className="img-container">
              <img src="../imgs/icons/icon-bike.svg" alt={t("Bike Trips")} />
            </div>
            <span>
              {bikeLocations.length} {t("e-Bikes")}<br/>
              <span>
                {t("In my fleet")}
              </span>
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="stat-icon white">
            <div className="img-container">
              <img src="../imgs/icons/icon-flag.svg" alt={t("Stores")} />
            </div>
            <span>
              {stores.length} {t("stores")}<br/>
              <span>
                {t("In my brand")}
              </span>
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="stat-icon white">
            <div className="img-container">
              <img src="../imgs/icons/icon-route.svg" alt={t("Total Trip Distance")} />
            </div>
            <span>
              {totalTripDistance} {t("km")}<br/>
              <span>
                {t("Total trip distance")}
              </span>
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="stat-icon white">
            <div className="img-container">
              <img src="../imgs/icons/icon-map.svg" alt={t("Total Trips")} />
            </div>
            <span>
              {totalTrips} {t("trips")}<br/>
              <span>
                {t("Total trips")}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2 className="text-secondary-color mb-4">{t("Fleet Maps")}</h2>
        </div>
        <div className="col-md-6">
          <h3 className="mb-3">{t("Location of Trips")}</h3>
          <MapContainer style={{ height: "300px", width: "100%" }} center={[0, 0]} zoom={2}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <HeatmapLayer points={tripHeatmapData} />
            <MapBounds points={tripHeatmapData} />
          </MapContainer>
        </div>
        <div className="col-md-6">
          <h3 className="mb-3">{t("Location of Bikes")}</h3>
          <MapContainer style={{ height: "300px", width: "100%" }} center={[0, 0]} zoom={2}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <HeatmapLayer points={bikeHeatmapData} />
            <MapBounds points={bikeHeatmapData} />
          </MapContainer>
        </div>
        <div className="col-12">
          <h3 className="mt-5">{t("Total Trip Distance Over Time (Last 6 Months)")}</h3>
          {trips.length > 0 ? (
            <Line
              data={processTotalTripDistance()}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
              style={{ maxHeight: '300px' }}
            />
          ) : (
            <p>{t("No trip data available.")}</p>
          )}
        </div>
        <div className="col-6">
          <h3 className="mt-5">{t("Trip Distance per Month")} <span>{t("(Last 6 Months)")}</span></h3>
          {trips.length > 0 ? (
            <div className="chart-container" style={{ height: '300px', width: '100%' }}>
              <Line data={processTripDistancePerDay()} options={chartOptions} />
            </div>
          ) : (
            <p>{t("No trip data available.")}</p>
          )}
        </div>
        <div className="col-6">
          <h3 className="mt-5">{t("Total Trip Length per Month (km)")} <span>{t("(Last 6 Months)")}</span></h3>
          {trips.length > 0 ? (
            <div className="chart-container" style={{ height: '300px', width: '100%' }}>
              <Line data={processTotalTrips()} options={chartOptions} />
            </div>
          ) : (
            <p>{t("No trip data available.")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const HeatmapLayer = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const heatLayer = L.heatLayer(points, {
      radius: 25,
      blur: 15,
      maxZoom: 17,
      minOpacity: 0.5,
      max: 1.0,
    }).addTo(map);

    return () => {
      map.removeLayer(heatLayer);
    };
  }, [map, points]);

  return null;
};

const MapBounds = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    // Ensure valid points only
    const validPoints = points.filter(
      point => point[0] !== undefined && point[1] !== undefined
    );

    if (validPoints.length > 0) {
      const bounds = L.latLngBounds(validPoints.map(point => [point[0], point[1]]));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [points, map]);

  return null;
};

export default BikesComponent;
