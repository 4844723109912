import React from 'react';
import useDiagnostics from './../../hooks/useDiagnostics.hook';
import RemoteDiagnosticsComponent from './remoteDiagnostics.component.js';

const RunRemoteDiagnostics = (props) => {
  const remoteDiagnosticsProps = useDiagnostics(props);
  return <RemoteDiagnosticsComponent {...remoteDiagnosticsProps} />;
};

export default RunRemoteDiagnostics;
