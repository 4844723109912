import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import useContent from "../hooks/useContent.hook";
import RestAPIService from "../services/rest-api.service";
import AnalyticsService from "../services/analytics.service";
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import Debug from "../services/debug.js";
import { useTranslation } from 'react-i18next';

function CustomToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className={`btn btn-small btn-secondary ${isCurrentEventKey ? "open" : ""}`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const BikeComponent = ({
  isConnected,
  isPreflightChecked,
  errorCode,
  bikeSerial,
  getBikeBrand,
  getBikeModel,
  setCurrentBikeError,
  getErrorCodeByBLE,
  setCurrentSolution,
  setBikeError,
  getDebugState,
}) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [currentError, setCurrentError] = useState(null);
  const [hasError, setHasError] = useState(true);
  const [errorDocs, setErrorDocs] = useState(false);
  const [batterySerial, setBatterySerial] = useState("");
  const [ftexSerial, setFtexSerial] = useState("");
  const [brandSerial, setBrandSerial] = useState("");
  const [bikeBrand, setBikeBrand] = useState("");
  const [bikeModel, setBikeModel] = useState("");
  const [bikeYear, setBikeYear] = useState("");
  const {
    errorCodeDocumentation,
  } = useContent();

  const debugMode = getDebugState()

  const showSolution = (solution, index) => {
    setCurrentSolution({
      solution: solution,
      index: index
    });
    navigate('/bike/solution/' + index);
  }

  useEffect(() => {
    if (!isConnected && !isPreflightChecked) {
      navigate('/bleConnect');
    }

    if (isConnected && !isPreflightChecked) {
      navigate('/bluetoothPreflight');
    }

    // setCurrentError(errorCode);
    Debug.logger(1, 'Set current error => ' + currentError);

    if (currentError && currentError > 0) {
      let error = "Error Code: " + currentError;
      let comment = "Error detect via bluetooth in diagnostics tool";
      let params = {
        type: "error",
        error: currentError,
      }
      AnalyticsService.addRecord(bikeSerial, error, comment, params);
      setHasError(true);
    }

    Debug.logger(1, 'Fetched error documentation from wordpress', errorCodeDocumentation);
    if (errorCodeDocumentation) {
      const selectedBikeModel = "R48i 2024";
    
      if (errorCodeDocumentation[selectedBikeModel]) {
        // Get the error documentation for the selected bike model
        const bikeErrorDocs = errorCodeDocumentation[selectedBikeModel];
        setErrorDocs(bikeErrorDocs);
      }
    } else {
      setErrorDocs(false);
    }

  }, [isConnected, isPreflightChecked, navigate, bikeModel, errorCodeDocumentation, errorCode]);

  useEffect(() => {
    const fetchBikeInfo = async () => {
      try {
        let bikeInfo = await RestAPIService.getBike(bikeSerial);
        Debug.logger(2, 'bikeInfo' ,bikeInfo)
        setBatterySerial(bikeInfo.battery_serial);
        setFtexSerial(bikeInfo.ftex_serial);
        setBikeBrand(bikeInfo.brand);
        setBrandSerial(bikeInfo.bike_serial);
        setBikeModel(bikeInfo.model);
        setBikeYear(bikeInfo.year);
      } catch (error) {
        Debug.logger(2, 'Error fetching bike info from Rest API Server', error);
        // Handle the error, maybe redirect the user or show an error message
      }
    };

    if (bikeSerial) {
      Debug.logger(2, 'bikeSerial IF YES', bikeSerial);
      fetchBikeInfo();
    } else {
      Debug.logger(2, 'bikeSerial IF NO', bikeSerial);
    }
  }, [bikeSerial]);

  // useEffect(() => {
  //   console.log('bike cokponent calling getErrorCodeByBLE')
  // }, [hasError]);

  useEffect(() => {
    async function updateErrors() {
      Debug.logger(1, "updateErrors calls");
      let errors = await getErrorCodeByBLE();
      if (errors && errors.length) {
        const errorCode = errors[0].code;
        if (errorCode > 0) {
          setCurrentError(errors[0].code);
          setCurrentBikeError(errors[0].code);
          setHasError(true);
        }
      }
    }

    // Call the function inside useEffect to avoid infinite loop
    updateErrors();
  }, [errorCode]); // Add getErrorCodeByBLE as a dependency if it's stable, otherwise consider other dependencies


  function formatIndex(index) {
    return index < 10 ? `0${index}` : index.toString();
  }

  return (
    <div className="container-fluid bike-wrapper">
      <div className="row justify-content-center">
        {bikeBrand && bikeModel && bikeYear ? (
          <>
            <div className="col-md-11">
              <header>
                <h2>
                  {bikeBrand} {bikeModel} ({bikeYear})- &nbsp;
                </h2>
                <h3>
                   {brandSerial}
                </h3>
                <span>{t("Battery")}: <span>{batterySerial}</span></span>
                <span>{t("Controller")}: <span>{ftexSerial}</span></span>
                <hr/>
              </header>
            </div>

            {hasError && currentError && errorDocs ? (
              <>
                <div className="col-md-11">
                  <Accordion>
                    <Card>
                      <Card.Header>
                        <h2>
                          {t("Error Code")} : <span>{formatIndex(currentError)}</span>
                          <CustomToggle eventKey="0">{t("Discover")}</CustomToggle>
                        </h2>
                        <hr/>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {errorDocs[currentError] && (
                            <>
                              <h4>{t("Most likely reason for this error")}</h4>
                              <hr/>
                              <h5>{errorDocs[currentError].title}</h5>
                              <p>
                                {errorDocs[currentError].description}
                              </p>
                              <hr/>
                              <h4>{t("How to solve this error?")}</h4>
                              <hr/>
                              {errorDocs[currentError].solutions.length === 1
                                ? (
                                  // If there is only one solution, show "View Solution"
                                  <button
                                    className="solution btn-block btn btn-large btn-primary"
                                    onClick={() => showSolution(errorDocs[currentError].solutions[0], 1)}
                                  >
                                    {t("View Solution")}
                                  </button>
                                ) : (
                                  // If there are multiple solutions, number them
                                  errorDocs[currentError].solutions.map((solution, index) => (
                                    <button
                                      key={index}
                                      className="solution btn-block btn btn-large btn-primary"
                                      onClick={() => showSolution(solution, index + 1)}
                                    >
                                      {t("Solution")} {index + 1}
                                    </button>
                                  ))
                                )
                              }
                            </>
                          )}
                          {!errorDocs[currentError] && (
                            <>
                              <h4>{t("Unknown Resolution for this Error")}</h4>
                            </>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </>
            ) : (
              <div className="col-md-11">
                <header>
                  <h2>{t("No Current Errors Detected on this Bike!")}</h2>
                </header>
              </div>
            )}
          </>
        ) : (
          <div className="col-md-11">
            <header>
              <h2>{t("Bike with FTEX serial number")} {bikeSerial} {t("not found")}</h2>
            </header>
          </div>
        )}

        {errorDocs.length > 0 && (
          <div className="col-md-11 error-code-list">
            <Accordion>
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="1">
                    <h4>{t("Guide of Possible Error Codes")}</h4>
                    <span className="active">
                      +
                    </span>
                    <span className="inactive">
                      -
                    </span>
                  </CustomToggle>
                  <hr/>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    {errorDocs.map((errorDoc, index) => (
                      errorDoc.title && errorDoc.description ? (
                        <div key={index}>
                          <p className="error-code">{t("Error Code")} <span>{formatIndex(index)}</span></p>
                          <p>{errorDoc.title}</p>
                          {debugMode && (  // Conditional rendering based on the debugBLE flag
                            <>
                              {errorDoc.solutions.length === 1
                                ? (
                                  // If there is only one solution, show "View Solution"
                                  <button
                                    className="solution btn-block btn btn-large btn-primary"
                                    onClick={() => showSolution(errorDoc.solutions[0], 1)}
                                  >
                                    {t("View Solution")}
                                  </button>
                                ) : (
                                  // If there are multiple solutions, number them
                                  errorDoc.solutions.map((solution, index) => (
                                    <button
                                      key={index}
                                      className="solution btn-block btn btn-large btn-primary"
                                      onClick={() => showSolution(solution, index + 1)}
                                    >
                                      {t("Solution")} {index + 1}
                                    </button>
                                  ))
                                )
                              }
                              <hr/>
                            </>
                          )}
                        </div>
                      ) : null
                    ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
};

export default BikeComponent;