import { useState } from 'react';

const useLoadingScreen = () => {
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const setLoadingScreen = (value) => {
    setIsLoadingScreen(value);
  };

  const isLoading = () => {
    return isLoadingScreen;
  };

  const loadingScreenError = (msg) => {
    setErrorMsg(msg);
  }

  const resetLoadingScreenError = () => {
    setErrorMsg("");
  }

  return {
    isLoading,
    setLoadingScreen,
    loadingScreenError,
    resetLoadingScreenError,
    errorMsg,
  };
};

export default useLoadingScreen;