import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const Resources = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
  }, [currentLanguage]);

  return (
    <div className={`container-fluid resources-wrapper ${currentLanguage}`}>
      <div className="row justify-content-center">
        <div className="col-md-11">
          <header>
            <h2>{t('Additional Resources')}</h2>
            <hr/>
          </header>
        </div>
        <div className="col-md-11">
          <ul className="additional-resources row">
            <li className="col-sm-6 col-md-4 col-lg-3" data-language="fr">
              <Link to="http://diagnostics-wp.ftex.ca/wp-content/uploads/2024/06/autorq-bb-fr.pdf" target="_blank" rel="noopener noreferrer">
                <span>Pedalier Autorq</span>
                <p>Comment installer un jeu de pedalier Autorq</p>
                <img src="/imgs/icons/icon-download.svg" alt="Download" />
              </Link>
            </li>
            <li className="col-sm-6 col-md-4 col-lg-3" data-language="en">
              <Link to="http://diagnostics-wp.ftex.ca/wp-content/uploads/2024/06/autorq-bb-en.pdf" target="_blank" rel="noopener noreferrer">
                <span>Autorq Sensor</span>
                <p>How to Install an Autorq PAS sensor</p>
                <img src="/imgs/icons/icon-download.svg" alt="Download" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Resources;
