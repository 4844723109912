import axios from "axios";
import Debug from "./debug.js";
import RestAPIService from "./rest-api.service";
 

//const API_URL = "http://localhost:5050/";
const API_URL = "https://ftex-backend-73e39a9f925f.herokuapp.com/";

class AnalyticsService {
  addRecord(bikeSerial, problem, comment, params) {
    let recordObject = {
      type: "Auto",
      source: "FTEX Diagnostics Tool Analytics",
      problem: problem,
      comment: comment,
      params: params,
    }

    RestAPIService.addBikeRecord(bikeSerial, recordObject).then(
      (response) => {
        return response;
      },
      (error) => {
        Debug.logger(1, 'error adding new analytics record', error)
        return error;
      }
    );
  }

  track(action, brand, params) {
    let recordObject = {
      type: "Auto",
      source: "FTEX Diagnostics Tool Analytics",
      params: params,
    }

    RestAPIService.addRecord(action, brand, recordObject).then(
      (response) => {
        return response;
      },
      (error) => {
        Debug.logger(1, 'error adding new analytics record', error)
        return error;
      }
    );
  }
}

export default new AnalyticsService();