import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import RestAPIService from "../services/rest-api.service";
import AnalyticsService from "../services/analytics.service";
import { useTranslation } from 'react-i18next';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Debug from "../services/debug.js";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const BikeUpdateComponent = ({
  isConnected,
  isPreflightChecked,
  bikeSerial,
  getBikeBrand,
  getBikeModel,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [batterySerial, setBatterySerial] = useState("");
  const [newBatterySerial, setNewBatterySerial] = useState("");
  const [newFtexSerial, setNewFtexSerial] = useState("");
  const [ftexSerial, setFtexSerial] = useState("");
  const [brandSerial, setBrandSerial] = useState("");
  const [bikeBrand, setBikeBrand] = useState("");
  const [bikeModel, setBikeModel] = useState("");
  const [bikeYear, setBikeYear] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!isConnected && !isPreflightChecked) {
      navigate('/bleConnect');
    }

    if (isConnected && !isPreflightChecked) {
      navigate('/bluetoothPreflight');
    }
  }, [isConnected, isPreflightChecked, navigate, bikeModel]);

  const fetchBikeInfo = async () => {
    try {
      let bikeInfo = await RestAPIService.getBike(bikeSerial);
      Debug.logger(2, 'bikeInfo', bikeInfo);
      setBatterySerial(bikeInfo.battery_serial);
      setFtexSerial(bikeInfo.ftex_serial);
      setBikeBrand(bikeInfo.brand);
      setBrandSerial(bikeInfo.bike_serial);
      setBikeModel(bikeInfo.model);
      setBikeYear(bikeInfo.year);
    } catch (error) {
      Debug.logger(2, 'Error fetching bike info from Rest API Server', error);
    }
  };

  useEffect(() => {
    fetchBikeInfo();

    if (bikeSerial) {
      Debug.logger(2, 'bikeSerial IF YES', bikeSerial);
      fetchBikeInfo();
    } else {
      Debug.logger(2, 'bikeSerial IF NO', bikeSerial);
    }
  }, [bikeSerial]);

  const handleBatterySerialChange = async (e) => {
    e.preventDefault();

    setMessage("");
    if (!bikeSerial || !newBatterySerial) {
      return setMessage("Error updating battery serial number");
    }

    Debug.logger(1, 'Updating battery serial', { bikeSerial: brandSerial, newBatterySerial });
    RestAPIService.updateBikeBattery(brandSerial, newBatterySerial)
      .then((response) => {
        Debug.logger(1, 'Battery update response', response);
        if (response instanceof Error || response?.status >= 400) {
          const resMessage =
            response.response?.data?.message ||
            response.message ||
            response.toString();

          if (response.response?.status === 409) {
            setShowModal(true);
          } else {
            setMessage(resMessage);
          }
          return;
        }

        let error = "Battery Serial Change";
        let comment = "Battery Serial Number has been updated to " + newBatterySerial;
        let params = {
          type: "serial"
        };
        AnalyticsService.addRecord(brandSerial, error, comment, params);
        setNewBatterySerial("");
        fetchBikeInfo();
      })
      .catch((error) => {
        Debug.logger(1, 'Battery update error', error);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 409) {
          setShowModal(true);
        } else {
          setMessage(resMessage);
        }
      });
  };

  const handleForceRemoveBattery = async () => {
    try {
      Debug.logger(1, 'Forcing battery removal', { newBatterySerial });
      await RestAPIService.removeBatteryFromBike(newBatterySerial);
      await RestAPIService.updateBikeBattery(brandSerial, newBatterySerial);
      let error = "Battery Serial Change";
      let comment = "Battery Serial Number has been updated to " + newBatterySerial;
      let params = {
        type: "serial"
      };
      AnalyticsService.addRecord(brandSerial, error, comment, params);
      setNewBatterySerial("");
      fetchBikeInfo();
      setShowModal(false);
    } catch (error) {
      Debug.logger(1, 'Error forcing battery update', error);
      setMessage("Error forcing battery update: " + error.toString());
    }
  };

  const onChangeBatterySerial = (e) => {
    setNewBatterySerial(e.target.value);
  };

  const handleControllerSerialChange = (e) => {
    e.preventDefault();

    setMessage2("");
    if (!bikeSerial || !newFtexSerial) {
      return setMessage("Error updating controller serial number");
    }
    RestAPIService.updateBikeController(brandSerial, newFtexSerial).then(
      () => {
        let error = "Controller Serial Change";
        let comment = "Controller Serial Number has been updated to " + newFtexSerial;
        let params = {
          type: "serial"
        };
        AnalyticsService.addRecord(brandSerial, error, comment, params);
        setNewFtexSerial("");
        fetchBikeInfo();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage2(resMessage);
      }
    );
  };

  const onChangeControllerSerial = (e) => {
    setNewFtexSerial(e.target.value);
  };

  return (
    <div className="container-fluid history-wrapper">
      <div className="row justify-content-center">
        {bikeBrand && bikeModel && bikeYear && (
          <div className="col-md-11">
            <header>
              <h2>{bikeBrand} {bikeModel} ({bikeYear})- &nbsp;</h2>
              <h3>{bikeSerial}</h3>
              <span>{t("Battery")}: <span>{batterySerial}</span></span>
              <span>{t("Controller")}: <span>{ftexSerial}</span></span>
              <hr/>
            </header>
          </div>
        )}
        <div className="col-md-11">
          <h4>{t("Change Battery")}</h4>
          <hr/>
          <Form onSubmit={handleBatterySerialChange}>
            <div className="form-group">
              <label htmlFor="batterySerial">{t('New Battery Serial')}</label>
              <Input
                type="text"
                className="form-control"
                name="batterySerial"
                placeholder={t('Battery Serial Number')}
                value={newBatterySerial}
                onChange={onChangeBatterySerial}
                validations={[required]}
              />
            </div>
            <div className="form-group">
              <button className="btn btn-primary btn-big">
                <span>{t('Change Battery Serial')}</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Battery Conflict')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('The battery serial number is already assigned to another bike. Do you want to force remove it from the other bike and assign it to this one?')}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleForceRemoveBattery}>
            {t('Force Update')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BikeUpdateComponent;
