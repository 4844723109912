import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BatteryComponent = ({
  isConnected,
  isPreflightChecked,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isConnected && !isPreflightChecked) {
      console.log('battery redirect', isConnected, isPreflightChecked)
      navigate('/bleConnect');
    }

    if (isConnected && !isPreflightChecked) {
      console.log('battery redirect 2', isConnected, isPreflightChecked)
      navigate('/bluetoothPreflight');
    }
  }, [isConnected, isPreflightChecked, navigate]);

  return (
    <div className="container">
      <h1>Battery Diagnostics</h1>
      {/* Your Battery diagnostics content here */}
    </div>
  );
};

export default BatteryComponent;