import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Debug from "../services/debug.js";
import { useTranslation } from 'react-i18next';

const BluetoothPreflight = ({
  checkBluetoothConnection,
  userGroup,
  getFirmwareVersion,
  checkLTEConnection,
  checkGPSFunction,
  checkBatteryVoltage,
  isConnected,
  preFlightComplete,
  resetPreFlightComplete,
  errorCode,
  setIsBikeError,
  setIsIotError,
  setIsBatteryError,
  resetBikeErrors,
}) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [bluetoothChecked, setBluetoothChecked] = useState("unchecked");
  const [versionChecked, setVersionChecked] = useState("unchecked");
  const [lteChecked, setLteChecked] = useState("unchecked");
  const [gpsChecked, setGpsChecked] = useState("unchecked");
  const [batteryChecked, setBatteryChecked] = useState("unchecked");
  const [checkComplete, setCheckComplete] = useState(false);

  useEffect(() => {
    if (!isConnected) {
      resetPreFlightComplete();
      resetBikeErrors();
      navigate('/bleConnect');
    }
  }, [isConnected, navigate]);

  useEffect(() => {
    if (!isConnected) {
      navigate('/bleConnect');
    } else {
      // Check Bluetooth connection
      resetPreFlightComplete();
      resetBikeErrors();

      setBluetoothChecked("checking");
      let isBluetoothConnected = checkBluetoothConnection();
      if (isBluetoothConnected) {
        setBluetoothChecked("pass");

        setCheckComplete(true);
        setTimeout(() => {
          preFlightComplete(); // Mark pre-flight as complete
          if (userGroup === "Assembler") {
            navigate('/newBike'); // Redirect to the next page
          } else {
            navigate('/bike'); // Redirect to the next page
          }
        }, 2000); // Redirect after 2 seconds
      } else {
        setBluetoothChecked("fail");
      }
    }
  }, [isConnected, navigate]);

  // useEffect(() => {
  //   let timeout;

  //   if (!isConnected) {
  //     resetPreFlightComplete();
  //     resetBikeErrors();
  //     navigate('/bleConnect');
  //   }
 
  //   const runChecks = async () => {
  //     try {
  //       //
  //       const redirect = () => {
  //         setCheckComlete(true);
  //         // timeout = setTimeout(() => {
  //         //   if (!checkBluetoothConnection()) {
  //         //     navigate('/bleConnect');
  //         //     return;
  //         //   }
  //         //   preFlightComplete();
  //         //   //navigate('/bike');
  //         // }, 2000);
  //       }

  //       // Check Bluetooth connection
  //       resetPreFlightComplete();
  //       resetBikeErrors();

  //       setBluetoothChecked("checking");
  //       let isBluetoothConnected = checkBluetoothConnection();
  //       if (isBluetoothConnected) {
  //         setBluetoothChecked("pass");
  //       } else {
  //         setBluetoothChecked("fail");
  //       }

  //       if (!isBluetoothConnected) {
  //         navigate('/bleConnect');
  //         return;
  //       } else {
  //         redirect()
  //       }

  //       // function checkVersion() {

  //       //   let currentVersion = getFirmwareVersion();
  //       //   console.log('currentVersion', currentVersion);

  //       // }

  //       // // Check LTE connection
  //       // setLteChecked("checking");
  //       // const isLteConnected = await checkLTEConnection();
  //       // if (isLteConnected) {
  //       //   setLteChecked("pass");
  //       // } else {
  //       //   setLteChecked("fail");
  //       //   setIsIotError(true);
  //       // }
  //       // console.log('preflight isLteConnected', isLteConnected);
  //       // console.log('isBluetoothConnected 2', isBluetoothConnected)

  //       // // Check GPS function
  //       // setGpsChecked("checking");
  //       // const isGpsFunctioning = await checkGPSFunction();
  //       // if (isGpsFunctioning) {
  //       //   setGpsChecked("pass");
  //       // } else {
  //       //   setGpsChecked("fail");
  //       //   setIsIotError(true);
  //       // }
  //       // console.log('preflight isGpsFunctioning', isGpsFunctioning);          
  //       // console.log('isBluetoothConnected 3', isBluetoothConnected)

  //       // // Check battery voltage
  //       // setBatteryChecked("checking");
  //       // const batteryVoltage = checkBatteryVoltage();
  //       // if (batteryVoltage >= 36) {
  //       //   setBatteryChecked("pass");
  //       // } else {
  //       //   setBatteryChecked("fail");
  //       //   setIsBatteryError(true);
  //       // }

  //       // console.log('preflight batteryVoltage', batteryVoltage);

  //       // if (batteryVoltage < 36) {
  //       //   console.error('Battery voltage is too low');
  //       // }

  //       // //before redirect, fetch error if one exists and raise flag
  //       // if (errorCode) {
  //       //   setIsBikeError(true);
  //       // }

  //         // timeout = setTimeout(() => {
  //         //   console.log('checkBluetoothConnection()', checkBluetoothConnection());
  //         //   if (!checkBluetoothConnection()) {
  //         //     navigate('/bleConnect');
  //         //     return;
  //         //   }
  //         //   preFlightComplete();
  //         //   if (!isLteConnected || !isGpsFunctioning) {
  //         //     console.log('iot preflight fail', isBluetoothConnected, isLteConnected, isGpsFunctioning);
  //         //     navigate('/iot');
  //         //   } else if (batteryVoltage < 36) {
  //         //     navigate('/battery');
  //         //   } else {
  //         //     navigate('/bike');
  //         //   }
  //         // }, 2000);



  //     } catch (error) {
  //       console.error('Error during checks:', error);
  //     }
  //   };
  //   runChecks();

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [navigate, isConnected]);


  return (
    <div className="container-fluid preflight-wrapper text-center">
      <div className="row justify-content-center">
        <div className="col-md-12">
          {userGroup === "Velec" ? (
            <img src="../imgs/clients/velec/logo-white.avif" alt="Velec E-Bikes" />
          ) : userGroup === "Bike" ? (
            <img src="../imgs/clients/bike/logo.avif" alt="Bike" />
          ) : (
            <img src="../imgs/ftex-logo-color.png" alt="FTEX" />
          )}
          <div className="preflight-items">
            <div className={`card card-container ${bluetoothChecked}`}>
              <strong>{t("Bluetooth")}</strong>
              <img src="../imgs/bluetooth-icon-light.png" alt="Bluetooth" />
              <span className="check-light"></span>
              {bluetoothChecked === "pass" && (
                <span className="check-status">{t("OK")}</span>
              )}
              {bluetoothChecked === "fail" && (
                <span className="check-status">{t("Problem Detected")}</span>
              )}
            </div>
{/*          <div className={`card card-container ${lteChecked}`}>
              <strong>LTE</strong>
              <img src="../imgs/lte-icon.png" alt="LTE" />
              <span className="check-light"></span>
              {lteChecked === "pass" && (
                <span className="check-status">OK</span>
              )}
              {lteChecked === "fail" && (
                <span className="check-status">Problem Detected</span>
              )}
            </div>*/}
{/*            <div className={`card card-container ${gpsChecked}`}>
              <strong>GPS</strong>
              <img src="../imgs/gps-icon.png" alt="GPS" />
              <span className="check-light"></span>
              {gpsChecked === "pass" && (
                <span className="check-status">OK</span>
              )}
              {gpsChecked === "fail" && (
                <span className="check-status">Problem Detected</span>
              )}
            </div>
            <div className={`card card-container ${batteryChecked}`}>
              <strong>Battery</strong>
              <img src="../imgs/battery-icon.png" alt="Battery" />
              <span className="check-light"></span>
              {batteryChecked === "pass" && (
                <span className="check-status">OK</span>
              )}
              {batteryChecked === "fail" && (
                <span className="check-status">Problem Detected</span>
              )}
            </div>*/}
  {/*          <div className={`card card-container ${versionChecked}`}>
              <strong>Version</strong>
              <img src="../imgs/bluetooth-icon-light.png" alt="Version" />
              <span className="check-light"></span>
              {versionChecked === "pass" && (
                <span className="check-status">OK</span>
              )}
              {versionChecked === "fail" && (
                <span className="check-status">Problem Detected</span>
              )}
            </div>*/}



          </div>
        </div>
        <div className="col-md-12">
          {checkComplete ? (
            <h4 className="connected fade-in">{t("Connection Succesful...")}</h4>
          ) : (
            <h4>{t("Checking Bluetooth Connection...")}</h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default BluetoothPreflight;