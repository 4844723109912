import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import SideMenu from './sidemenu.component';
import HomeComponent from './home.component';
import GuidesComponent from './guides.component';
import ResourcesComponent from './resources.component';
import ViewSolutionComponent from './viewSolution.component';
import BikeComponent from './bike.component';
import SolutionComponent from './solution.component';
import MaintenanceComponent from './maintenance.component';
import RunBattery from './RunBattery.component';
import RunDiagnostics from './RunDiagnostics.component';
import RunMaintenance from './RunMaintenance.component';
import RemoteConnection from './remoteConnection.component';

const DiagnosticsComponent = ({ 
  userGroup,
  isConnected,
  setUserGroup,
  currentUser,
  setCurrentUser,
  connectToDevice,
  setLoadingScreen,
  disconnectFromDevice,
  getErrorCodeByBLE,
  bikeSerial,
  debugClearErrorFlag,
  debugClearError,
  stopNotificationListener,
  startNotificationListener,
  writePassthroughCharacteristic,
  primaryService,
  verifyPin,
  setPinVerified,
  sendPassthroughRead,
  sendPassthroughWrite,
  getSendPassthroughRW,
}) => {
  useEffect(() => {

  }, [userGroup]);

  useEffect(() => {

  }, [isConnected]);

  return (
    <div className="container-fluid container-diagnostics-module">
      <div className="row">
        <div className="col-md-2">
          <SideMenu userGroup={userGroup} isConnected={isConnected} disconnectFromDevice={disconnectFromDevice} />
        </div>
        <div className="col-md-10 main-content h-100">
          <Routes>
            <Route 
              path="/" 
              element={
              <HomeComponent 
                userGroup={userGroup}
                isConnected={isConnected}
                setUserGroup={setUserGroup}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                connectToDevice={connectToDevice}
                setLoadingScreen={setLoadingScreen}
                bikeSerial={bikeSerial}
                getErrorCodeByBLE={getErrorCodeByBLE}
                disconnectFromDevice={disconnectFromDevice}
              />}
            />
            <Route 
              path="/maintenance"
              element={
              <RunMaintenance
                bikeSerial={bikeSerial} 
                stopNotificationListener={stopNotificationListener}
                startNotificationListener={startNotificationListener}
                writePassthroughCharacteristic={writePassthroughCharacteristic}
                primaryService={primaryService}
                verifyPin={verifyPin}
                sendPassthroughRead={sendPassthroughRead}
                sendPassthroughWrite={sendPassthroughWrite}
                getSendPassthroughRW={getSendPassthroughRW}
                isConnected={isConnected}
              />} 
            />
            <Route 
              path="/battery"
              element={
              <RunBattery
                bikeSerial={bikeSerial} 
                stopNotificationListener={stopNotificationListener}
                startNotificationListener={startNotificationListener}
                writePassthroughCharacteristic={writePassthroughCharacteristic}
                primaryService={primaryService}
                verifyPin={verifyPin}
                sendPassthroughRead={sendPassthroughRead}
                sendPassthroughWrite={sendPassthroughWrite}
                getSendPassthroughRW={getSendPassthroughRW}
                isConnected={isConnected}
              />} 
            />
            <Route 
              path="/bike/solution" 
              element={
                <SolutionComponent 
                  debugClearErrorFlag={debugClearErrorFlag} 
                />
              } 
            />
            <Route 
              path="/bike" 
              element={
                <BikeComponent 
                  bikeSerial={bikeSerial}
                  debugClearError={debugClearError}
                  isConnected={isConnected}
                  getErrorCodeByBLE={getErrorCodeByBLE}
                />
              } 
            />
            <Route path="/guides" element={<GuidesComponent userGroup={userGroup}/>} />
            <Route path="/guides/viewSolution" element={<ViewSolutionComponent />} />
            <Route path="/resources" element={<ResourcesComponent />} />
            <Route path="/remote" element={<RemoteConnection />} />
            <Route 
              path="/run"
              element={
              <RunDiagnostics
                stopNotificationListener={stopNotificationListener}
                startNotificationListener={startNotificationListener}
                writePassthroughCharacteristic={writePassthroughCharacteristic}
                primaryService={primaryService}
                verifyPin={verifyPin}
                sendPassthroughRead={sendPassthroughRead}
                sendPassthroughWrite={sendPassthroughWrite}
                getSendPassthroughRW={getSendPassthroughRW}
                isConnected={isConnected}
              />} 
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default DiagnosticsComponent;
