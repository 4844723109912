import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';

// Import test components
import Intro from './tests/intro.component';
import PASTest from './tests/PAS.component';
import CadenceTest from './tests/cadence.component';
import TorqueTest from './tests/torque.component';
import LeftBrakeTest from './tests/leftBrake.component';
import RightBrakeTest from './tests/rightBrake.component';
import ThrottleTest from './tests/throttle.component';
import FrontLightTest from './tests/frontLight.component';
import RearLightTest from './tests/rearLight.component';
import SpeedMotorTest from './tests/speedMotor.component';

// Map test names to components
const testsMap = {
  intro: Intro,
  PAS: PASTest,
  leftBrake: LeftBrakeTest,
  rightBrake: RightBrakeTest,
  // cadence: CadenceTest,
  // torque: TorqueTest,
  throttle: ThrottleTest,
  // frontLight: FrontLightTest,
  rearLight: RearLightTest,
  speedMotor: SpeedMotorTest,
};

const BikeTestMain = ({
  show,
  setDiagnosticsResults,
  diagnosticsResults,
  compKey,
  onHide,
  primaryService,
  pin,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener,
  tests,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);
  const instanceId = useRef(`instance-${Math.random().toString(36).substr(2, 9)}`);
  const testListRef = useRef(null);
  const [testItemHeight, setTestItemHeight] = useState(0);

  useEffect(() => {
    console.log(`BikeTestMain mounted with instance ID: ${instanceId.current}`);

    return () => {
      console.log(`BikeTestMain unmounted with instance ID: ${instanceId.current}`);
    };
  }, []);

  useEffect(() => {
    console.log("BikeTestMain diagnosticsResults: ", diagnosticsResults);
  }, [diagnosticsResults]);

  useEffect(() => {
    console.log('KEY', compKey);
    if (show) {
      setCurrentStep(0);
      setIsInitialized(true); 
    } else {
      setIsInitialized(false);
    }
  }, [show]);

  useEffect(() => {
    console.log('restListRef');
    if (testListRef.current) {
      const testItem = testListRef.current.querySelector('.test-item');
      const separator = testListRef.current.querySelector('.test-separator');
      let itemOffsetHeight = 0;
      let separatorHeight = 0;
      if (testItem) {
        itemOffsetHeight = testItem.offsetHeight;
      }
      if (separator) {
        separatorHeight = separator.clientHeight;
      }
      setTestItemHeight(itemOffsetHeight + separatorHeight);
    }
  }, [isInitialized]);

  const CurrentTestComponent = useMemo(() => testsMap[tests[currentStep]?.file], [tests, currentStep]);

  const handleResult = useCallback((result) => {
    setDiagnosticsResults((prevResults) => ({
      ...prevResults,
      [tests[currentStep].file]: result,
    }));

    if (currentStep < tests.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onHide();
    }
  }, [currentStep, tests, onHide]);

  const getTestClass = (index) => {
    if (index < currentStep) {
      return diagnosticsResults[tests[index]?.file]?.result ? 'success' : 'fail';
    } else if (index === currentStep) {
      return 'active';
    }
    return '';
  };

  const calculateMargin = () => {
    let margin = 0;
    for (let i = 0; i < currentStep; i++) {
      margin -= testItemHeight;
    }
    return margin;
  };

  return (
    <Modal show={show} onHide={onHide} size="full-screen" className="diagnostics-test-modal" backdrop="static" keyboard={false}>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 side-col">
              <div className="test-list-container">
                <div className="test-list" ref={testListRef} style={{ marginTop: calculateMargin() }}>
                  {tests.map((test, index) => (
                    <React.Fragment key={index}>
                      <div className={`test-item ${getTestClass(index)}`}>
                        <span className="test-index">0{index + 1}</span>
                        <span className="test-name">{test.name}</span>
                      </div>
                      {index < tests.length - 1 && (
                        <div className="test-separator">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-9 main-col">
              <div className="row">
                <div className="col-11 offset-1">
                  {isInitialized && CurrentTestComponent && show && (
                    <CurrentTestComponent
                      key={`${currentStep}-${compKey}`}
                      stepNumber={`${currentStep + 1}`}
                      onResult={handleResult}
                      primaryService={primaryService}
                      pin={pin}
                      getSendPassthroughRW={getSendPassthroughRW}
                      stopNotificationListener={stopNotificationListener}
                      startNotificationListener={startNotificationListener}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>      
      </Modal.Body>
      <Modal.Footer>
        <Button variant="btn btn-primary" onClick={onHide}>
          End Test
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BikeTestMain;
