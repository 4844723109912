import React, { useEffect, useState } from 'react';
import RestAPIService from '../../services/rest-api.service';
import { Line, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend);

const AppComponent = ({ userGroup }) => {
  const { t } = useTranslation();
  const [appInstalls, setAppInstalls] = useState([]);
  const [importedUsers, setImportedUsers] = useState([]);
  const [userRegionData, setUserRegionData] = useState(null);

  useEffect(() => {
    RestAPIService.fetchInstallations()
      .then(data => {
        console.log(t("Fetched app installations:"), data);
        setAppInstalls(data);
      })
      .catch(error => {
        console.error(t("Error fetching app installations:"), error);
      });

    RestAPIService.fetchImportedUsers(userGroup)
      .then(data => {
        console.log(t("Fetched imported users:"), data);
        setImportedUsers(data);
        processUserRegion(data);
      })
      .catch(error => {
        console.error(t("Error fetching imported users:"), error);
      });
  }, [userGroup, t]);

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  };

  const generateWeeklyLabels = (startDate, endDate) => {
    const weeks = [];
    let currentDate = dayjs(startDate).startOf('week');

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'week')) {
      weeks.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'week');
    }

    return weeks;
  };

  const processWeeklyCumulativeData = (data, label) => {
    const counts = {};
    const sixMonthsAgo = dayjs().subtract(6, 'months').startOf('month');
    const currentDate = dayjs();

    data.forEach(record => {
      if (isValidDate(record._created_at)) {
        const week = dayjs(record._created_at).startOf('week').format('YYYY-MM-DD');
        if (dayjs(week).isAfter(sixMonthsAgo)) {
          counts[week] = (counts[week] || 0) + 1;
        }
      }
    });

    const weeks = generateWeeklyLabels(sixMonthsAgo, currentDate);

    const cumulativeCounts = weeks.map((week, index) => {
      return weeks.slice(0, index + 1).reduce((sum, currentWeek) => sum + (counts[currentWeek] || 0), 0);
    });

    return {
      labels: weeks,
      datasets: [
        {
          label: t(label),
          data: cumulativeCounts,
          fill: false,
          backgroundColor: label === t('Total App Installs Over Time') ? 'rgba(54,162,235,0.4)' : 'rgba(153,102,255,0.4)',
          borderColor: label === t('Total App Installs Over Time') ? 'rgba(54,162,235,1)' : 'rgba(153,102,255,1)',
          tension: 0.4, // Smoothing the line
        },
      ],
    };
  };

  const processAppVersionDistribution = () => {
    const counts = appInstalls.reduce((acc, install) => {
      const version = install.appVersion || t("Unknown");
      acc[version] = (acc[version] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(counts);
    const data = Object.values(counts);

    return {
      labels,
      datasets: [
        {
          label: t('App Version Distribution'),
          data,
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#FF6384', '#36A2EB', '#FFCE56'
          ],
        },
      ],
    };
  };

  const processDeviceTypeDistribution = () => {
    const counts = appInstalls.reduce((acc, install) => {
      const deviceType = install.deviceType || t("Unknown");
      acc[deviceType] = (acc[deviceType] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(counts);
    const data = Object.values(counts);

    return {
      labels,
      datasets: [
        {
          label: t('Device Type Distribution'),
          data,
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#FF6384', '#36A2EB', '#FFCE56'
          ],
        },
      ],
    };
  };

  const processLocaleIdentifierDistribution = () => {
    const counts = appInstalls.reduce((acc, install) => {
      const locale = install.localeIdentifier ? install.localeIdentifier.substring(0, 2) : t("Unknown");
      acc[locale] = (acc[locale] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(counts);
    const data = Object.values(counts);

    return {
      labels,
      datasets: [
        {
          label: t('Locale Identifier Distribution'),
          data,
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#FF6384', '#36A2EB', '#FFCE56'
          ],
        },
      ],
    };
  };

  const processUserAgeGroup = () => {
    const ageGroups = {
      '0-17': 0,
      '18-24': 0,
      '25-34': 0,
      '35-44': 0,
      '45-54': 0,
      '55-64': 0,
      '65+': 0,
    };

    importedUsers.forEach(user => {
      if (user.additionalInfo && user.additionalInfo.age) {
        const age = user.additionalInfo.age;
        if (age <= 17) ageGroups['0-17']++;
        else if (age <= 24) ageGroups['18-24']++;
        else if (age <= 34) ageGroups['25-34']++;
        else if (age <= 44) ageGroups['35-44']++;
        else if (age <= 54) ageGroups['45-54']++;
        else if (age <= 64) ageGroups['55-64']++;
        else ageGroups['65+']++;
      }
    });

    return {
      labels: Object.keys(ageGroups),
      datasets: [
        {
          label: t('User Age Group'),
          data: Object.values(ageGroups),
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56'
          ],
        },
      ],
    };
  };

  const processUserGender = () => {
    const genderCounts = importedUsers.reduce((acc, user) => {
      if (user.additionalInfo && user.additionalInfo.gender) {
        const gender = user.additionalInfo.gender;
        acc[gender] = (acc[gender] || 0) + 1;
      }
      return acc;
    }, {});

    return {
      labels: Object.keys(genderCounts),
      datasets: [
        {
          label: t('User Gender'),
          data: Object.values(genderCounts),
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56'
          ],
        },
      ],
    };
  };

  const processUserNationality = () => {
    const nationalityCounts = importedUsers.reduce((acc, user) => {
      if (user.additionalInfo && user.additionalInfo.nationality) {
        const nationality = user.additionalInfo.nationality;
        acc[nationality] = (acc[nationality] || 0) + 1;
      }
      return acc;
    }, {});

    return {
      labels: Object.keys(nationalityCounts),
      datasets: [
        {
          label: t('User Nationality'),
          data: Object.values(nationalityCounts),
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56'
          ],
        },
      ],
    };
  };

  const processUserRegion = async (users) => {
    const regionCounts = {};

    await Promise.all(users.map(async user => {
      if (user.postalCode) {
        try {
          const { lat, lng, formatted, components } = await RestAPIService.fetchLatLng(user.postalCode);
          const region = components.state || components.province || components.region;
          if (region) {
            regionCounts[region] = (regionCounts[region] || 0) + 1;
          }
        } catch (error) {
          console.error(t(`Error fetching region for postal code ${user.postalCode}:`), error);
        }
      }
    }));

    setUserRegionData({
      labels: Object.keys(regionCounts),
      datasets: [
        {
          label: t('User Region'),
          data: Object.values(regionCounts),
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56'
          ],
        },
      ],
    });
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0, // Remove decimals
        },
        grid: {
          display: false // Remove grid lines
        }
      },
      x: {
        ticks: {
          callback: function(value) {
            return dayjs(this.getLabelForValue(value)).format('DD MMM YY');
          }
        },
        grid: {
          display: false // Remove grid lines
        }
      }
    },
    plugins: {
      legend: {
        display: false, // Remove legend
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const total = tooltipItem.dataset.data.reduce((acc, currentValue) => acc + currentValue, 0);
            const currentValue = tooltipItem.raw;
            const percentage = ((currentValue / total) * 100).toFixed(2);
            return `${tooltipItem.label}: ${percentage}%`;
          }
        }
      }
    },
    elements: {
      line: {
        tension: 0.4, // Smoothing the line
      }
    }
  };

  const doughnutChartOptions = {
    plugins: {
      legend: {
        display: true, // Display the legend
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const total = tooltipItem.dataset.data.reduce((acc, currentValue) => acc + currentValue, 0);
            const currentValue = tooltipItem.raw;
            const percentage = ((currentValue / total) * 100).toFixed(2);
            return `${tooltipItem.label}: ${percentage}%`;
          }
        }
      }
    },
    scales: {
      y: {
        display: false // Remove y-axis
      },
      x: {
        display: false // Remove x-axis
      }
    }
  };

  return (
    <div className="container-fluid fleet-module">
      <div className="row">
        <div className="col-md-12">
          <h2>{t("App Analytics")}</h2>
          <div className="row">
            <div className="col-md-3">
              <div className="chart-section donut" style={{ marginTop: '2rem' }}>
                <h4>{t("App Version Distribution")}</h4>
                {appInstalls.length > 0 ? (
                  <Doughnut data={processAppVersionDistribution()} options={doughnutChartOptions} />
                ) : (
                  <p>{t("No app version data available.")}</p>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="chart-section donut" style={{ marginTop: '2rem' }}>
                <h4>{t("Device Type Distribution")}</h4>
                {appInstalls.length > 0 ? (
                  <Doughnut data={processDeviceTypeDistribution()} options={doughnutChartOptions} />
                ) : (
                  <p>{t("No device type data available.")}</p>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="chart-section donut" style={{ marginTop: '2rem' }}>
                <h4>{t("Locale Identifier Distribution")}</h4>
                {appInstalls.length > 0 ? (
                  <Doughnut data={processLocaleIdentifierDistribution()} options={doughnutChartOptions} />
                ) : (
                  <p>{t("No locale identifier data available.")}</p>
                )}
              </div>
            </div>
          </div>
          <h2 className="mt-4">{t("AI Insights")}</h2>
          <div className="row">
            <div className="col-md-3">
              <div className="chart-section donut" style={{ marginTop: '2rem' }}>
                <h4>{t("User Age Group")}</h4>
                {importedUsers.length > 0 ? (
                  <Doughnut data={processUserAgeGroup()} options={doughnutChartOptions} />
                ) : (
                  <p>{t("No user age group data available.")}</p>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="chart-section donut" style={{ marginTop: '2rem' }}>
                <h4>{t("User Gender")}</h4>
                {importedUsers.length > 0 ? (
                  <Doughnut data={processUserGender()} options={doughnutChartOptions} />
                ) : (
                  <p>{t("No user gender data available.")}</p>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="chart-section donut" style={{ marginTop: '2rem' }}>
                <h4>{t("User Region")}</h4>
                {userRegionData ? (
                  <Doughnut data={userRegionData} options={doughnutChartOptions} />
                ) : (
                  <p>{t("No user region data available.")}</p>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="chart-section donut" style={{ marginTop: '2rem' }}>
                <h4>{t("User Nationality")}</h4>
                {importedUsers.length > 0 ? (
                  <Doughnut data={processUserNationality()} options={doughnutChartOptions} />
                ) : (
                  <p>{t("No user nationality data available.")}</p>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="chart-section mt-4">
                <h4>{t("Total App Installs Over Time")}</h4>
                {appInstalls.length > 0 ? (
                  <Line data={processWeeklyCumulativeData(appInstalls, 'Total App Installs Over Time')} options={chartOptions} />
                ) : (
                  <p>{t("No app install data available.")}</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="chart-section mt-4" style={{ marginTop: '2rem' }}>
                <h4>{t("Total App Users")}</h4>
                {importedUsers.length > 0 ? (
                  <Line data={processWeeklyCumulativeData(importedUsers, 'Total App Users')} options={chartOptions} />
                ) : (
                  <p>{t("No app user data available.")}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppComponent;
