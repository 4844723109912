import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Debug from "../services/debug.js";

const IoTComponent = ({
  isConnected,
  isPreflightChecked,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isConnected && !isPreflightChecked) {
      Debug.logger(1, 'iot redirect', isConnected, isPreflightChecked)
      navigate('/bleConnect');
    }

    if (isConnected && !isPreflightChecked) {
      Debug.logger(1, 'iot redirect 2', isConnected, isPreflightChecked)
      navigate('/bluetoothPreflight');
    }
  }, [isConnected, isPreflightChecked, navigate]);

  return (
    <div className="container">
      <h1>IoT Diagnostics</h1>
      {/* Your IoT diagnostics content here */}
    </div>
  );
};

export default IoTComponent;