import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import useCANStream from '../../../hooks/useCANStream.hook'; // Ensure the correct path

const RightBrakeTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const nodeId = 0x00000601;
  const index = 0x202C;
  const subIndex = 0x00;

  const timeAvailable = 20;

  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("Waiting for initial brake state to be 0...");
  const [countdown, setCountdown] = useState(timeAvailable);

  const currentValue = useRef([]);
  const startTime = useRef(new Date());
  const streamStarted = useRef(false);
  const timerId = useRef(null);
  const testBrake = useRef(false);

  const setNewValue = (value) => {
    currentValue.current.push(value);
    valueUpdated();
  };

  const { startStream, stopStream } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    [{ nodeId, index, subIndex }],
    (idx, value) => {
      if (idx.index === index && idx.subIndex === subIndex) {
        setNewValue(value);
      }
    }
  );

  useEffect(() => {
    startStream();
    return () => {
      stopStream();
    };
  }, []);

  const handleTestStop = (result) => {
    setInstruction(null);
    setTestResult(result);
    setTestRunning(false);
  };

  let endValueUpdates = false;

  const valueUpdated = () => {
    if (!endValueUpdates) {
      const latestValue = currentValue.current.length > 0 ? currentValue.current[currentValue.current.length - 1] : null;

      if (latestValue === 0) {
        setInstruction("Press and hold the right brake.");
        testBrake.current = true;
      }
      if (testBrake.current && latestValue === 1) {
        handleTestStop(true);
        setTestFinished(true);
        endValueUpdates = true;
      }

      const elapsedSeconds = Math.floor((new Date() - startTime.current) / 1000);
      setCountdown(timeAvailable - elapsedSeconds);
      if (elapsedSeconds >= timeAvailable) {
        handleTestStop(false);
        setTestFinished(true);
      }      
    }
  };

  const restartTest = () => {
    setTestRunning(true);
    setTestResult(null);
    testBrake.current = false;
    currentValue.current = [];
    startTime.current = new Date();
    setInstruction("Waiting for initial brake state to be 0...");
    setCountdown(timeAvailable);
    setTestFinished(false);
  };

  return (
    <div>
      <h1>Step {stepNumber} - Right Brake Test</h1>
      <p className="instructions">{instruction}</p>
      {testFinished && testResult === false && (
        <div className="fail-title">
          <h3>Test Failed</h3>
          The right brake was not activated within the time limit.
        </div>
      )}
      {testFinished && testResult === true && (
        <div className="success-title">
          <h3>Test Successful</h3>
          The right brake was successfully activated.
        </div>
      )}
      {testFinished && (
        <div className="test-summary">
          <p>
            <img src="/imgs/icons/icon-warning.svg" alt="Warning" />
            A summary of the entire test will be displayed at the end.
          </p>
        </div>
      )}
      {testResult === null && (
        <p>Current Brake State: {currentValue.current.length > 0 ? currentValue.current[currentValue.current.length - 1] : 'Waiting for data...'}</p>
      )}
      {!testFinished && (
        <div>
          <span className="countdown">Time remaining: {countdown} seconds</span>
          <ProgressBar
            now={(timeAvailable - countdown) * 5}
            className={countdown > 0 ? 'in-progress' : 'timeout'}
          />
        </div>
      )}
      {testResult === true && (
        <div>
          <Button onClick={() => onResult({ 
            result: true, 
            data: "Brake Activated",
            message: "The right brake was successfully activated." 
          })}>Next</Button>
        </div>
      )}
      {testResult === false && (
        <div>
          <Button className="btn btn-large btn-primary mr-4" onClick={restartTest}>Restart Test</Button>
          <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
            result: false, 
            data: "Brake Not Activated",
            message: "The right brake was not activated within the time limit."
          })}>Skip Test</Button>
        </div>
      )}
    </div>
  );
};

export default RightBrakeTest;
