import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import SideMenu from './sidemenu.component';
import mappingJson from './mapping.json';
import TabComponent from './tab.component';
import PinEntryComponent from './pin.component';
import { useTranslation } from 'react-i18next';

const ConfigurationComponent = ({ 
    userGroup,
    verifyPin,
    primaryService,
    writePassthroughCharacteristic,
    disconnectFromDevice,
    readPassthroughValue,
    connectToDevice,
    isConnected,
    bikeSerial,
    performFirmwareUpdate,
    updateControllerSettings,
    startNotificationListener,
    stopNotificationListener,
    getSendPassthroughRW,
}) => {
    const { t, i18n } = useTranslation();
    const [pin, setPin] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [mapping, setMapping] = useState(mappingJson);
    const navigate = useNavigate();

    useEffect(() => {
        setMenuItems(Object.keys(mapping));
    }, [mapping]);

    useEffect(() => {
        console.log("isConnected:", isConnected, "dataLoaded:", dataLoaded);
        if (!isConnected && !dataLoaded) {
            navigate('/configuration/pin-entry');
        }
    }, [isConnected, dataLoaded, navigate]);

    const reloadMapping = () => {
        setMapping(prevMapping => {
            const newMapping = { ...prevMapping };
            Object.entries(newMapping).forEach(([categoryKey, category]) => {
                Object.entries(category).forEach(([subCategoryKey, subCategory]) => {
                    Object.entries(subCategory.variables).forEach(([variableKey, variable]) => {
                        delete variable.value;
                        delete variable.updated;
                        delete variable.error;
                    });
                });
            });
            return newMapping;
        });
    };

    const updateMapping = (key, value) => {
        setMapping(prevMapping => {
            const newMapping = { ...prevMapping };
            Object.entries(newMapping).forEach(([categoryKey, category]) => {
                Object.entries(category).forEach(([subCategoryKey, subCategory]) => {
                    if (subCategory.variables[key]) {
                        subCategory.variables[key].value = value;
                        subCategory.variables[key].updated = true;
                    }
                });
            });
            return newMapping;
        });
    };

    const handleDataLoaded = () => {
        setDataLoaded(true);
    };

    const resetStates = () => {
        setDataLoaded(false);
        setMapping(mappingJson);
    };

    return (
        <div className="container-fluid container-diagnostics-module">
            <div className="row">
                {isConnected && dataLoaded && (
                    <div className="col-md-2">
                        <SideMenu
                            isConnected={isConnected}
                            disconnectFromDevice={disconnectFromDevice}
                            menuItems={menuItems}
                            dataLoaded={dataLoaded}
                            mapping={mapping}
                            updateMapping={updateMapping}
                            resetStates={resetStates}
                            primaryService={primaryService} 
                            performFirmwareUpdate={performFirmwareUpdate}
                            updateControllerSettings={updateControllerSettings}
                            readPassthroughValue={readPassthroughValue}
                            pin={pin}
                        />
                   </div>
                )}
                <div className={`col-md-${isConnected && dataLoaded ? '10' : '12'} main-content h-100`}>
                    {isConnected && dataLoaded && (
                      <div className="row">
                        <div className="col-12">
                          <h3>{t('Configuration')}</h3>
                          <h4>{t('Tool')}</h4>
                        </div>
                      </div>
                    )}
                    <Routes>
                        <Route path="/pin-entry" element={
                            <PinEntryComponent 
                                bikeSerial={bikeSerial} 
                                primaryService={primaryService} 
                                verifyPin={verifyPin}
                                writePassthroughCharacteristic={writePassthroughCharacteristic}
                                disconnectFromDevice={disconnectFromDevice}
                                readPassthroughValue={readPassthroughValue}
                                updateMapping={updateMapping}
                                handleDataLoaded={handleDataLoaded}
                                isConnected={isConnected}
                                connectToDevice={connectToDevice}
                                userGroup={userGroup}
                                dataLoaded={dataLoaded}
                                performFirmwareUpdate={performFirmwareUpdate}
                                startNotificationListener={startNotificationListener}
                                stopNotificationListener={stopNotificationListener}
                                getSendPassthroughRW={getSendPassthroughRW}
                                mapping={mapping}
                                setMapping={setMapping}
                                pin={pin}
                                setPin={setPin}
                                reloadMapping={reloadMapping}
                            />}
                        />
                        {dataLoaded && menuItems.length > 0 && menuItems.map((item, index) => (
                            <React.Fragment key={index}>
                                <Route path={`/${item.toLowerCase()}/:tab`} element={<TabComponent item={item} updateMapping={updateMapping} />} />
                                <Route path={`/${item.toLowerCase()}`} element={<Navigate to={`/configuration/${item.toLowerCase()}/${Object.keys(mapping[item])[0].toLowerCase()}`} />} />
                            </React.Fragment>
                        ))}
                        <Route path="*" element={<Navigate to={dataLoaded ? `/${menuItems[0]?.toLowerCase()}/${Object.keys(mapping[menuItems[0]])[0].toLowerCase()}` : '/configuration/pin-entry'} />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default ConfigurationComponent;
