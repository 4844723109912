import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Alert, Form } from 'react-bootstrap';
import RestAPIService from '../../services/rest-api.service';
import { useTranslation } from 'react-i18next';

const ViewStores = ({ stores, fetchStoresByBrand }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredStores, setFilteredStores] = useState(stores);
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [showStoreModal, setShowStoreModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [generalMessage, setGeneralMessage] = useState('');
  const navigate = useNavigate();

  const searchStore = (e) => {
    e.preventDefault();
    RestAPIService.searchStore(searchQuery).then((stores) => {
      console.log('stores', stores);
      if (stores && stores.length > 0) {
        setFilteredStores(stores);
        setGeneralMessage('');
      } else {
        setFilteredStores([]);
        setGeneralMessage(t('No stores found.'));
      }
    }).catch((error) => {
      console.error(t('There was an error searching for the store!'), error);
      setGeneralMessage(t('There was an error searching for the store!'));
    });
  };

  const handleShowStoreModal = (storeId) => {
    setSelectedStoreId(storeId);
    setDeleteMessage('');
    setShowStoreModal(true);
  };

  const handleCloseStoreModal = () => {
    setShowStoreModal(false);
    setSelectedStoreId(null);
    setDeleteMessage('');
    setFilteredStores(stores);
  };

  const handleDeleteStore = () => {
    if (selectedStoreId) {
      RestAPIService.deleteStore(selectedStoreId).then((response) => {
        console.log('response store delete', response);
        fetchStoresByBrand();
        setFilteredStores(stores);
        if (response.status === 200) {
          setGeneralMessage(t('Store deleted successfully'));
          handleCloseStoreModal();
        } else {
          setDeleteMessage(t('There was an error deleting the store. Please try again.'));
        }
      }).catch((error) => {
        console.error(t('There was an error deleting the store!'), error);
        setDeleteMessage(t('There was an error deleting the store. Please try again.'));
      });
    }
  };

  const clearSearch = () => {
    setFilteredStores(stores);
    setSearchQuery('');
    setGeneralMessage('');
  };

  return (
    <div className="container-fluid users-module-users-wrapper">
      <div className="row">
        <div className="col-6">
          <h3>{t('Search Stores')}</h3>
          <h4>{t('by Name, Address, or Phone')}:</h4>
        </div>
        <div className="col-6">
          <div className="search-form-container">
            <Form onSubmit={searchStore}>
              <Form.Group controlId="formSearchStore">
                <Form.Control
                  type="text"
                  placeholder={t('Enter search query')}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  required
                />
              </Form.Group>
              <Button className="btn btn-block btn-huge btn-primary" type="submit">
                {t('Search')}
              </Button>
            </Form>
          </div>
        </div>
      </div>
      {filteredStores.length !== stores.length && (
        <div className="row">
          <div className="col-12 text-right">
            <Button variant="secondary" onClick={clearSearch}>
              {t('Clear Search')}
            </Button>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12">
          {generalMessage && <Alert variant="info">{generalMessage}</Alert>}
          <table className="table table-no-borders">
            <thead>
              <tr>
                <th>{t('Name')}</th>
                <th>{t('Address')}</th>
                <th>{t('Phone')}</th>
                <th>{t('View Details')}</th>
                <th>{t('Delete')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredStores.map(store => (
                <tr key={store._id}>
                  <td>{store.name}</td>
                  <td>{`${store.address1}${store.address2 ? `, ${store.address2}` : ''}, ${store.city}, ${store.state}, ${store.country}, ${store.postalCode}`}</td>
                  <td>{store.phone}</td>
                  <td>
                    <button onClick={() => navigate(`/users/stores/${store._id}`)} className="btn btn-secondary-reverse btn-small">{t('View Details')}</button>
                  </td>
                  <td>
                    <button onClick={() => handleShowStoreModal(store._id)} className="btn btn-danger btn-small">{t('Delete')}</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Confirmation Modal for Stores */}
      <Modal show={showStoreModal} onHide={handleCloseStoreModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Confirm Deletion')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('Are you sure you want to delete this store?')}
          {deleteMessage && <Alert variant="danger" className="mt-2">{deleteMessage}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseStoreModal}>
            {t('Cancel')}
          </Button>
          <Button variant="danger" onClick={handleDeleteStore}>
            {t('Delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewStores;
