import { useState } from 'react';

const useSmartBike = () => {
  const [serialNumber, setSerialNumber] = useState(undefined);
  const [errorCode, setErrorCode] = useState(1);
  const [errorHistory, setErrorHistory] = useState([]);
  const [bikeState, setBikeState] = useState(false);
  const [iotState, setIotState] = useState(false);
  const [batteryState, setBatteryState] = useState(false);


  const setBikeSerialNumber = (value) => {
    return setSerialNumber(value);
  };

  const getBikeSerialNumber = () => {
    return serialNumber;
  };

  const setBikeError = (value) => {
    return setErrorCode(value);
  };

  const getBikeError = () => {
    return errorCode;
  };

  const getBikeErrorHistory = (value) => {
    return setErrorHistory(value);
  };

  const setBikeErrorHistory = () => {
    return errorHistory;
  };

  const isBikeError = () => {
    return bikeState;
  };

  const setIsBikeError = (value) => {
    return setBikeState(value);
  };

  const isIotError = () => {
    return iotState;
  };

  const setIsIotError = (value) => {
    return setIotState(value);
  };

  const isBatteryError = () => {
    return batteryState;
  };

  const setIsBatteryError = (value) => {
    return setBatteryState(value);
  };

  const resetBikeErrors = () => {
    setSerialNumber(undefined);
    setErrorCode(undefined);
    setErrorHistory([]);
    setBikeState(false);
    setIotState(false);
    setBatteryState(false);
  }

  return {
    getBikeSerialNumber,
    setBikeSerialNumber,
    getBikeError,
    setBikeError,
    getBikeErrorHistory,
    setBikeErrorHistory,
    isBikeError,
    setIsBikeError,
    isIotError,
    setIsIotError,
    isBatteryError,
    setIsBatteryError,
    resetBikeErrors,
  };
};

export default useSmartBike;