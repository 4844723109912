import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import AuthService from "../services/auth.service";
import AnalyticsService from "../services/analytics.service";
import { useTranslation } from 'react-i18next';
import Debug from "../services/debug.js";
import Logo from "./logo.component";

const ModulesComponent = ({ userGroup, setUserGroup, currentUser, setCurrentUser, setLoadingScreen }) => {
  const { t, i18n } = useTranslation();

  const [userReady, setUserReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const authedUser = AuthService.getCurrentUser();
    const group = AuthService.getCurrentGroup();
    setUserGroup(group);
    Debug.logger(1, 'authedUser', authedUser);
    Debug.logger(1, 'group', group);

    if (!authedUser) {
      navigate("/login");
    } else if (Array.isArray(authedUser.roles) && !authedUser.roles.includes("diagnostics-admin")) {
      navigate("/diagnostics");
    }

    setCurrentUser(authedUser);
    setUserReady(true);
  }, [navigate]);

  useEffect(() => {
    Debug.logger(1, 'userGroup', userGroup);
  }, [userGroup]);

  return (
    <div className="container-fluid modules-wrapper text-center">
      <div className="row justify-content-center">
        <div className="col-md-11">
          <Logo userGroup={userGroup} noDefault={true}/>
          <img className="title-svg" src="../imgs/ftex-icon-color.svg" alt="FTEX Evionics Operating System" />
          <h1>{t('Evionics Modules')}</h1>
          <h3 className="text-secondary-color">{t('End-to-End Vehicles Management System')}</h3>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-9">
          <div className="row justify-content-center module-container">
            <div className="col-md-4">
              <Link to={"/diagnostics"} className="card card-container module-card">
                <img src="../imgs/modules/diagnostics-icon.svg" alt="Diagnostics tool icon" />
                <h3>{t('Diagnostics')}</h3>
                <h4 className="text-secondary-color">{t('Tool')}</h4>
                <p>{t('Remote troubleshooting for bike brands, and equips workshops. Rapid diagnostic and repair tools.')}</p>
              </Link>
            </div>

            <div className="col-md-4">
              <Link to={"/fleet"} className="card card-container module-card">
                <img src="../imgs/modules/fleet-icon.svg" alt="Fleet manager icon" />
                <h3>{t('Fleet Manager')}</h3>
                <h4 className="text-secondary-color">{t('Tool')}</h4>
                <p>{t('Monitoring of the general activity metrics and state of health of vehicles on the road.')}</p>
              </Link>
            </div>

            <div className="col-md-4">
              <Link to={"/users"} className="card card-container module-card">
                <img src="../imgs/modules/users-icon.svg" alt="Users manager icon" />
                <h3>{t('Users Admin')}</h3>
                <h4 className="text-secondary-color">{t('Tool')}</h4>
                <p>{t('Creation of user roles for all new users. Distribution of the access to other tools of the SAAS.')}</p>
              </Link>
            </div>

            <div className="col-md-4">
              <Link to={"/analytics"} className="card card-container module-card">
                <img src="../imgs/modules/users-icon.svg" alt="Analytics icon" />
                <h3>{t('Analytics')}</h3>
                <h4 className="text-secondary-color">{t('Tool')}</h4>
                <p>{t('Get detailed analytics, statistics and information on how your users are interacting with their bikes, the mobile app and detailed error reporting.')}</p>
              </Link>
            </div>

            <div className="col-md-4">
              <Link to={"/manufacturing"} className="card card-container module-card disabled" onClick={(event) => event.preventDefault()}>
                <img src="../imgs/modules/users-icon.svg" alt="Manufacturing icon" />
                <h3>{t('Manufacturing')}</h3>
                <h4 className="text-secondary-color">{t('Tool (coming soon)')}</h4>
                <p>{t('Tools for performing quality assurance testing by bike assemblers, and supply chain tracking.')}</p>
              </Link>
            </div>

            <div className="col-md-4">
              <Link to={"/configuration"} className="card card-container module-card">
                <img src="../imgs/modules/users-icon.svg" alt="Configuration icon" />
                <h3>{t('Configuration')}</h3>
                <h4 className="text-secondary-color">{t('Tool')}</h4>
                <p>{t('The configuration tool allows brands to connect to your IoT enabled bike and modify its settings.')}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModulesComponent;
