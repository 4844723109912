import React from 'react';
import useDiagnostics from './../../hooks/useDiagnostics.hook';
import BatteryComponent from './battery.component.js';

const RunBattery = (props) => {
  const batteryProps = useDiagnostics(props);
  return <BatteryComponent {...batteryProps} />;
};

export default RunBattery;
