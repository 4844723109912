import React from 'react';
import useDiagnostics from './../../hooks/useDiagnostics.hook';
import DiagnosticsComponent from './liveDiagnostics.component.js';

const RunDiagnostics = (props) => {
  const diagnosticsProps = useDiagnostics(props);
  return <DiagnosticsComponent {...diagnosticsProps} />;
};

export default RunDiagnostics;
