import axios from "axios";
import Debug from "./debug.js";

const API_URL = "https://ftex-backend-73e39a9f925f.herokuapp.com/";
const OPEN_CAGE_API_KEY = "64f29c16a2784ea38f8cedf8f98e30e1"; // Replace with your actual OpenCage API key

class RestAPIService {
  checkServer() {
    return axios
      .get(API_URL + "status")
      .then(response => {
        Debug.logger(1, 'DB Server is Running');
        return true;
      })
      .catch(function (error) {
        Debug.logger(3, 'DB Server is down');
        return false;
      });
  }

  getBike(id) {
    return axios
      .get(API_URL + "bikes/" + id)
      .then(response => {
        Debug.log('rest api getBike', response.data);
        return response.data;
      })
      .catch(function (error) {
        Debug.log('rest api getBike', JSON.stringify(error));
        return error;
      });
  }

  listAllBikes(userGroup) {
    return axios
      .get(API_URL + "bikes/all/" + userGroup)
      .then(response => {
        Debug.log('rest api listAllBikes', response.data);
        return response.data;
      })
      .catch(function (error) {
        Debug.log('rest api listAllBikes', JSON.stringify(error));
        return error;
      });
  }

  createBike(bike) {
    return axios
      .post(API_URL + "bikes", bike)
      .then(response => {
        Debug.log('rest api createBike', response.data);
        return response.data;
      })
      .catch(function (error) {
        Debug.log('rest api createBike', JSON.stringify(error));
        throw error;
      });
  }

  removeBatteryFromBike(batterySerial) {
    Debug.logger(2, "Remove Battery From Bike API Call", batterySerial);
    return axios
      .put(`${API_URL}bikes/remove-battery/${batterySerial}`)
      .then(response => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  updateBikeBattery(id, batterySerial) {
    return axios
      .put(API_URL + "bikes/" + id, {
        "battery_serial": batterySerial,
      })
      .then(response => {
        Debug.log('rest api updatedBikeBattery', response.data);
        return response.data;
      })
      .catch(function (error) {
        Debug.log('rest api updatedBikeBattery', JSON.stringify(error));
        return error;
      });
  }

  updateBikeController(id, controllerSerial) {
    return axios
      .put(API_URL + "bikes/" + id, {
        "ftex_serial": controllerSerial,
      })
      .then(response => {
        Debug.log('rest api updateBikeController', response.data);
        return response.data;
      })
      .catch(function (error) {
        Debug.log('rest api updateBikeController', JSON.stringify(error));
        return error;
      });
  }

  addBikeRecord(id, record) {
    if (!record.type) {
      record.type = "auto";
    }

    return axios
      .post(API_URL + "bikes/" + id + "/records", {
        "type": record.type,
        "source": record.source,
        "problem": record.problem,
        "comment": record.comment,
        "params": record.params,
      })
      .then(response => {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }

  addRecord(action, brand, params) {
    if (!action || !brand || !params) {
      return;
    }

    return axios
      .post(API_URL + "analytics", {
        "action": action.toLowerCase(),
        "brand": brand.toLowerCase(),
        "type": params.type,
        "source": params.source,
        "params": params.params,
      })
      .then(response => {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }

  getBikeRecords(id) {
    return axios
      .get(API_URL + "bikes/" + id + "/records")
      .then(response => {
        Debug.log('rest api getBikeRecords', response.data);
        return response.data;
      })
      .catch(function (error) {
        Debug.log('rest api getBike', JSON.stringify(error));
        return error;
      });
  }

  createStore(store) {
    return axios
      .post(API_URL + "stores", store)
      .then((response) => {
        Debug.log("rest api createStore", response);
        return response;
      })
      .catch(function (error) {
        Debug.log("rest api createStore", JSON.stringify(error));
        throw error;
      });
  }

  fetchStoresByBrand(brand) {
    return axios.get(`${API_URL}stores/${brand}`)
      .then(response => {
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch(error => {
        Debug.log('Fetch Stores by Brand Error in RestAPIService', JSON.stringify(error));
        return [];
      });
  }

  fetchLatLng(query) {
    return axios.get(`${API_URL}opencage/geocode?query=${encodeURIComponent(query)}`)
      .then(response => {
        if (response.data.lat && response.data.lng) {
          return {
            lat: response.data.lat,
            lng: response.data.lng,
            formatted: response.data.formatted,
            components: response.data.components,
            cache: response.data.cache
          };
        } else {
          throw new Error("No results found for the provided query.");
        }
      })
      .catch(error => {
        Debug.log('fetchLatLng', JSON.stringify(error));
        throw error;
      });
  }

  searchStore(query) {
    return axios.get(`${API_URL}stores/search/${query}`)
      .then(response => {
        Debug.log('rest api searchStore', response.data);
        return response.data;
      })
      .catch(error => {
        Debug.log('rest api searchStore', JSON.stringify(error));
        return [];
      });
  }

  deleteStore(id) {
    return axios.delete(`${API_URL}stores/${id}`)
      .then(response => {
        Debug.log('rest api deleteStore', response);
        return response;
      })
      .catch(error => {
        Debug.log('rest api deleteStore', JSON.stringify(error));
        throw error;
      });
  }

  getStore(storeId, brand) {
    return axios.get(`${API_URL}stores/store/${storeId}`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        Debug.log('getStore', JSON.stringify(error));
        throw error;
      });
  }

  updateStore(storeId, store) {
    return axios.put(`${API_URL}stores/store/${storeId}`, store)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        Debug.log('updateStore', JSON.stringify(error));
        throw error;
      });
  }

  fetchUsersByStore(storeId) {
    return axios.get(`${API_URL}users/store/${storeId}`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        Debug.log('fetchUsersByStore', JSON.stringify(error));
        throw error;
      });
  }

  fetchBikesByBrand(brand) {
    return axios.get(`${API_URL}bikes/all/${brand}`)
      .then(response => {
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch(error => {
        Debug.log('Fetch Bikes by Brand Error in RestAPIService', JSON.stringify(error));
        return [];
      });
  }

  fetchBikesAddedOverTime(userGroup) {
    return axios.get(`${API_URL}bikes/added-over-time/${userGroup}`)
      .then(response => response.data)
      .catch(error => {
        Debug.log('fetchBikesAddedOverTime', JSON.stringify(error));
        throw error;
      });
  }

  fetchBikeErrorsByBrand(userGroup) {
    return axios.get(`${API_URL}analytics/${userGroup}`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        Debug.log('Fetch Bike Errors by Brand Error in RestAPIService', JSON.stringify(error));
        throw error;
      });
  }

  fetchImportedUsers(brand) {
    return axios.get(`${API_URL}analytics/app/users`, {
      params: { brand }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      Debug.log('Fetch Imported Users Error in RestAPIService', JSON.stringify(error));
      return [];
    });
  }

  fetchTrips() {
    return axios.get(`${API_URL}analytics/app/trips`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        Debug.log('Fetch All Trips Error in RestAPIService', JSON.stringify(error));
        throw error;
      });
  }

  fetchSessions() {
    return axios.get(`${API_URL}analytics/app/sessions`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        Debug.log('Fetch All Sessions Error in RestAPIService', JSON.stringify(error));
        throw error;
      });
  }

  fetchBikeLocations(brand) {
    return axios.get(`${API_URL}analytics/app/bikes`, {
      params: { brand }
    })
    .then(response => response.data)
    .catch(error => {
      Debug.log('Fetch Bike Locations Error in RestAPIService', JSON.stringify(error));
      throw error;
    });
  }

  fetchInstallations() {
    return axios.get(`${API_URL}analytics/app/installations`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        Debug.log('Fetch Installations Error in RestAPIService', JSON.stringify(error));
        throw error;
      });
  }

  getAnalyticsData(brand, bikeSerial) {
    return axios.get(`${API_URL}analytics/data`, { params: { brand, bikeSerial }})
      .then(response => {
        return response;
      })
      .catch(error => {
        Debug.log('Fetch Installations Error in RestAPIService', JSON.stringify(error));
        throw error;
      });
  }

  getConnections(brand) {
    return axios
      .get(`${API_URL}analytics/connections`, { params: { brand } })
      .then(response => response.data)
      .catch(error => {
        Debug.log('rest api getConnections', JSON.stringify(error));
        throw error;
      });
  }

  getLogins(brand) {
    return axios
      .get(`${API_URL}analytics/logins`, { params: { brand } })
      .then(response => response.data)
      .catch(error => {
        Debug.log('rest api getLogins', JSON.stringify(error));
        throw error;
      });
  }

  getStoreDetailsByIds(storeIds) {
    return axios
      .post(`${API_URL}stores/ids`, { storeIds })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        Debug.log('rest api getStoreDetailsByIds', JSON.stringify(error));
        throw error;
      });
  }
}

export default new RestAPIService();
