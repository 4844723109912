import React, { createContext, useContext, useState, useEffect } from 'react';

const DebugContext = createContext();

export const useDebug = () => {
  return useContext(DebugContext);
};

export const DebugProvider = ({ children, containerRef }) => {
  const [debugBLE, setDebugBLE] = useState(false);

  useEffect(() => {
    if (debugBLE) {
      containerRef.current.classList.add('debug-mode');
    } else {
      containerRef.current.classList.remove('debug-mode');
    }
  }, [debugBLE, containerRef]);

  return (
    <DebugContext.Provider value={{ debugBLE, setDebugBLE }}>
      {children}
    </DebugContext.Provider>
  );
};
