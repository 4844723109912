import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Debug from '../../services/debug.js';
import RestAPIService from '../../services/rest-api.service';
import useBluetooth from '../../hooks/useBluetooth.hook';
import useContent from '../../hooks/useContent.hook';
import ReactCodeInput from 'react-code-input';

const BatteryMaintenanceComponent = ({
  bikeSerial,
  t,
  pin,
  setPin,
  error,
  isPrimaryServiceAvailable,
  isConnected,
  pinVerified,
  results,
  batteryCommands,
  batteryStatusMessage,
  handleSubmit,
  getSendPassthroughRW,
  primaryService,
  stopUpdates,
  restartUpdates,
  stopNotificationListener,
  startNotificationListener,
}) => {
  const [batterySerial, setBatterySerial] = useState('');
  const [bikeBrand, setBikeBrand] = useState('');
  const [bikeModel, setBikeModel] = useState('');
  const [bikeYear, setBikeYear] = useState('');
  const [showBatteryModal, setShowBatteryModal] = useState(false);
  const { errorCodeDocumentation } = useContent();
  const navigate = useNavigate();

  const fetchBikeInfo = async () => {
    try {
      let bikeInfo = await RestAPIService.getBike(bikeSerial);
      Debug.logger(2, 'bikeInfo', bikeInfo);
      setBatterySerial(bikeInfo.battery_serial);
      setBikeBrand(bikeInfo.brand);
      setBikeModel(bikeInfo.model);
      setBikeYear(bikeInfo.year);
    } catch (error) {
      Debug.logger(2, 'Error fetching bike info from Rest API Server', error);
    }
  };

  useEffect(() => {
    Debug.logger(2, 'FETCH FETCH', bikeSerial);
    if (bikeSerial && pinVerified) {
      fetchBikeInfo();
    }
  }, [bikeSerial, pinVerified]);

  const handlePinChange = (value) => {
    setPin(value);
  };

  return (
    <div className="container-fluid h-100 bike-detail">
      <div className="row">
        <div className="col-6">
          <h3>{t("Battery Maintenance")}</h3>
          <h4>{t("Diagnostics and Status")}</h4>
        </div>
      </div>
      {isPrimaryServiceAvailable && !pinVerified ? (
        <div className="row text-center d-flex align-items-center justify-content-center h-100">
          <div className="col-3 text-left">
            <div className="pin-entry">
              <h4>{t("PIN Required")}</h4>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <ReactCodeInput
                    type="number"
                    fields={4}
                    id="pin"
                    value={pin}
                    onChange={handlePinChange}
                  />
                  <label htmlFor="pin">{t('The PIN is unique to each bicycle and is held by the owner of the vehicle. This 4-digit code is necessary to access the information.')}</label>
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                <button type="submit" className="btn btn-block btn-primary">{t('Connect')}</button>
              </form>
            </div>
          </div>
        </div>
      ) : !isPrimaryServiceAvailable && !pinVerified ? (
        <div className="row text-center d-flex align-items-center justify-content-center h-100">
          <div className="col-3">
            <p>{t('Connecting to primary service...')}</p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-9">
            <div className="bike-detail-summary">
              {bikeBrand && bikeModel && bikeYear && (
                <>
                  <h2>
                    <span>{bikeBrand}</span> {bikeModel} ({bikeYear})&nbsp;-&nbsp;
                  </h2>
                  <h3>{bikeSerial}</h3>
                  <div className="row info-box-container">
                    <div className="col info-box">
                      <h5>{t('Battery Serial')}:</h5>
                      <span>{batterySerial}</span>
                      <button className="btn btn-danger btn-block" onClick={() => setShowBatteryModal(true)}>{t('Edit')}</button>
                    </div>
                    <div className="col info-box">
                      <h5>{t('Model')}:</h5>
                      <span>{bikeModel}</span>
                    </div>
                    <div className="col info-box">
                      <h5>{t('Year')}:</h5>
                      <span>{bikeYear}</span>
                    </div>
                    <div className="col info-box">
                      <h5>{t('Bike Serial')}:</h5>
                      <span>{bikeSerial}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-12 mt-4">
            <h4>{t('Diagnostics Results')}</h4>
            <div className="diagnostics-logging">
              {batteryStatusMessage && (
                <p style={{ color: batteryStatusMessage === t('There is a battery problem.') ? 'red' : 'green' }}>
                  {batteryStatusMessage}
                </p>
              )}
            </div>
          </div>

          <div className="col-12 mt-4">
            {batteryCommands.length > 0 && (
              <div className="row">
                <div className="col-md-6">
                  <table className="table table-no-borders no-background">
                    <thead>
                      <tr>
                        <th colSpan="2">{t('Battery Status')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {results.filter(result => batteryCommands.some(cmd => cmd.index === result.index && cmd.subIndex === result.subIndex)).map((result, index) => (
                        <tr key={index}>
                          <td>{result.subName}</td>
                          <td>{result.latestValue} {result.unit}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BatteryMaintenanceComponent;
