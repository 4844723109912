import axios from "axios";
import Debug from "./debug.js";

//const API_URL = "http://localhost/ftex-diagnostics"; // local deployment
//const API_URL = "https://diagnosticsftexca.kinsta.cloud"; // Kinsta site preview deployment
const API_URL = "https://diagnostics-wp.ftex.ca"; // production deployment

const AUTH_KEY = "ftex1234";

class AuthService {
  checkWordpressStatus() {
    return true;
    return axios
      .get(API_URL + "/wp-json/wp/v2/themes")
      .then(response => {
        return true;
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          return true;
        } else {
          return false;
        }
      });
  }

  login(email, password) {
    Debug.logger(1, 'trying to login to auth service with simple JWT login');
     return axios
      .post(API_URL + "/?rest_route=/simple-jwt-login/v1/auth", {
        email,
        password,
        AUTH_KEY
      })
      .then(response => {
        Debug.logger(1, 'AuthService.login', response.data);
        if (response.status !== 200) {
          throw new Error(response);
        }
        if (response.data && response.data.data && response.data.data.jwt) {
          const JWT = response.data.data.jwt;
          const group = response.data.brand_group;
          const userID = response.data.userID;
          const roles = response.data.roles;
          const store_id = response.data.store_id;
          if (!group) {
            group = "FTEX";
          }
          localStorage.setItem("jwt", JWT);
          localStorage.setItem("group", group);
          localStorage.setItem("userID", userID);
          localStorage.setItem("storeID", store_id);
          localStorage.setItem("roles", roles);
          return response.data;
        }
      })
      .catch(function (error) {
        Debug.logger(1, 'error occured during login');
        Debug.log('AuthService.login catch', JSON.stringify(error));
        return Promise.reject(error.response);
      });
  }

  sendPasswordReset(email) {
     return axios
      .post(API_URL + "/?rest_route=/simple-jwt-login/v1/user/reset_password", {
        email,
        AUTH_KEY
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(response);
        }
        if (response.data) {
          Debug.logger(1, 'response', response.data);
        }
      })
      .catch(function (error) {
        Debug.logger(1, 'error occured during login');
        Debug.log('AuthService.login catch', JSON.stringify(error));
        return Promise.reject(error.response);
      });
  }

  resetPassword(email, code, new_password) {
     return axios
      .put(API_URL + "/?rest_route=/simple-jwt-login/v1/user/reset_password", {
        email,
        code,
        new_password,
        AUTH_KEY
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(response);
        }
        if (response.data) {
          Debug.logger(1, 'response', response.data);
        }
      })
      .catch(function (error) {
        Debug.logger(1, 'error occured during login');
        Debug.log('AuthService.login catch', JSON.stringify(error));
        return Promise.reject(error.response);
      });
  }

  getCurrentUserData(email, password) {
    const JWT = localStorage.getItem('jwt');
    if (JWT || JWT !== 'undefined') {
      return axios
        .post(API_URL + "/?rest_route=/simple-jwt-login/v1/auth/validate", {
          jwt: JWT,
          AUTH_KEY
          })
          .then(response => {
            Debug.logger(1, 'AuthService.getCurrentUserData', response.data);
            if (response.data && response.data.data && response.data.data.user) {
              let user = {
                username: response.data.data.user.display_name,
                accessToken: JWT,
                id: response.data.data.user.ID,
                store: response.data.store_id,
                brand: response.data.brand_group,
                email: response.data.data.user.user_email,
                roles: response.data.data.roles
              }
              localStorage.setItem("user", JSON.stringify(user));
              return user;
            }
          }).catch(error => {
            return error;
          });
    }
  }

  updateUserLocale(lang) {
    Debug.logger(1, 'set wordpress lang', lang);
    const JWT = localStorage.getItem('jwt');
    const userID = localStorage.getItem('userID');

    if (JWT && JWT !== 'undefined') {
      axios
        .post(`${API_URL}/wp-json/ftex/v1/users/language`, {
          userID: userID,
          lang: lang,
          jwt: JWT,
          AUTH_KEY
        })
        .then(updateResponse => {
          Debug.logger(1, 'Language update response', updateResponse);
        })
        .catch(error => {
          Debug.logger(1, 'Error updating language', error);
        });
    }
  }


  // updateUserLocale(lang) {
  //   console.log('set wordpress lang', lang);
  //   const JWT = localStorage.getItem('jwt');
  //   if (JWT || JWT !== 'undefined') {
  //     return axios
  //       .get(API_URL + "/wp-json/wp/v2/users")
  //       .then(response => {
  //         if (response && response.data && response.data.length > 0) {
  //           const userID = response.data[0].id;
  //           if (userID && userID > 0) {
  //             console.log('USER ID is ' + userID);

  //             axios
  //               .get(API_URL + "/wp-json/nonce/v1/get?random=" + Math.floor(Math.random() * 10 + 1))
  //               .then(response => {
  //                 console.log('nonce', response)
  //               })

  //             // axios
  //             //   .post(API_URL + "/wp-json/wp/v2/users/" + userID, {
  //             //   jwt: JWT,
  //             //   AUTH_KEY,
  //             //   locale: "en_US",
  //             //   context: "edit"
  //             //   })
  //             //   .then(response => {
  //             //     console.log('try to set language response', response);
  //             //   }).catch(error => {
  //             //     console.log('try to set language response', response);
  //             //   });
  //           }
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log('get user error');
  //       });
  //   }
  // }

  logout() {
    const JWT = localStorage.getItem('jwt');
    Debug.log('AuthService.logout JWT', JWT);
    axios
      .post(API_URL + "/?rest_route=/simple-jwt-login/v1/auth/revoke", {
        jwt: JWT,
        AUTH_KEY
        })
        .then(response => {
          Debug.log('AuthService.logout', response);
        })
        .catch(error => {
          Debug.log('AuthService.logout error', error);
        });
    localStorage.removeItem("user");
    localStorage.removeItem("jwt");
    localStorage.removeItem("userID");
    localStorage.removeItem("storeID");
    localStorage.removeItem("roles");
  }

  // register(username, email, password) {
  //   return axios.post(API_URL + "/?rest_route=/simple-jwt-login/v1/users", {
  //     username,
  //     email,
  //     password
  //   });
  // }

  getCurrentUser() {
    const USER = localStorage.getItem('user');
    const JWT = localStorage.getItem('jwt');
    if (!USER || !JWT) {
      localStorage.removeItem('user');
      localStorage.removeItem('jwt');
      localStorage.removeItem("userID");
      localStorage.removeItem("storeID");
      localStorage.removeItem("roles");
      return false;
    }
    Debug.log('AuthService.getCurrentUser return user', localStorage.getItem('user'));
    return JSON.parse(localStorage.getItem('user'));
  }

  getCurrentGroup() {
    return localStorage.getItem('group');
  }

  getUserGroup() {
    const GROUP = localStorage.getItem('group');
    if (!GROUP) {
      return "FTEX";
    }
    return GROUP;
  }
}

export default new AuthService();