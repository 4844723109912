import config from "./config.js";

class Debug {
  log(...msg) {
    if (config.isDebugMode) {
      console.log(msg);
    }
  }
  logger(level, ...msg) {
    if (!level) {
      msg = level;
      level = 1;
    }
    if (config.isDebugMode && config.debugDetail >= 3 && level === 1) {
      console.info(msg);
    }
    if (config.isDebugMode && config.debugDetail >= 2 && level === 2) {
      console.warn(msg);
    }
    if (config.isDebugMode && config.debugDetail >= 1 && level === 3) {
      console.error(msg);
    }
  }
}

export default new Debug();