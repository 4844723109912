import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import useCANStream from '../../../hooks/useCANStream.hook'; // Ensure the correct path
import { useTranslation } from 'react-i18next';

const TorqueTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const nodeId = 0x00000601;
  const index = 0x2024;
  const subIndex = 0x01;

  const timeAvailable = 20;

  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("Press on the pedals on the bike");
  const [torqueChangeCounter, setTorqueChangeCounter] = useState(0);
  const [initialValueFailed, setInitialValueFailed] = useState(false);
  const [countdown, setCountdown] = useState(timeAvailable);
  const { t } = useTranslation();

  const currentValue = useRef([]);
  const initialValueChecked = useRef(false);
  const startTime = useRef(null);
  const streamStarted = useRef(false);
  const timerId = useRef(null);

  const setNewValue = (value) => {
    currentValue.current.push(value);
    valueUpdated();
  };

  const countValueChanges = (arr) => {
    if (arr.length === 0) return 0;

    let changeCount = 0;
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] !== arr[i - 1]) {
        changeCount++;
      }
    }

    return changeCount;
  };

  const { startStream, stopStream } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    [{ nodeId, index, subIndex }],
    (idx, value) => {
      if (idx.index === index && idx.subIndex === subIndex) {
        setNewValue(value);
      }
    }
  );

  const handleTestStop = useCallback((result) => {
    clearInterval(timerId.current);
    setInstruction(null);
    setTestResult(result);
    setTestRunning(false);
    stopStream();
  }, [stopStream]);

  useEffect(() => {
    return () => {
      clearInterval(timerId.current);
      stopStream();
    };
  }, [stopStream]);

  const startTestStream = () => {
    streamStarted.current = true;
    setTestRunning(true);
    startTime.current = new Date();
    startStream();
    console.log('start stream and timerID');
    timerId.current = setInterval(() => {
      const elapsedSeconds = Math.floor((new Date() - startTime.current) / 1000);
      setCountdown(timeAvailable - elapsedSeconds);

      if (elapsedSeconds >= timeAvailable) {
        handleTestStop(false);
        setTestFinished(true);
      }
    }, 1000);
  }

  useEffect(() => {
    if (!streamStarted.current) {
      startTestStream();
    }
  }, [startStream, handleTestStop]);

  const valueUpdated = () => {
    const latestValue = currentValue.current.length > 0 ? currentValue.current[currentValue.current.length - 1] : null;

    if (latestValue !== null) {
      if (!initialValueChecked.current) {
        if (latestValue === 0) {
          initialValueChecked.current = true;
          setInstruction("Press on the pedals on the bike");
        } else {
          setInitialValueFailed(true);
          setInstruction("Initial value of zero not detected");
          initialValueChecked.current = true;
        }
      } else {
        const changes = countValueChanges(currentValue.current);
        setTorqueChangeCounter(changes);

        const elapsedSeconds = Math.floor((new Date() - startTime.current) / 1000);
        setCountdown(timeAvailable - elapsedSeconds);

        if (changes >= 5) {
          setTestFinished(true);
          if (initialValueFailed) {
            handleTestStop(false);            
          } else {
            handleTestStop(true);
          }
        } else if (elapsedSeconds >= timeAvailable) {
          setTestFinished(true);
          if(torqueChangeCounter >= 5 && !initialValueFailed) {
            handleTestStop(true);
          } else {
            handleTestStop(false);
          }
        }
      }
    }
  };

  const restartTest = () => {
    setTestRunning(true);
    setTestResult(null);
    setTorqueChangeCounter(0);
    currentValue.current = [];
    initialValueChecked.current = false;
    setInstruction("Press on the pedals on the bike");
    setInitialValueFailed(false);
    setCountdown(timeAvailable);
    setTestFinished(false);
    startTime.current = new Date();
    startTestStream();
    setTimeout(() => {
      console.log('set timeout', countdown, timeAvailable)
      if (countdown === timeAvailable) {
        startTestStream();
      }
    }, 2000);
  };

  return (
    <div>
      <h1>Step {stepNumber} - Torque Test</h1>
      <p className="instructions">{instruction}</p>
      {testFinished && initialValueFailed && (
        <div className="fail-title">
          <h3>Test Failed</h3>
          Torque initial state is not zero. This means that the torque sensor is sending a torque input even if no pressure is put on the pedal, this can be dangerous and the torque sensor needs to be replaced.
        </div>
      )}
      {testFinished && !initialValueFailed && torqueChangeCounter < 5 && (
        <div className="fail-title">
          <h3>Test Failed</h3>
          A torque (pressure on the pedal) was not detected. If you stepped on the the pedals within the timer and you are getting this error, it means the torque sensor is not functioning correctly, or is not correctly plugged into the controller.
        </div>
      )}
      {testFinished && !initialValueFailed && torqueChangeCounter >= 5 && (
        <div className="success-title">
          <h3>Test Successful</h3>
          The initial torque state is 0 (no torque detected when not stepping on the pedals), and we are able to detect the torque when the pedals are stepped on or pressed.
        </div>
      )}
      {testFinished && (
        <div className="test-summary">
          <p>
            <img src="/imgs/icons/icon-warning.svg" alt="Warning" />
            A summary of the entire test will be displayed at the end.
          </p>
        </div>
      )}
      {testResult === null && (
        <p>Current Torque: {currentValue.current.length > 0 ? currentValue.current[currentValue.current.length - 1] : 'Waiting for data...'}</p>
      )}
      {!testFinished && (
        <div>
          <span className="countdown">Time remaining: {countdown} seconds</span>
          <ProgressBar
            now={(timeAvailable - countdown) * 5}
            className={countdown > 0 ? 'in-progress' : 'timeout'}
          />
        </div>
      )}
      {testResult === true && (
        <div>
          <Button onClick={() => onResult({ 
            result: true, 
            data: torqueChangeCounter,
            message: "The initial torque state is 0 (no torque detected when not stepping on the pedals), and we are able to detect the torque when the pedals are stepped on or pressed." 
          })}>Next</Button>
        </div>
      )}
      {testResult === false && (
        <div>
          <Button className="btn btn-large btn-primary mr-4" onClick={restartTest}>Restart Test</Button>
          {testFinished && initialValueFailed && (
            <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
              result: false, 
              data: torqueChangeCounter,
              message: "Torque initial state is not zero. This means that the torque sensor is sending a torque input even if no pressure is put on the pedal, this can be dangerous and the torque sensor needs to be replaced." 
            })}>Skip Test</Button>
          )}
          {testFinished && !initialValueFailed && torqueChangeCounter < 5 && (
            <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
              result: false, 
              data: torqueChangeCounter,
              message: "A torque (pressure on the pedal) was not detected. If you stepped on the the pedals within the timer and you are getting this error, it means the torque sensor is not functioning correctly, or is not correctly plugged into the controller." 
            })}>Skip Test</Button>
          )}

        </div>
      )}
    </div>
  );
};

export default TorqueTest;
