import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import SideMenu from './sidemenu.component';
import HomeComponent from './home.component';
import AppComponent from './app.component';
import BikesComponent from './bikes.component';
import StoresComponent from './stores.component';
import UsersComponent from './users.component';
import DiagnosticsUsageComponent from './diagnostics-usage.component';

const AnalyticsComponent = ({ userGroup }) => {
  return (
    <div className="container-fluid container-analytics-module">
      <div className="row">
        <div className="col-md-2">
          <SideMenu userGroup={userGroup}/>
        </div>
        <div className="col-md-10 main-content">
          <Routes>
            <Route path="/" element={<BikesComponent userGroup={userGroup} />} />
            <Route path="/stores" element={<StoresComponent userGroup={userGroup} />} />
            <Route path="/users" element={<UsersComponent userGroup={userGroup} />} />
            <Route path="/app" element={<AppComponent userGroup={userGroup} />} />
            <Route path="/diagnostics-usage" element={<DiagnosticsUsageComponent userGroup={userGroup} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsComponent;
