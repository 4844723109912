import { useState, useEffect } from 'react';
import axios from "axios";
import config from "../services/config.js";
import Debug from "../services/debug.js";
import { useTranslation } from 'react-i18next';

const API_URI = config.wordpressURL;

const useContent = () => {
  const [errorCodeDocumentation, setErrorCodeDocumentation] = useState({});
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchContent = () => {
      let API_lang = i18n.language === 'fr' ? "/fr" : "";
      console.log('fetch content velec', API_URI + API_lang + "/wp-json/wp/v2/pages");
      axios.get(API_URI + API_lang + "/wp-json/wp/v2/velec?per_page=100")
        .then(response => {
          setErrorCodeDocumentation(processDocumentationData(response.data));
          Debug.log('content velec', response);
        })
        .catch(error => {
          Debug.log('Content Fetch Error in useContent hook', JSON.stringify(error));
          return error;
        });
    };

    fetchContent();  // Call fetchContent on initial mount

    // Setup the language change listener
    const handleLanguageChange = () => {
      fetchContent();  // Refetch the content when language changes
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup function to remove listener
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);  // Effect depends on i18n object

  const sendEmail = (emailData) => {
    const { to, subject, message, brand } = emailData;

    // Prepare the data to send
    const dataToSend = {
      to,
      subject,
      message,
      brand
    };

    return axios.post(`${API_URI}/wp-json/ftex/v1/send-email`, dataToSend)
      .then(response => response.data)
      .catch(error => {
        Debug.log('Send Email Error in useContent hook', JSON.stringify(error));
        throw error;
      });
  };

  const fetchUsersByBrand = (brand) => {
    return axios.get(`${API_URI}/wp-json/ftex/v1/users/list?brand=${brand}`)
      .then(response => {
        // Ensure the response is in the expected format
        if (Array.isArray(response.data.users)) {
          return response.data.users;
        } else {
          return [];
        }
      })
      .catch(error => {
        Debug.log('Fetch Users by Brand Error in useContent hook', JSON.stringify(error));
        return [];
      });
  };

  const fetchUserByEmail = (email, brand) => {
    return axios.get(`${API_URI}/wp-json/ftex/v1/users/by-email?email=${email}&brand=${brand}`)
      .then(response => {
        // Ensure the response is in the expected format
        if (response.data) {
          return response.data;
        } else {
          return null;
        }
      })
      .catch(error => {
        Debug.log('Fetch User by Email Error in useContent hook', JSON.stringify(error));
        return null;
      });
  };

  const fetchUsersByStore = (storeId) => {
    return axios.get(`${API_URI}/wp-json/ftex/v1/users/by-store?store_id=${storeId}`)
      .then(response => {
        // Ensure the response is in the expected format
        if (Array.isArray(response.data.users)) {
          return response.data.users;
        } else {
          return [];
        }
      })
      .catch(error => {
        Debug.log('Fetch Users by Store Error in useContent hook', JSON.stringify(error));
        return [];
      });
  };

  const createUser = (user) => {
    return axios.post(`${API_URI}/wp-json/ftex/v1/users/register`, user)
      .then(response => response.data)
      .catch(error => {
        Debug.log('Create User Error in useContent hook', JSON.stringify(error));
        throw error;
      });
  };

  const resetUserPassword = (userId) => {
    return axios.post(`${API_URI}/wp-json/ftex/v1/users/password-reset`, { user_id: userId })
      .then(response => response.data)
      .catch(error => {
        Debug.log('Reset User Password Error in useContent hook', JSON.stringify(error));
        throw error;
      });
  };

  const deleteUser = (userId) => {
    return axios.post(`${API_URI}/wp-json/ftex/v1/users/delete`, { user_id: userId })
      .then(response => response)
      .catch(error => {
        Debug.log('Delete User Error in useContent hook', JSON.stringify(error));
        throw error;
      });
  };

  const updateUser = (userId, updatedUser) => {
    console.log('useContent updateUser', userId, updatedUser);
    const userData = {
      user_id: userId,
      username: updatedUser.username,
      first_name: updatedUser.first_name,
      last_name: updatedUser.last_name,
      email: updatedUser.email,
      language: updatedUser.language,
      store: updatedUser.store
    };
    return axios.post(`${API_URI}/wp-json/ftex/v1/users/update`, userData)
      .then(response => response.data)
      .catch(error => {
        Debug.log('Update User Error in useContent hook', JSON.stringify(error));
        throw error;
      });
  };

  return {
    errorCodeDocumentation,
    fetchUsersByBrand,
    fetchUserByEmail,
    fetchUsersByStore,
    createUser,
    resetUserPassword,
    updateUser,
    deleteUser,
    sendEmail
  };
};

export default useContent;

function processDocumentationData(data) {
  Debug.logger(1, 'codeDoc', data);
  let docs = {};
  for (let i = 0; i < data.length; i++) {
    let codeDoc = data[i];
    Debug.logger(1, 'codeDoc', i, data[i]);
    //check if this bike exists in docs object, else create
    if (!docs[codeDoc.acf.bike_model]) {
      docs[codeDoc.acf.bike_model] = [];
    }

    docs[codeDoc.acf.bike_model][codeDoc.acf.error_code] = {
      title: codeDoc.acf.code_title,
      description: codeDoc.acf.code_sescription,
      solutions: codeDoc.acf.solutions
    }
  }
  Debug.logger(1, 'useContent Hook processDocumentationData', docs);
  return docs;
}
