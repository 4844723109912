import React, { useState, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import AuthService from "../services/auth.service";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Debug from "../services/debug.js";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const ForgotPassword = ({setLoadingScreen}) => {
  const { t, i18n } = useTranslation();

  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const currentUser = await AuthService.getCurrentUser();
        if (currentUser) {
          setLoggedIn(true);
        }
      } catch (error) {
        Debug.logger(1, "Error checking user status:", error);
      }
    };

    checkLoginStatus();
  }, []);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();

    setMessage("");
    setLoadingScreen(true); // Use the setLoading function

    Debug.logger(1, 'calling authservice.login');

    AuthService.sendPasswordReset(email)
      .then(() => {
        setLoadingScreen(false);
        navigate("/");
      })
      .catch((error) => {
        setMessage(t("This email is not registered"));
        setLoadingScreen(false);
      });
  };

  return (
    <div className="container-fluid login-wrapper text-center">
      <div className="col-md-12">
        {loggedIn && navigate("/modules")}
        <img src="../imgs/ftex-logo-color.png" alt="FTEX Evionics Operating System" />
        <h1>
          {t('Diagnostics Tool')}
        </h1>
        <div className="card card-container">
          <Form onSubmit={handleForgotPassword}>
            <div className="form-group">
              <label htmlFor="email">{t('Email')}</label>
              <Input
                type="text"
                className="form-control"
                name="email"
                placeholder={t('Email')}
                value={email}
                onChange={onChangeEmail}
                validations={[required]}
              />
            </div>
            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <div className="form-group">
              <button
                className="btn btn-primary btn-big"
              >
                <span>{t('Send Password Reset')}</span>
              </button>
            </div>
          </Form>       
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;