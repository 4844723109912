import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const ReadParameterModal = ({
  show,
  onHide,
  primaryService,
  pin,
  nodeId,
  index,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener,
}) => {
  const [value, setValue] = useState(new Array(index.length).fill(null));
  const [readWritePassthrough, setReadWritePassthrough] = useState(null);
  const [data, setData] = useState([]);
  const [timeRange, setTimeRange] = useState(30);
  const intervalRef = useRef(null);

  const notificationCallback = (event) => {
    if (event) {
      const value = new Uint8Array(event.buffer);
      if (value[0] === 'P'.charCodeAt(0)) {
        const { msgIndex, msgSubIndex, canData } = decodePassthroughResponse(value);
        if (canData !== null) {
          index.forEach((idx, i) => {
            if (msgIndex === idx.index && msgSubIndex === idx.subIndex) {
              const timestamp = Date.now();
              setValue((prevValues) => {
                const newValues = [...prevValues];
                newValues[i] = canData;
                return newValues;
              });
              setData((prevData) => {
                const newData = [
                  ...prevData,
                  { timestamp, values: [...prevData[prevData.length - 1]?.values || new Array(index.length).fill(0)] }
                ];
                newData[newData.length - 1].values[i] = canData; // Update the specific index value
                return newData.filter(entry => timestamp - entry.timestamp <= 120000); // Keep entries from the last 120 seconds
              });
            }
          });
        }
      }
    }
  };

  const decodePassthroughResponse = (response) => {
    if (response.length !== 9) {
      console.error('Invalid response length:', response.length);
      return null;
    }

    const canHeader = response.slice(1, 5);
    const canDataBytes = response.slice(5, 9);
    const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

    const msgIndex = (canHeader[2] << 8) | canHeader[1];
    const msgSubIndex = canHeader[3];

    return { msgIndex, msgSubIndex, canData };
  };

  useEffect(() => {
    if (show && primaryService) {
      getSendPassthroughRW(primaryService).then((sendFunction) => {
        if (sendFunction) {
          setReadWritePassthrough(() => sendFunction);
        } else {
          console.error('Failed to initialize passthrough function');
        }
      });
    } else {
      setReadWritePassthrough(null);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [show, primaryService, getSendPassthroughRW]);

  useEffect(() => {
    if (!intervalRef.current && show && readWritePassthrough && pin && nodeId !== undefined && index.length) {
      intervalRef.current = setInterval(async () => {
        try {
          for (const idx of index) {
            await readWritePassthrough('read', pin, nodeId, idx.index, idx.subIndex);
          }
        } catch (error) {
          console.error('Error reading parameter:', error);
        }
      }, 500);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [show, readWritePassthrough, pin, nodeId, index]);

  useEffect(() => {
    if (show && primaryService) {
      stopNotificationListener(primaryService);
      startNotificationListener(primaryService, notificationCallback);
    }

    return () => {
      stopNotificationListener(primaryService);
    };
  }, [show, primaryService, startNotificationListener, stopNotificationListener]);

  useEffect(() => {
    if (!show) {
      setData([]);
      setValue(new Array(index.length).fill(null));
    }
  }, [show]);

  const now = Date.now();

  const filteredData = data
    .filter((entry) => now - entry.timestamp <= 120000) // Keep last 120 seconds of data
    .map((entry) => ({
      ...entry,
      timestamp: -(now - entry.timestamp) / 1000, // Convert to seconds back from now
    }));

  const chartData = {
    labels: filteredData.map((entry) => entry.timestamp),
    datasets: index.map((idx, i) => ({
      label: idx.title,
      data: filteredData.map((entry) => ({ x: entry.timestamp, y: entry.values[i] })),
      fill: false,
      borderColor: `hsl(${i * 360 / index.length}, 100%, 50%)`,
      tension: 0.4,
      pointRadius: 0,
    })),
  };

  const chartOptions = {
    animation: false,
    scales: {
      x: {
        type: 'linear',
        title: {
          display: true,
          text: 'Time (seconds ago)',
        },
        min: -timeRange,
        max: 0,
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Read Parameter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Reading value for NodeId: {nodeId}</p>
        <p>Current Values: {value.length > 0 ? value.join(', ') : 'Loading...'}</p>
        <Form.Group controlId="timeRangeSelect">
          <Form.Label>Select Time Range</Form.Label>
          <Form.Control as="select" value={timeRange} onChange={(e) => setTimeRange(Number(e.target.value))}>
            <option value={30}>Last 30 seconds</option>
            <option value={60}>Last 60 seconds</option>
            <option value={120}>Last 120 seconds</option>
          </Form.Control>
        </Form.Group>
        <div>
          <Line data={chartData} options={chartOptions} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReadParameterModal;
