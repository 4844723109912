import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ControllerSerialModal = ({ show, handleClose, bikeId, fetchBikeInfo, AnalyticsService, RestAPIService }) => {
  const { t } = useTranslation();
  const [newFtexSerial, setNewFtexSerial] = useState('');
  const [message, setMessage] = useState('');

  const handleControllerSerialChange = (e) => {
    e.preventDefault();

    setMessage("");
    if (!bikeId || !newFtexSerial) {
      return setMessage(t("Error updating controller serial number"));
    }
    RestAPIService.updateBikeController(bikeId, newFtexSerial).then(
      () => {
        let error = t("Controller Serial Change");
        let comment = t("Controller Serial Number has been updated to ") + newFtexSerial;
        let params = {
          type: "serial"
        };
        AnalyticsService.addRecord(bikeId, error, comment, params);
        setNewFtexSerial("");
        fetchBikeInfo();
        handleClose();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
      }
    );
  };

  const onChangeControllerSerial = (e) => {
    setNewFtexSerial(e.target.value);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Change Controller")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleControllerSerialChange}>
          <Form.Group controlId="controllerSerial">
            <Form.Label>{t('New Controller Serial')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('Controller Serial Number')}
              value={newFtexSerial}
              onChange={onChangeControllerSerial}
              required
            />
          </Form.Group>
          <Button type="submit" className="btn btn-primary btn-big">
            {t('Change Controller Serial')}
          </Button>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ControllerSerialModal;
