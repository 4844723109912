import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import ReactCodeInput from 'react-code-input';
import { io } from 'socket.io-client';

const RemoteDiagnosticsComponent = ({
  bikeSerial,
  t,
  pin,
  setPin,
  error,
  isPrimaryServiceAvailable,
  isConnected,
  pinVerified,
  batteryCommands,
  liveCommands,
  otherCommands,
  batteryStatusMessage,
  errors,
  handleSubmitOnly,
  brakeState,
  frontLightState,
  rearLightState,
  toggleFrontLight,
  toggleRearLight,
  getSendPassthroughRW,
  runDiagnosticsCommands,
  primaryService,
  stopUpdates,
  restartUpdatesOnly,
  stopNotificationListener,
  startNotificationListener,
  results,
  connectToDevice,
  setPinVerified,
  disconnectFromDevice
}) => {
  const [randomNumber, setRandomNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isServiceReady, setIsServiceReady] = useState(false);
  const [remoteConnectionEstablished, setRemoteConnectionEstablished] = useState(false);
  const [userInfo, setUserInfo] = useState({ username: '', brand: '', store: '' });
  const [debugMessage, setDebugMessage] = useState([]);

  const socketRef = useRef(null);
  const randomNumberRef = useRef(randomNumber);

  const handleConnect = async () => {
    try {
      await connectToDevice();
    } catch (err) {
      setErrorMessage(t('Failed to connect to the bike. Please try again.', err));
    }
  };

  useEffect(() => {
    if (primaryService) {
      setIsServiceReady(true);
    }
  }, [primaryService]);

  useEffect(() => {
    if (!randomNumber) {
      stopUpdates();
    } else {
      randomNumberRef.current = randomNumber;
    }
  }, [randomNumber]);

  const handlePinChange = (value) => {
    setPin(value);
  };

  const handleSubmitPin = async (e) => {
    e.preventDefault();

    const isValid = await handleSubmitOnly(e);
    if (isValid) {
      setPinVerified(true);
      const randomNum = Math.floor(10000 + Math.random() * 90000).toString();
      setRandomNumber(randomNum);
      setupSocket(randomNum);
      stopNotificationListener(primaryService);
      console.log('start notification listener');
      
      const startNotifications = () => {
        startNotificationListener(primaryService, (value, err) => {
          if (err) {
            console.log('err', err);
            stopNotificationListener(primaryService);
            startNotifications();
            return;
          }


          setDebugMessage(prevMessages => {
            const newMessage = (typeof err === 'object' && err !== null) ? JSON.stringify(err) : err;
            return [...prevMessages, newMessage];
          });
          // console.log('startNotificationListener callback', value);
          if (socketRef.current && socketRef.current.connected) {
            // console.log('emit', 'canMessage', randomNumberRef.current ? randomNumberRef.current : "no random number", value);
            socketRef.current.emit('canMessage', { pin: randomNumberRef.current, message: Array.from(value) });
          } else {
            setDebugMessage(prevMessages => {
              return [...prevMessages, 'Socket not connected, unable to emit message'];
            });
            console.log('Socket not connected, unable to emit message');
          }
        });
      };
      startNotifications();

      restartUpdatesOnly();
    } else {
      setErrorMessage(t('Invalid PIN. Please try again.'));
      stopNotificationListener(primaryService);
      stopUpdates();
      disconnectFromDevice();
    }
  };

  const setupSocket = (randomNum) => {
    socketRef.current = io('https://ftex-backend-73e39a9f925f.herokuapp.com/streaming', {
      transports: ['websocket', 'polling', 'flashsocket'],
      query: {
        clientType: 'connectAndVerify',
        pin: randomNum,
      }
    });

    socketRef.current.on('connect', () => {
      console.log('Socket connected');
      restartUpdatesOnly();
      socketRef.current.emit('connectRequest', {
        clientType: 'connectAndVerify',
        pin: randomNum,
      });
    });

    socketRef.current.on('status', (data) => {
      console.log('Socket status', data);
      if (data.message === 'Remote connection established') {
        setRemoteConnectionEstablished(true);
      }
      if (data.message === 'Remote connection disconnected') {
        setUserInfo({ username: '', brand: '', store: '' });
      }
    });

    socketRef.current.on('canWrite', (data) => {
      console.log('data canWrite', data);
    });

    socketRef.current.on('canMessage', (data) => {
      console.log('Received CAN message:', data.message);
      // Process the CAN message here
    });

    socketRef.current.on('infoMessage', (data) => {
      console.log('Received info message:', data.message);
      if (data.message?.type === 'userInfo') {
        userInfoMessage(data.message);
      }
      if (data.message?.type === 'refreshCommands') {
        runDiagnosticsCommands();
      }
      if (data.message?.type === 'action' && data.message?.action === 'toggleFrontLight') {
        toggleFrontLight();
      }
      if (data.message?.type === 'action' && data.message?.action === 'toggleRearLight') {
        toggleRearLight();
      }
    });

    socketRef.current.on('disconnect', () => {
      console.log('Socket disconnected');
      handleConnectionDrop();
    });

    socketRef.current.on('error', (err) => {
      console.error('Socket error', err);
      setErrorMessage(t('Connection error. Please try again.'));
    });
  };

  const userInfoMessage = (data) => {
    setUserInfo({
      username: data.username,
      brand: data.brand,
      store: data.store
    });
  }

  const handleDisconnect = async () => {
    try {
      if (socketRef.current) {
        socketRef.current.close();
      }
      stopUpdates();
      stopNotificationListener(primaryService);
      await disconnectFromDevice();
      resetState();
    } catch (err) {
      setErrorMessage(t('Failed to disconnect from the bike. Please try again.'));
    }
  };

  const handleConnectionDrop = async () => {
    resetState();
  };

  const resetState = () => {
    setPinVerified(false);
    setPin('');
    setRandomNumber('');
    setRemoteConnectionEstablished(false);
    setErrorMessage(null);
    setUserInfo({ username: '', brand: '', store: '' });
    stopNotificationListener(primaryService);
    stopUpdates();
    disconnectFromDevice();
  };

  const sendCanMessage = (message) => {
    if (socketRef.current) {
      socketRef.current.emit('canMessage', { pin: randomNumberRef.current, message });
      console.log('Sent CAN message:', message);
    }
  };

  const sendInfoMessage = (message) => {
    if (socketRef.current) {
      socketRef.current.emit('infoMessage', { pin: randomNumberRef.current, message });
      console.log('Sent info message:', message);
    }
  };

  return (
    <div className="container-fluid h-100 bike-detail live-bike-detail">
      <div className="row">
        <div className="col text-center">
          <h3>{t("Remote Connection")}</h3>
        </div>
      </div>
      <div className="row text-center d-flex align-items-center justify-content-center h-50">
        {!isConnected ? (
          <div className="col text-center">
            <div className="pin-entry">
              <h4>{t("Connect to your bike")}</h4>
              <button onClick={handleConnect} className="btn btn-secondary">{t('Connect')}</button>
            </div>
          </div>
        ) : (
          <>
            {isConnected && isServiceReady && !pinVerified ? (
              <div className="col text-center">
                <div className="pin-entry">
                  <h4>{t("PIN Required")}</h4>
                  <form onSubmit={handleSubmitPin}>
                    <div className="form-group">
                      <ReactCodeInput
                        type="number"
                        fields={4}
                        id="pin"
                        value={pin}
                        onChange={handlePinChange}
                      />
                      <label htmlFor="pin">{t('The PIN is unique to each bicycle and is held by the owner of the vehicle. This 4-digit code is necessary to access the information.')}</label>
                    </div>
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    <div className="row">
                      <div className="col-12">
                        <button type="submit" className="btn btn-secondary">{t('Submit')}</button>
                      </div>
                      <div className="col-12">
                        <button onClick={handleDisconnect} type="button" className="btn btn-danger mt-2">{t('Disconnect')}</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div className="col">
                <div className="mt-4 text-center">
                  <h4>{t('PIN is correct')}</h4>
                  <p className="text-center">{t('Your verification code is:')} <strong>{randomNumber}</strong></p>
                  <button onClick={handleDisconnect} className="btn btn-danger mt-3">{t('Disconnect')}</button>
                  <p className="text-center mt-2"><strong>Sending: </strong>{debugMessage}</p>
                  {remoteConnectionEstablished && userInfo.username && (
                    <div className="mt-3">
                      <h5>{t('Connection with:')}</h5>
                     {userInfo.username}
{/*                      <p>{t('Brand')}: {userInfo.brand}</p>
                      <p>{t('Store')}: {userInfo.store}</p>
*/}                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RemoteDiagnosticsComponent;
