import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AddUserModal from './AddUserModal';
import AddStoreModal from './AddStoreModal';
import { useTranslation } from 'react-i18next';

const SideMenu = ({ userGroup, listUsersByBrand, fetchStoresByBrand }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAddStoreModal, setShowAddStoreModal] = useState(false);

  const handleAddUserClick = () => setShowAddUserModal(true);
  const handleAddStoreClick = () => setShowAddStoreModal(true);

  const handleBackClick = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    if (pathParts.length > 1) {
      const parentPath = '/' + pathParts.slice(0, -1).join('/');
      navigate(parentPath);
    } else {
      navigate('/'); // Or navigate to a default route
    }
  };

  return (
    <div className="ftex-side-menu">
      <ul>
        <li>
          {t('Menu')}
        </li>
        <li className={location.pathname === '/users' ? 'active' : ''}>
          <Link className="overview" to="/users">{t('Overview')}</Link>
        </li>
        <li className={location.pathname.startsWith('/users/all') ? 'active' : ''}>
          <Link className="user" to="/users/all">{t('Users')}</Link>
        </li>
        <li className={location.pathname.startsWith('/users/stores') ? 'active' : ''}>
          <Link className="flag" to="/users/stores">{t('Stores')}</Link>
        </li>
      </ul>
      <button className="btn btn-block btn-big btn-primary" onClick={handleAddUserClick}>
        {t('Add User')}
        <img src="/imgs/sidebar/icon-user.svg" alt={t('Add User')} />
      </button>
      <button className="btn btn-block btn-big btn-secondary" onClick={handleAddStoreClick}>
        {t('Add Store')}
        <img src="/imgs/sidebar/icon-flag.svg" alt={t('Add Store')} />
      </button>
      <button className="btn btn-block btn-big" onClick={handleBackClick}>
        {t('Back')}
      </button>

      <AddUserModal
        show={showAddUserModal}
        onHide={() => setShowAddUserModal(false)}
        userGroup={userGroup}
        listUsersByBrand={listUsersByBrand}
      />
      <AddStoreModal
        show={showAddStoreModal}
        onHide={() => setShowAddStoreModal(false)}
        userGroup={userGroup}
        fetchStoresByBrand={fetchStoresByBrand}
      />
    </div>
  );
};

export default SideMenu;
