import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import useCANStream from '../../../hooks/useCANStream.hook'; // Ensure the correct path
import { useTranslation } from 'react-i18next';

const FrontLightTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const nodeId = 0x00000601;
  const lightIndex = 0x2021;
  const lightSubIndex = 0x00;

  const timeAvailable = 20;

  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("The front light test has started. Please observe the front light.");
  const [countdown, setCountdown] = useState(timeAvailable);
  const [isWriting, setIsWriting] = useState(false);
  const { t } = useTranslation();

  const currentValue = useRef([]);
  const initialValueChecked = useRef(false);
  const startTime = useRef(null);
  const streamStarted = useRef(false);

  const { writeValue } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    [],
    () => {}
  );

  useEffect(() => {
    let lightState = 0;

    const intervalId = setInterval(() => {
      if (testRunning) {
        const elapsedSeconds = Math.floor((new Date() - startTime.current)/1000);
        setCountdown((timeAvailable - elapsedSeconds));
        writeLightState(lightState % 2 === 0 ? 1 : 0);
        lightState++;
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, [testRunning, startTime]);

  useEffect(() => {
      restartTest();
  }, []);

  useEffect(() => {
    if (countdown <= 0 && testRunning) {
      handleTestStop(false);
    }
  }, [countdown, testRunning]);

  const writeLightState = async (state) => {
    console.log('writting light', state);
    writeValue(nodeId, lightIndex, lightSubIndex, state, true);
  };

  const handleTestStop = (result) => {
    setCountdown(0);
    setInstruction(null);
    setTestResult(result);
    setTestRunning(false);
    setTestFinished(true);
  };

  const restartTest = () => {
    console.log('restartTest');
    setTestRunning(true);
    setTestResult(null);
    setInstruction("The front light test has started. Please observe the front light.");
    setCountdown(timeAvailable);
    setTestFinished(false);
    startTime.current = new Date();
  };


  const lightDetected = () => {
    handleTestStop(true);
  }

  const lightNotDetected = () => {
    handleTestStop(false);
  }

  return (
    <div>
      <h1>Step {stepNumber} - Front Light Test</h1>
      <p className="instructions">{instruction}</p>
      {testFinished && testResult === false && (
        <div className="fail-title">
          <h3>Test Failed</h3>
          You did not see the front light flashing, which means that the front light is either not functioning correctly or not plugged in. Otherwise it could be a controller issue.
        </div>
      )}
      {testFinished && testResult === true && (
        <div className="success-title">
          <h3>Test Successful</h3>
          The front light was successfully flashing.
        </div>
      )}
      {testFinished && (
        <div className="test-summary">
          <p>
            <img src="/imgs/icons/icon-warning.svg" alt="Warning" />
            A summary of the entire test will be displayed at the end.
          </p>
        </div>
      )}
      {!testFinished && (
        <div>
          <span className="countdown">Time remaining: {Math.floor(countdown)} seconds</span>
          <ProgressBar
            now={(timeAvailable - countdown) * 5}
            className={countdown > 0 ? 'in-progress' : (countdown <= 0 && testResult === false ? 'timeout' : '')}
          />
        </div>
      )}
      {testRunning === true && (
        <div className="row">
          <div className="col">
            <button className="btn btn-secondary" onClick={() => lightDetected()}>Click if you see the light flashing</button>
          </div>
          <div className="col">
            <button className="btn btn-danger" onClick={() => lightNotDetected()}>Click if you don't see the light flashing</button>
          </div>
        </div>
      )}
      {testResult === true && (
        <div>
          <Button onClick={() => onResult({ 
            result: true, 
            data: "Front Light Detected",
            message: "The front light was successfully flashing." 
          })}>Next</Button>
        </div>
      )}
      {testResult === false && (
        <div>
          <Button className="btn btn-large btn-primary mr-4" onClick={restartTest}>Restart Test</Button>
          <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
            result: false, 
            data: "Front Light Not Detected",
            message: "You did not see the front light flashing, which means that the front light is either not functioning correctly or not plugged in. Otherwise it could be a controller issue." 
          })}>Skip Test</Button>
        </div>
      )}
    </div>
  );
};

export default FrontLightTest;
