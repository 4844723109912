import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddBikeModal from './AddBikeModal';

const SideMenu = ({ userGroup, listBikesByBrand }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [showAddBikeModal, setShowAddBikeModal] = useState(false);

  const handleAddBikeClick = () => setShowAddBikeModal(true);

  const handleBackClick = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    if (pathParts.length > 1) {
      const parentPath = '/' + pathParts.slice(0, -1).join('/');
      navigate(parentPath);
    } else {
      navigate('/'); // Or navigate to a default route
    }
  };

  useEffect(() => {
  }, [userGroup]);

  return (
    <div className="ftex-side-menu">
      <ul>
        <li>{t("Menu")}</li>
        <li className={location.pathname === '/fleet' ? 'active' : ''}>
          <Link className="overview" to="/fleet">{t("Overview")}</Link>
        </li>
        <li className={location.pathname === '/fleet/all' || location.pathname.startsWith('/fleet/bike') ? 'active' : ''}>
          <Link className="bike" to="/fleet/all">{t("Bikes")}</Link>
        </li>
        <li className={location.pathname === '/fleet/trips' ? 'active' : ''}>
          <Link className="trip" to="/fleet/trips">{t("Trips")}</Link>
        </li>
      </ul>
      <button className="btn btn-block btn-big btn-secondary" onClick={handleAddBikeClick}>
        {t("Add Bike")}
        <img src="/imgs/icons/icon-bike.svg" alt={t("Add Bike")} />
      </button>
      <button className="btn btn-block btn-big" onClick={handleBackClick}>
        {t("Back")}
      </button>

      <AddBikeModal
        show={showAddBikeModal}
        onHide={() => setShowAddBikeModal(false)}
        userGroup={userGroup}
        listBikesByBrand={listBikesByBrand}
      />
    </div>
  );
};

export default SideMenu;
