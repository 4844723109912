import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import RestAPIService from '../../services/rest-api.service';

// Default Leaflet marker icon
import 'leaflet/dist/leaflet.css';

// Fix Leaflet's default icon paths
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const MapComponent = ({ stores }) => {
  const [storeLocations, setStoreLocations] = useState([]);
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]);
  const [mapZoom, setMapZoom] = useState(13);

  useEffect(() => {
    const fetchStoreLocations = async () => {
      try {
        const locations = await Promise.all(
          stores.map(async (store) => {
            if (store.lat && store.lng) {
              return store;
            }

            const fullAddress = `${store.postalCode}`;
            console.log(`Fetching coordinates for postal code: ${fullAddress}`);
            try {
              const response = await RestAPIService.fetchLatLng(fullAddress);
              const { lat, lng } = response;
              if (lat !== undefined && lng !== undefined) {
                console.log(`Coordinates for ${fullAddress}: (${lat}, ${lng})`);
                return { ...store, lat, lng };
              }
              console.error(`No valid coordinates found for postal code: ${fullAddress}`);
              return null;
            } catch (error) {
              console.error(`Error fetching coordinates for postal code: ${fullAddress}`, error);
              return null;
            }
          })
        );
        const validLocations = locations.filter(location => location !== null);
        setStoreLocations(validLocations);

        if (validLocations.length > 0) {
          const bounds = L.latLngBounds(validLocations.map(loc => [loc.lat, loc.lng]));
          setMapCenter(bounds.getCenter());
          setMapZoom(null); // Disable zoom setting, let fitBounds handle it
        }
      } catch (error) {
        console.error("Error fetching store locations:", error);
      }
    };

    fetchStoreLocations();
  }, [stores]);

  return (
    <MapContainer 
      center={mapCenter} 
      zoom={mapZoom} 
      style={{ height: "350px", width: "100%" }} 
      scrollWheelZoom={false} 
      doubleClickZoom={false} 
      dragging={false}
      zoomControl={true}
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
      />
      {storeLocations.map((store) => (
        <Marker key={store._id} position={[store.lat, store.lng]}>
          <Popup>
            <div>
              <h3>{store.name || store.email}</h3>
              <p>{store.postalCode}</p>
            </div>
          </Popup>
        </Marker>
      ))}
      <MapUpdater bounds={storeLocations.map(loc => [loc.lat, loc.lng])} />
    </MapContainer>
  );
};

const MapUpdater = ({ bounds }) => {
  const map = useMap();
  
  useEffect(() => {
    if (bounds.length > 0) {
      const latLngBounds = L.latLngBounds(bounds);
      map.fitBounds(latLngBounds, { padding: [50, 50] }); // Add padding to ensure markers are not at the edge
    }
  }, [bounds, map]);

  return null;
};

export default MapComponent;
