import React from 'react';
import { useDebug } from '../hooks/useDebugContext';
import { useTranslation } from 'react-i18next';

const ToggleButton = () => {
  const { debugBLE, setDebugBLE } = useDebug();
  const { t } = useTranslation();

  const handleToggle = () => {
    setDebugBLE(prevState => {
      if (prevState) {
        window.location.href = '/';
      }
      return !prevState;
    });
  };

  return (
    <div className="toggle-button" onClick={handleToggle}>
      <span className="toggle-label">{debugBLE ? t('Training Mode On') : t('Training Mode Off')}</span>
      <div className={`toggle-switch ${debugBLE ? 'active' : ''}`}>
        <div className="toggle-knob"></div>
      </div>
    </div>
  );
};

export default ToggleButton;
