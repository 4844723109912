import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Debug from '../../services/debug.js';
import AnalyticsService from "../../services/analytics.service";

const SolutionComponent = ({ debugClearErrorFlag }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);

  const {
    bikeSerial,
    bikeBrand,
    bikeModel,
    bikeYear,
    ftexSerial,
    batterySerial,
    errorCode,
    solutionIndex,
    solution
  } = location.state || {};

  useEffect(() => {
    if (!solution) {
      navigate('/diagnostics/bike');
    }
  }, [solution, navigate]);

  const previousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const nextStep = () => {
    if (currentStep < (solution?.steps?.length || 0)) {
      setCurrentStep(currentStep + 1);
    }
  };

  const fixedBike = async () => {
    let solutionText = new DOMParser().parseFromString(solution.steps[currentStep - 1].description, 'text/html').body.textContent || '';

    let error = "Error Code:" + " " + errorCode;
    let comment = "Error Code" + " " + errorCode + " fixed with solution:" + " " + solution.solution_description + ". Fixed at step " + solutionText;
    let params = {
      type: "errorFixed",
      error: errorCode,
      solution: parseInt(solutionIndex, 10),
      step: currentStep,
    };

    AnalyticsService.addRecord(bikeSerial, error, comment, params);

    await debugClearErrorFlag();
    navigate('/diagnostics/bike');
  };

  if (!solution) {
    return <div>{t('No solution found')}</div>;
  }

  return (
    <div className="container-fluid bike-detail">
      <div className="row">
        <div className="col-6">
          <h3>{t("Repair Guide")}</h3>
          <h4>{t("Step-by-Step Solution")}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          <div className="bike-detail-summary">
            {bikeBrand && bikeModel && bikeYear && (
              <>
                <h2>
                  <span>{bikeBrand}</span> {bikeModel} ({bikeYear})&nbsp;-&nbsp;
                </h2>
                <h3>{bikeSerial}</h3>
                <div className="row info-box-container">
                  <div className="col info-box">
                    <h5>{t("Controller Serial:")}</h5>
                    <span>{ftexSerial}</span>
                  </div>
                  <div className="col info-box">
                    <h5>{t("Battery Serial:")}</h5>
                    <span>{batterySerial}</span>
                  </div>
                  <div className="col info-box">
                    <h5>{t("Model:")}</h5>
                    <span>{bikeModel}</span>
                  </div>
                  <div className="col info-box">
                    <h5>{t("Year:")}</h5>
                    <span>{bikeYear}</span>
                  </div>
                  <div className="col info-box">
                    <h5>{t("Bike Serial:")}</h5>
                    <span>{bikeSerial}</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 col-lg-7">
          <div className="row solution-numbering mt-4 mb-4">
            {solution.steps.map((step, index) => (
              <React.Fragment key={index}>
                <div className={`col number ${index < currentStep ? 'active' : ''}`}>
                  <span>{index + 1}</span>
                </div>
                {index < solution.steps.length - 1 && (
                  <div className={`col line ${index < currentStep - 1 ? 'active' : ''}`}></div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="col-md-12 col-lg-10">
          <h1 className="mb-4">
            {t("Step")} {currentStep}/{solution.steps.length}
          </h1>
          <div className="mb-4" dangerouslySetInnerHTML={{ __html: solution.steps[currentStep - 1].description }}></div>
        </div>
      </div>
      <div className="row fleet-module mb-4">
        <div className="col-md-10 steps-buttons">
          <div className="row justify-content-center">
            <div className="col-md-4">
              {currentStep > 1 && (
                <button className="btn btn-big btn-secondary" onClick={previousStep}>
                  {t("Previous Step")}
                </button>
              )}
            </div>
            <div className="col-md-4 d-flex justify-content-center">
              <button className="btn btn-big btn-success" onClick={fixedBike}>
                {t("Problem Solved")}
              </button>
            </div>
            <div className="col-md-4 d-flex align-items-end flex-column">
              {currentStep < (solution.steps?.length || 0) && (
                <button className="btn btn-big btn-primary" onClick={nextStep}>
                  {t("Next Step")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionComponent;
