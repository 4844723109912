import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const BatterySerialModal = ({ show, handleClose, bikeId, fetchBikeInfo, AnalyticsService, RestAPIService }) => {
  const { t } = useTranslation();
  const [newBatterySerial, setNewBatterySerial] = useState('');
  const [message, setMessage] = useState('');
  const [showForceModal, setShowForceModal] = useState(false);

  const handleBatterySerialChange = (e) => {
    e.preventDefault();

    setMessage("");
    if (!bikeId || !newBatterySerial) {
      return setMessage(t("Error updating battery serial number"));
    }

    RestAPIService.updateBikeBattery(bikeId, newBatterySerial)
      .then((response) => {
        if (response instanceof Error || response?.status >= 400) {
          console.log('RESPONSE battery update', response);
          const resMessage =
            response.response?.data?.message ||
            response.message ||
            response.toString();

          if (response.response?.status === 409) {
            setShowForceModal(true);
          } else {
            setMessage(resMessage);
          }
          return;
        }
        
        let error = t("Battery Serial Change");
        let comment = t("Battery Serial Number has been updated to ") + newBatterySerial;
        let params = {
          type: "serial"
        };
        AnalyticsService.addRecord(bikeId, error, comment, params);
        setNewBatterySerial("");
        fetchBikeInfo();
        handleClose();
      })
      .catch((error) => {
        console.log('battery change error', error);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 409) {
          setShowForceModal(true);
        } else {
          setMessage(resMessage);
        }
      });
  };

  const handleForceRemoveBattery = async () => {
    try {
      await RestAPIService.removeBatteryFromBike(newBatterySerial);
      await RestAPIService.updateBikeBattery(bikeId, newBatterySerial);
      let error = t("Battery Serial Change");
      let comment = t("Battery Serial Number has been updated to ") + newBatterySerial;
      let params = {
        type: "serial"
      };
      AnalyticsService.addRecord(bikeId, error, comment, params);
      setNewBatterySerial("");
      fetchBikeInfo();
      setShowForceModal(false);
      handleClose();
    } catch (error) {
      setMessage(t("Error forcing battery update: ") + error.toString());
    }
  };

  const onChangeBatterySerial = (e) => {
    setNewBatterySerial(e.target.value);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Change Battery")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleBatterySerialChange}>
            <Form.Group controlId="batterySerial">
              <Form.Label>{t('New Battery Serial')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('Battery Serial Number')}
                value={newBatterySerial}
                onChange={onChangeBatterySerial}
                required
              />
            </Form.Group>
            <Button type="submit" className="btn btn-primary btn-big">
              {t('Change Battery Serial')}
            </Button>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showForceModal} onHide={() => setShowForceModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Battery Serial Conflict')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('The battery serial is already assigned to another bike. Do you want to force remove the battery from the other bike and assign it to this one?')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowForceModal(false)}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleForceRemoveBattery}>
            {t('Force Update')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BatterySerialModal;
