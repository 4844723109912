import React, { useEffect, useState } from 'react';
import useContent from '../../hooks/useContent.hook';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const GuidesComponent = ({ userGroup }) => {
  const { t } = useTranslation();
  const { errorCodeDocumentation } = useContent();
  const [errorDocs, setErrorDocs] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (errorCodeDocumentation) {
      setErrorDocs(errorCodeDocumentation);
    }
  }, [errorCodeDocumentation]);

  useEffect(() => {
    // Handle userGroup change if needed
  }, [userGroup]);

  const handleViewSolution = (solution) => {
    navigate('/diagnostics/guides/viewSolution', { state: { solution } });
  };

  const renderSolutions = (solutions) => {
    return (
      <ul>
        {solutions.length === 1 ? (
          <li>
            <button className="btn btn-small btn-primary" onClick={() => handleViewSolution(solutions[0])}>{t('View Solution')}</button>
          </li>
        ) : (
          solutions.map((solution, index) => (
            <li key={index}>
              <button className="btn btn-small btn-primary" onClick={() => handleViewSolution(solution)}>{t('Solution')} {index + 1}</button>
            </li>
          ))
        )}
      </ul>
    );
  };

  const renderErrors = (errors) => {
    return (
      <ul className="row">
        {errors.map((errorDoc, index) => (
          <li key={index} className="col-sm-6 col-md-3 col-xl-3">
            <div className="error-code-card mb-3">
              <h4>{t('Error Code')} - <span>{index}</span></h4>
              <p>{errorDoc.title}</p>
              {renderSolutions(errorDoc.solutions)}
              <img src="/imgs/icons/icon-guide.svg" alt={t('Guide')} />
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const renderErrorGuides = () => {
    return Object.keys(errorDocs).map((model, index) => {
      const errors = errorDocs[model];
      return (
        <li key={index}>
          <h3 className="mt-4 mb-3">{model}</h3>
          <div className="row">
            <div className="col-12">
              {Array.isArray(errors) ? renderErrors(errors) : <p>{t('No errors available for this model.')}</p>}
            </div>
          </div>
        </li>
      );
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <h3>{userGroup}</h3>
          <h4>{t('Error Code Guide')}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12 code-guides">
          <ul>
            {errorDocs && Object.keys(errorDocs).length > 0 ? (
              renderErrorGuides()
            ) : (
              <p>{t('No error guides available for the selected brand.')}</p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GuidesComponent;
