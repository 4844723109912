import React, { useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { DebugProvider, useDebug } from './hooks/useDebugContext';
import './i18n';

const container = document.getElementById("root");
const root = createRoot(container);

const Main = () => {
  const containerRef = useRef(container);

  return (
    <DebugProvider containerRef={containerRef}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </DebugProvider>
  );
};

root.render(<Main />);
