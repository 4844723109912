import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Debug from '../../services/debug.js';
import RestAPIService from '../../services/rest-api.service';
import useBluetooth from '../../hooks/useBluetooth.hook';
import useContent from '../../hooks/useContent.hook';
import { format } from 'date-fns';
import AddCommentModal from '../fleet/AddCommentModal';
import BatterySerialModal from '../fleet/BatterySerialModal';
import ControllerSerialModal from '../fleet/ControllerSerialModal';
import ReactCodeInput from 'react-code-input';
import GraphModal from './graph.component';
import { Form } from 'react-bootstrap';

const MaintenanceComponent = ({
  bikeSerial,
  t,
  pin,
  setPin,
  error,
  isPrimaryServiceAvailable,
  isConnected,
  pinVerified,
  results,
  batteryCommands,
  liveCommands,
  otherCommands,
  batteryStatusMessage,
  errors,
  handleSubmit,
  brakeState,
  frontLightState,
  rearLightState,
  toggleFrontLight,
  toggleRearLight,
  getSendPassthroughRW,
  primaryService,
  stopUpdates,
  restartUpdates,
  stopNotificationListener,
  startNotificationListener,
}) => {
  const { getErrorCodeByBLE } = useBluetooth();
  const [batterySerial, setBatterySerial] = useState('');
  const [ftexSerial, setFtexSerial] = useState('');
  const [bikeBrand, setBikeBrand] = useState('');
  const [bikeModel, setBikeModel] = useState('');
  const [bikeYear, setBikeYear] = useState('');
  const [bikeRecords, setBikeRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedErrorCode, setSelectedErrorCode] = useState('');
  const [selectedSource, setSelectedSource] = useState('');
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showBatteryModal, setShowBatteryModal] = useState(false);
  const [showControllerModal, setShowControllerModal] = useState(false);
  const [bikeErrors, setBikeErrors] = useState([]);
  const [errorSolutions, setErrorSolutions] = useState({});
  const { errorCodeDocumentation } = useContent();
  const navigate = useNavigate();

  const [graphModalShowTorqueCadence, setGraphModalShowTorqueCadence] = useState(false);
  const [graphModalShowPower, setGraphModalShowPower] = useState(false);
  const [testModalShow, setTestModalShow] = useState(false);
  const [key, setKey] = useState(0);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(null);
  const [graphModalShow, setGraphModalShow] = useState(false);

  const dropdownOptions = [
    { category: 'Live', subName: 'Vehicle speed with decimals', index: 0x2000, subIndex: 0x00, description: 'Speed of the vehicle, expressed as a 3-digits number for speed with 1 decimals. For example: - if this parameter returns 234, the speed is 23.4 km/h - if this parameter returns 1, the speed is 0.1 km/h - if this parameter return 34, the speed is 3.4 km/h - if this parameter is 999, the speed is 99.9 km/h', unit: 'km/h x 10 (ie. hectometer/s)' },
    { category: 'Live', subName: 'Real-time total power', index: 0x2001, subIndex: 0x00, description: 'Real-time approximate total power of the vehicle (ie. DC Power). Note: screens/IoT should use this value if they want to display a power value, because this value is more relevant to the user.', unit: 'watts' },
    { category: 'Live', subName: 'Requested motor torque', index: 0x2001, subIndex: 0x01, description: 'Real-time approximate requested torque on the motor. Note: this is a torque setpoint, it may differ from the actual motor mechanical torque.', unit: 'cNm' },
    { category: 'Live', subName: 'Mechanical power in motor', index: 0x2001, subIndex: 0x02, description: 'Real-time approximate mechanical power in the motor (ie. torque x speed).', unit: 'watts' },
    { category: 'Live', subName: 'Active errors', index: 0x2006, subIndex: 0x00, description: 'Get the active errors currently on the controller. 0 means no error. see the FTEX Errors Definition tab', unit: '' },
    { category: 'Live', subName: 'Motor temperature', index: 0x200B, subIndex: 0x00, description: 'Temperature of the motor connected to the controller (if available)', unit: 'Celsius' },
    { category: 'Live', subName: 'Controller temperature', index: 0x200C, subIndex: 0x00, description: 'Current temperature of the controller (master)', unit: 'Celsius' },
    { category: 'Live', subName: 'Throttle ADC value', index: 0x2027, subIndex: 0x00, description: 'Current ADC value of the throttle (if using an analog throttle). The value is the raw ADC value scaled between 0-65535  (0 means 0V, 65535 means 5V)', unit: '%' },
    { category: 'Live', subName: 'Throttle value', index: 0x2027, subIndex: 0x01, description: 'Get or set the value of the throttle, in % x 100. For example, setting this to: - 3476 means, 34.76% of throttle - 9995 means 99.95% of throttle - 10000 means 100.00% of throttle (ie. max throttle) In the case of missing heartbeats from the HMI (see communication specification tab), the controller will disable the throttle. Setting the throttle is meant to be used in - diagnostics tool scenarios - screens that have the throttle connected into them and that are passing along the throttle value to the controller.', unit: '%' },
    { category: 'Live', subName: 'Pedaling cadence', index: 0x2024, subIndex: 0x00, description: 'Current pedaling cadence from the PAS sensor, in RPM. Positive means, pedaling forward. Negative means, pedaling backwards. Only forward pedaling is supported for now.', unit: 'rpm' },
    { category: 'Live', subName: 'Torque detected on pedals', index: 0x2024, subIndex: 0x01, description: 'Current torque detected on the pedals (if torque sensor present)', unit: '%' },
    { category: 'Live', subName: 'Brake state', index: 0x202C, subIndex: 0x00, description: 'The current brake state. 0 = not pressed. 1 = brake pressed. For the time being, this parameter assumes that the brake is not an analog brake.', unit: 'uint8_t' },
    { category: 'Live', subName: 'PAS levels', index: 0x2003, subIndex: 0x00, description: 'Get or set the current PAS level of the vehicle. Note: setting to PAS level 15 activates walk mode.', unit: 'uint8_t' },
    { category: 'Live', subName: 'Front light state', index: 0x2021, subIndex: 0x00, description: 'Manually get or set the front light state. 0 means off, 1 means on.', unit: 'uint8_t' },
    { category: 'Live', subName: 'Rear light state', index: 0x2022, subIndex: 0x00, description: 'Manually get or set the rear light state. 0 means off, 1 means on.', unit: 'uint8_t' },
  ];

  const motorTempResult = results.find(result => result.index === 0x200B && result.subIndex === 0x00);
  const controllerTempResult = results.find(result => result.index === 0x200C && result.subIndex === 0x00);
  const currentPASResult = results.find(result => result.index === 0x2003 && result.subIndex === 0x00);

  const motorTemperatureMessage = motorTempResult && motorTempResult.latestValue >= -10 && motorTempResult.latestValue <= 120
    ? { message: t('Motor temperature is within range'), color: 'green' }
    : { message: t('There is a problem with the motor temp'), color: 'red' };

  const controllerTemperatureMessage = controllerTempResult && controllerTempResult.latestValue >= -10 && controllerTempResult.latestValue <= 80
    ? { message: t('Controller temperature is within range'), color: 'green' }
    : { message: t('Controller temperature outside of normal range, controller might need to be replaced'), color: 'red' };

  const fetchBikeInfo = async () => {
    try {
      let bikeInfo = await RestAPIService.getBike(bikeSerial);
      Debug.logger(2, 'bikeInfo', bikeInfo);
      setBatterySerial(bikeInfo.battery_serial);
      setFtexSerial(bikeInfo.ftex_serial);
      setBikeBrand(bikeInfo.brand);
      setBikeModel(bikeInfo.model);
      setBikeYear(bikeInfo.year);
    } catch (error) {
      Debug.logger(2, 'Error fetching bike info from Rest API Server', error);
    }
  };

  const fetchBikeRecords = async () => {
    try {
      let results = await RestAPIService.getBikeRecords(bikeSerial);
      Debug.logger(1, 'bike getBikeRecords', results);
      setBikeRecords(results);
      setFilteredRecords(results);
    } catch (error) {
      console.error('Error fetching bike records:', error);
    }
  };

  useEffect(() => {
    Debug.logger(2,'FETCH FETCH', bikeSerial);
    if (bikeSerial && pinVerified) {
      fetchBikeInfo();
      fetchBikeRecords();
    }
  }, [bikeSerial, pinVerified]);

  const handlePinChange = (value) => {
    setPin(value);
  };

  const handleOpenGraphModalTorqueCadence = () => {
    stopUpdates();
    setKey(key + 1);
    setGraphModalShowTorqueCadence(true);
  };

  const handleCloseGraphModalTorqueCadence = () => {
    setGraphModalShowTorqueCadence(false);
    setKey(key + 1);
    restartUpdates();
  };

  const handleOpenGraphModalPower = () => {
    stopUpdates();
    setKey(key + 1);
    setGraphModalShowPower(true);
  };

  const handleCloseGraphModalPower = () => {
    setGraphModalShowPower(false);
    setKey(key + 1);
    restartUpdates();
  };

  const handleOpenTestModal = () => {
    stopUpdates();
    setKey(key + 1);
    setTestModalShow(true); 
  };

  const handleCloseTestModal = () => {
    setTestModalShow(false); 
    setKey(key + 1);
    restartUpdates();
  };

  const handleDropdownChange = (event) => {
    const selectedOption = dropdownOptions.find(option => option.subName === event);
    if (selectedOption) {
      setSelectedDropdownOption(selectedOption);
    }
  };

  const handleOpenGraphModal = () => {
    stopUpdates();
    setGraphModalShow(true);
  };

  const handleCloseGraphModal = () => {
    setGraphModalShow(false);
    restartUpdates();
  };

  return (
    <div className="container-fluid h-100 bike-detail">
      <div className="row">
        <div className="col-6">
          <h3>{t("Maintenance")}</h3>
          <h4>{t("And Bike Debug")}</h4>
        </div>
      </div>
      {isPrimaryServiceAvailable && !pinVerified ? (
        <div className="row text-center d-flex align-items-center justify-content-center h-100">
          <div className="col-3 text-left">
            <div className="pin-entry">
              <h4>{t("PIN Required")}</h4>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <ReactCodeInput
                    type="number"
                    fields={4}
                    id="pin"
                    value={pin}
                    onChange={handlePinChange}
                  />
                  <label htmlFor="pin">{t('The PIN is unique to each bicycle and is held by the owner of the vehicle. This 4-digit code is necessary to access the information.')}</label>
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                <button type="submit" className="btn btn-block btn-primary">{t('Connect')}</button>
              </form>
            </div>
          </div>
        </div>
      ) : !isPrimaryServiceAvailable && !pinVerified ? (
        <div className="row text-center d-flex align-items-center justify-content-center h-100">
          <div className="col-3">
            <p>{t('Connecting to primary service...')}</p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-9">
            <div className="bike-detail-summary">
              {bikeBrand && bikeModel && bikeYear && (
                <>
                  <h2>
                    <span>{bikeBrand}</span> {bikeModel} ({bikeYear})&nbsp;-&nbsp;
                  </h2>
                  <h3>{bikeSerial}</h3>
                  <div className="row info-box-container">
                    <div className="col info-box">
                      <h5>{t('Controller Serial')}:</h5>
                      <span>{ftexSerial}</span>
                    </div>
                    <div className="col info-box">
                      <h5>{t('Battery Serial')}:</h5>
                      <span>{batterySerial}</span>
                      <button className="btn btn-danger btn-block" onClick={() => setShowBatteryModal(true)}>{t('Edit')}</button>
                    </div>
                    <div className="col info-box">
                      <h5>{t('Model')}:</h5>
                      <span>{bikeModel}</span>
                    </div>
                    <div className="col info-box">
                      <h5>{t('Year')}:</h5>
                      <span>{bikeYear}</span>
                    </div>
                    <div className="col info-box">
                      <h5>{t('Bike Serial')}:</h5>
                      <span>{bikeSerial}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="bike-detail col-12">
            <div className="row">
              <div className="col-md-12">
                <button className="btn btn-secondary add-comment add-comment-left mb-3" onClick={() => setShowCommentModal(true)}>
                  {t('Add Comment')}
                  <img src="/imgs/icons/icon-plus.svg" alt={t('Add Comment')} />
                </button>
              </div>
            </div>
          </div>

          {/* AddCommentModal */}
          <AddCommentModal
            show={showCommentModal}
            handleClose={() => setShowCommentModal(false)}
            bikeId={bikeSerial}
            fetchBikeRecords={fetchBikeRecords}
            RestAPIService={RestAPIService}
          />

          {/* BatterySerialModal */}
          <BatterySerialModal
            show={showBatteryModal}
            handleClose={() => setShowBatteryModal(false)}
            bikeId={bikeSerial}
            fetchBikeInfo={fetchBikeInfo}
            RestAPIService={RestAPIService}
          />

          {/* ControllerSerialModal */}
          <ControllerSerialModal
            show={showControllerModal}
            handleClose={() => setShowControllerModal(false)}
            bikeId={bikeSerial}
            fetchBikeInfo={fetchBikeInfo}
            RestAPIService={RestAPIService}
          />

          {/* Live Diagnostics Section */}
          <div className="col-md-9 mt-4">
            <h4>{t('Diagnostics Results')}</h4>
            <div className="diagnostics-logging">
              {errors.length > 0 && errors.some(error => error.code !== '0') ? (
                errors
                  .filter(error => error.code !== '0')
                  .map((error, index) => (
                    <p style={{ color: 'red' }} key={index}>
                      {t(`Error Code ${error.code}: ${error.description} at ${error.timestamp}`)}
                    </p>
                  ))
              ) : (
                <p style={{ color: 'green' }}>
                  {t('No errors detected on screen')}
                </p>
              )}
              {motorTempResult && (
                <p style={{ color: motorTemperatureMessage.color }}>
                  {motorTemperatureMessage.message}
                </p>
              )}
              {controllerTempResult && (
                <p style={{ color: controllerTemperatureMessage.color }}>
                  {controllerTemperatureMessage.message}
                </p>
              )}
            </div>
          </div>

          <div className="col-12 mt-4">
            <div className="row mt-4">
              <div className="col-12">
                <h4>{t('Live Vehicle Toggles')}</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <div className={`stat-icon white ${brakeState === 'Brake ON' ? 'active' : ''}`}>
                  <span>
                    {t('Brake State')}<br />
                    <span>{brakeState ? 'ON' : 'OFF'}</span>
                  </span>
                </div>
              </div>
              <div className="col-2">
                <div className={`stat-icon white ${frontLightState === 'Front Light ON' ? 'active' : ''}`}>
                  <span>
                    {t('Front Light State')}<br />
                    <span>{frontLightState || t('N/A')}</span>
                  </span>
                  <button onClick={toggleFrontLight} className="btn btn-primary btn-small btn-block mt-2">
                    {frontLightState === 'Front Light ON' ? t('Turn Off') : t('Turn On')}
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className={`stat-icon white ${rearLightState === 'Rear Light ON' ? 'active' : ''}`}>
                  <span>
                    {t('Rear Light State')}<br />
                    <span>{rearLightState || t('N/A')}</span>
                  </span>
                  <button onClick={toggleRearLight} className="btn btn-primary btn-small btn-block mt-2">
                    {rearLightState === 'Rear Light ON' ? t('Turn Off') : t('Turn On')}
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="stat-icon white">
                  <span>
                    {t('Current PAS level')}<br />
                    <span>{currentPASResult ? currentPASResult.latestValue : t('N/A')}</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <h4>{t('Live Vehicle Data')}</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <div className="stat-icon white">
                  <span>
                    {t('Torque and Cadence')}<br />
                    <button className="btn btn-primary btn-small btn-block mt-2" onClick={handleOpenGraphModalTorqueCadence}>
                      {t('View Live Graph')}
                    </button>
                    {graphModalShowTorqueCadence && (
                      <GraphModal
                        compKey={key}
                        show={graphModalShowTorqueCadence}
                        onHide={handleCloseGraphModalTorqueCadence}
                        primaryService={primaryService}
                        pin={pin}
                        nodeId='0x00000601'
                        index={[{ index: 0x2024, subIndex: 0x00, title: 'Cadence' }, { index: 0x2024, subIndex: 0x01, title: 'Torque' }]}
                        getSendPassthroughRW={getSendPassthroughRW}
                        stopNotificationListener={stopNotificationListener}
                        startNotificationListener={startNotificationListener}
                      />
                    )}
                  </span>
                </div>
              </div>
              <div className="col-2">
                <div className="stat-icon white">
                  <span>
                    {t('Motor and Power')}<br />
                    <button className="btn btn-primary btn-small btn-block mt-2" onClick={handleOpenGraphModalPower}>
                      {t('View Live Graph')}
                    </button>
                    {graphModalShowPower && (
                      <GraphModal
                        compKey={key}
                        show={graphModalShowPower}
                        onHide={handleCloseGraphModalPower}
                        primaryService={primaryService}
                        pin={pin}
                        nodeId='0x00000601'
                        index={[{ index: 0x2001, subIndex: 0x00, title: 'Real-time total power' }, { index: 0x2001, subIndex: 0x01, title: 'Requested motor torque' }, { index: 0x2001, subIndex: 0x02, title: 'Mechanical power in motor' }]}
                        getSendPassthroughRW={getSendPassthroughRW}
                        stopNotificationListener={stopNotificationListener}
                        startNotificationListener={startNotificationListener}
                      />
                    )}
                  </span>
                </div>
              </div>
              <div className="col-4">
                <div className="stat-icon white">
                  <span>
                    {t('View Live CanBus Data')}<br />
                    <select
                      className="form-select"
                      onChange={(e) => handleDropdownChange(e.target.value)}
                    >
                      <option value="">{t('Select Live Data')}</option>
                      {dropdownOptions.map((option, index) => (
                        <option key={index} value={option.subName}>
                          {option.subName}
                        </option>
                      ))}
                    </select>
                    <button
                      className="btn btn-primary btn-small btn-block mt-2"
                      onClick={handleOpenGraphModal}
                      disabled={!selectedDropdownOption}
                    >
                      {t('View Live Graph')}
                    </button>
                    {graphModalShow && (
                      <GraphModal
                        compKey={key}
                        show={graphModalShow}
                        onHide={handleCloseGraphModal}
                        primaryService={primaryService}
                        pin={pin}
                        nodeId='0x00000601'
                        index={selectedDropdownOption ? [{ index: selectedDropdownOption.index, subIndex: selectedDropdownOption.subIndex, title: selectedDropdownOption.subName }] : []}
                        getSendPassthroughRW={getSendPassthroughRW}
                        stopNotificationListener={stopNotificationListener}
                        startNotificationListener={startNotificationListener}
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mt-4">
            <div className="row mt-4">
              <div className="col-12">
                <h4>{t('Vehicle Configuration')}</h4>
              </div>
            </div>
            <div className="row">
              {liveCommands.length > 0 && (
                <>
                  <div className="col-md-6">
                    <table className="table table-no-borders">
                      <thead>
                        <tr>
                          <th colSpan="5">{t('Real Time Vehicle Info')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {results.filter(result => liveCommands.some(cmd => cmd.index === result.index && cmd.subIndex === result.subIndex)).map((result, index) => (
                          <tr key={index}>
                            <td>{result.subName}</td>
                            <td>{result.latestValue} {result.unit}</td>
                            <td>{result.index}</td>
                            <td>{result.subIndex}</td>
                            <td>{result.time}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
            {otherCommands.length > 0 && (
              <>
                <table className="table table-no-borders">
                  <thead>
                    <tr>
                      <th colSpan="5">{t('Vehicle Settings')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {results.filter(result => otherCommands.some(cmd => cmd.index === result.index && cmd.subIndex === result.subIndex)).map((result, index) => (
                      <tr key={index}>
                        <td>{result.category}</td>
                        <td>{result.subName}</td>
                        <td>{result.description}</td>
                        <td>{result.unit}</td>
                        <td>{result.latestValue}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MaintenanceComponent;
