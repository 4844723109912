import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SideMenu = ({ userGroup }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackClick = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    if (pathParts.length > 1) {
      const parentPath = '/' + pathParts.slice(0, -1).join('/');
      navigate(parentPath);
    } else {
      navigate('/'); // Or navigate to a default route
    }
  };

  return (
    <div className="ftex-side-menu">
      <ul>
        <li>
          {t('Menu')}
        </li>
        <li className={location.pathname === '/analytics' ? 'active' : ''}>
          <Link to="/analytics">{t('Bikes')}</Link>
        </li>
{/*        <li className={location.pathname === '/analytics/stores' ? 'active' : ''}>
          <Link to="/analytics/stores">{t('Stores')}</Link>
        </li>
        <li className={location.pathname === '/analytics/users' ? 'active' : ''}>
          <Link to="/analytics/users">{t('Users')}</Link>
        </li>*/}
        <li className={location.pathname === '/analytics/app' ? 'active' : ''}>
          <Link to="/analytics/app">{t('App')}</Link>
        </li>
        <li className={location.pathname === '/analytics/diagnostics-usage' ? 'active' : ''}>
          <Link to="/analytics/diagnostics-usage">{t('Diagnostics Usage')}</Link>
        </li>
      </ul>
      <button className="btn btn-block btn-big" onClick={handleBackClick}>
        {t('Back')}
      </button>
    </div>
  );
};

export default SideMenu;
